import API from "Services";
import Cookies from "js-cookie";
import { GET_ADMIN_PROFILE_DETAILS, GET_CITY, FILE_UPLOAD, UPDATE_ADMIN_PROFILE, SEND_PASSWORD_EMAIL_RESET } from "Utils/ApiConstants";

export const getAdminProfileDetailsService = async (id) => {
  try {
    const response = await API.get(GET_ADMIN_PROFILE_DETAILS + id);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getCityService = async (data) => {
  try {
    const response = await API.get(`${GET_CITY}?search_term=${data}&limit=50`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const uploadProfilePhoto = async (data) => {
  try {
    const response = await API.post(FILE_UPLOAD, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const updateAdminProfile = async (data) => {
  try {
    const response = await API.post(UPDATE_ADMIN_PROFILE, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};


export const sendPasswordResetEmail = async (data) => {
  try {
    const response = await API.post(SEND_PASSWORD_EMAIL_RESET, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}
