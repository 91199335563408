import API from "Services";
import Cookies from "js-cookie";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  PROJECT_COST,
  GET_OUTCOMES,
  SAVE_COSTING,
  SEARCH_TALENT,
  DELETE_OUTCOME,
  CREATE_MS_TEAM,
  DELETE_COSTING,
  GET_CONSULTANTS,
  ADD_EDIT_CLIENT,
  ADD_EDIT_PROJECT,
  DELETE_MILESTONE,
  ADD_EDIT_OUTCOME,
  CURRENT_EMPLOYER,
  ADD_EDIT_CONTRACT,
  CURRENT_JOB_TITLE,
  GET_PROJECT_DETAILS,
  GET_ALL_PROJECT_DATA,
  ADD_UNIQUE_IDENTIFIER,
  GET_PROJECT_CONTRACTS,
  ADD_EDIT_DELIVERABLES,
  APPROVE_REJECT_REQUEST,
  DELETE_PROJECT_CONTRACT,
  UPDATE_CONSULTANT_STATUS,
  COSTING_SAVE_DELIVERABLES,
  ADD_COMMIUNICATION_CHANNEL,
  DELETE_COMMUNICATION_CHANNEL,
  GET_ALL_COMMUNICATION_CHANNEL,
  GET_PROJECT_MILESTONE_DELIRABLE,
  GET_COSTING,
} from "Utils/ApiConstants";

export const getAllProjectData = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}`;
    const response = await API.get(GET_ALL_PROJECT_DATA + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useGetAllProjectDataService = (onSuccess, onError, data) => {
  return useQuery(["all-projects", data], () => getAllProjectData(data), {
    onSuccess,
    onError,
    staleTime: 1000,
  });
};
const getProjectDetails = async (projectId) => {
  try {
    const response = await API.get(`${GET_PROJECT_DETAILS}?project_id=${projectId}`);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useProjectDetailsService = (onSuccess, onError, data) => {
  return useQuery(["project-details", data], () => getProjectDetails(data), {
    onSuccess,
    onError,
  });
};

export const addEditProjectService = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_PROJECT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

const addEditProject = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_PROJECT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const useProjectCost = () => {
  const queryClient = useQueryClient();
  return useMutation(addEditProject, {
    onSuccess: () => {
      queryClient.invalidateQueries("project-details");
      return;
    },
  });
};

export const useAddEditProjectService = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addEditProject, {
    onSuccess: () => {
      queryClient.invalidateQueries("project-details");
      return onSuccess();
    },
  });
};

export const useAddEditContractService = () => {
  const queryClient = useQueryClient();
  return useMutation(addEditContract, {
    onSuccess: () => {
      queryClient.invalidateQueries("project-details");
      return;
    },
  });
};

export const addEditContract = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_CONTRACT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useDeleteContractService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteContract, {
    onSuccess: () => {
      queryClient.invalidateQueries("project-details");
      return;
    },
  });
};

export const deleteContract = async (data) => {
  try {
    const response = await API.post(DELETE_PROJECT_CONTRACT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const deleteContractService = async (data) => {
  try {
    const response = await API.post(DELETE_PROJECT_CONTRACT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const addEditContractService = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_CONTRACT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const createClientService = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_CLIENT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const addUniqueIdentifierService = async () => {
  try {
    const response = await API.post(ADD_UNIQUE_IDENTIFIER);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

// Alll Milestone
export const useGetProjectMilestone = (onSuccess, onError, data) => {
  return useQuery(["all-milestone", data], () => getProjectMileStone(data), {
    onSuccess,
    onError,
  });
};

export const getProjectMileStone = async (project_id) => {
  try {
    const response = await API.get(`${GET_PROJECT_MILESTONE_DELIRABLE}?project_id=${project_id}`);

    if (response && response.data) {
      const response2 = await API.post(PROJECT_COST, { project_id: project_id });
      const response3 = await getProjectDetails(project_id);
      return response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const useEditProjectMileStone = () => {
  const queryClient = useQueryClient();
  return useMutation(editProjectMileStone, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      queryClient.invalidateQueries("project-details");
    },
  });
};
export const editProjectMileStone = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_DELIVERABLES, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const useAddEditOutcomeService = () => {
  const queryClient = useQueryClient();
  return useMutation(addEditOutcome, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      return;
    },
  });
};
export const addEditOutcome = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_OUTCOME, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const useDeleteOutcomeService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOutcome, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      return;
    },
  });
};

export const deleteOutcome = async (data) => {
  try {
    const response = await API.post(DELETE_OUTCOME, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const useDeleteMileStoneService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMileStone, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      return;
    },
  });
};
export const deleteMileStone = async (data) => {
  try {
    const response = await API.post(DELETE_MILESTONE, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useApproveRejectRequestService = () => {
  const queryClient = useQueryClient();
  return useMutation(approveReject, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      return;
    },
  });
};
export const approveReject = async (data) => {
  try {
    const response = await API.post(APPROVE_REJECT_REQUEST, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

// export const useDeleteMileStoneService = () => {
//   const queryClient = useQueryClient();
//   return useMutation(deleteMileStone, {
//     onSuccess: () => {
//       queryClient.invalidateQueries("all-milestone");
//       return;
//     },
//   });
// };

export const addEditDeliverableService = async (data) => {
  try {
    const response = await API.post(ADD_EDIT_DELIVERABLES, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getProjectDeliverablesService = async (project_id) => {
  try {
    const response = await API.get(`${GET_PROJECT_MILESTONE_DELIRABLE}?project_id=${project_id}`);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const createMsTeamService = async (data) => {
  try {
    const response = await API.post(CREATE_MS_TEAM, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useGetAllCChService = (onSuccess, onError, data) => {
  return useQuery(["communication-channel", data], () => allCommunicationChannel(data), {
    onSuccess,
    onError,
  });
};

const allCommunicationChannel = async (data) => {
  try {
    const response = await API.get(`${GET_ALL_COMMUNICATION_CHANNEL}?project_id=${data}`);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useAddCChService = () => {
  const queryClient = useQueryClient();
  return useMutation(addCommunicationChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries("communication-channel");
      return;
    },
  });
};

const addCommunicationChannel = async (data) => {
  try {
    const response = await API.post(ADD_COMMIUNICATION_CHANNEL, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useDeleteCCService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCommunicationChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries("communication-channel");
      return;
    },
  });
};

export const deleteCommunicationChannel = async (data) => {
  try {
    const response = await API.post(DELETE_COMMUNICATION_CHANNEL, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getProjectContractsService = async (data) => {
  try {
    const response = await API.get(`${GET_PROJECT_CONTRACTS}${data}`);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getConsultantsService = async (data) => {
  try {
    const response = await API.post(GET_CONSULTANTS, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getProjectDeliverablesOutcomesService = async (data) => {
  try {
    const response = await API.get(`${GET_OUTCOMES}${data}&sort=id&sort_order=asc&limit=200`);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const addEditCosting = async (data) => {
  try {
    const response = await API.post(COSTING_SAVE_DELIVERABLES, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getCosting = async (data) => {
  try {
    const response = await API.get(GET_COSTING + `?consultant_id=2&milestone_id=108`);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useAddEditCosting = () => {
  const queryClient = useQueryClient();
  return useMutation(addEditCosting, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      return;
    },
  });
};

export const useDeleteCostingService = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCosting, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-milestone");
      return;
    },
  });
};

export const deleteCosting = async (data) => {
  try {
    const response = await API.post(DELETE_COSTING, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const searchTalentService = async (data) => {
  try {
    const response = await API.post(SEARCH_TALENT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const searchCurrentJobTitleService = async (data) => {
  try {
    const response = await API.get(`${CURRENT_JOB_TITLE}?searchTerm=${data}`);

    if (response && response?.data) return response?.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const searchCurrentEmployerService = async (data) => {
  try {
    const response = await API.get(`${CURRENT_EMPLOYER}?searchTerm=${data}`);
    if (response && response?.data) return response?.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const updateConsultantStatusService = async (data) => {
  try {
    const response = await API.post(UPDATE_CONSULTANT_STATUS, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const saveConsultantCostingService = async (data) => {
  try {
    const response = await API.post(SAVE_COSTING, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
