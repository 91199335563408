import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundry, FallbackLoader } from "Common";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, CssBaseline, Box } from "Assets/Material";
import theme from "theme";
import Router from "Routes/Router";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<FallbackLoader />}>
              <ErrorBoundry>
                <Box sx={{ marginTop: "64px" }}>
                  <Router />
                </Box>
              </ErrorBoundry>
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
