import React, { useState } from "react";
import Cookies from "js-cookie";
import routes from "Routes";
import Styles from "Common/Header/Styles";
import { authUser, usertype } from "Utils";
import { useHistory } from "react-router-dom";
import { forgetPasswordService } from "Services/AuthService";

import { PowerSettingsNewIcon, UserProfile, LockClockIcon } from "Assets/Icons";
// import FormatColorReset from '@mui/icons-material/LockReset';
import { NavLink as RouterLink } from "react-router-dom";
import { useRecoilState, isLoginGlobal, profilePhotoGlobal } from "Atom";
import {
  List,
  Link,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Chip,
  MenuItem,
  Menu,
  ListItemIcon,
  Divider,
  SPAN,
} from "Assets/Material";

const DesktopMenu = (props) => {
  const history = useHistory();

  const [profilePhoto, setProfilePhoto] = useRecoilState(profilePhotoGlobal);
  const setLogout = useRecoilState(isLoginGlobal)[1];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickProfileMenuPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenuPopup = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setLogout(false);
    setProfilePhoto("");
    Cookies.remove("token");
    history.push("/admin-login");
  };
  return (
    <Box sx={Styles.desktopNavbarWrapper}>
      <List sx={Styles.desktopNavLinkList}>
        {routes.map(
          (route, index) =>
            route.inHeader &&
            route.path !== "/" &&
            route.access_level.includes(authUser()?.user_type_id) && (
              <Link
                key={index}
                to={route.path}
                component={RouterLink}
                sx={Styles.desktopNavlink}
                activeStyle={Styles.desktopActiveLink}
              >
                <ListItem sx={Styles.desktopNavLinkListItem}>
                  <ListItemText primary={route.title} sx={Styles.desktopNavLinkListItemText} />
                </ListItem>
              </Link>
            )
        )}
      </List>

      {routes.map(
        (route, index) =>
          route.isProfile &&
          route.profile_access_level === +authUser()?.user_type_id && (
            <React.Fragment key={index}>
              <Chip
                key={index}
                onClick={handleClickProfileMenuPopup}
                avatar={<Avatar alt={profilePhoto || UserProfile} src={profilePhoto || UserProfile} />}
                variant="outlined"
                label={<SPAN sx={{ textTransform: "capitalize" }}>{authUser()?.name.split(" ")[0]}</SPAN>}
                sx={Styles.desktopProfileIcon}
              />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleCloseProfileMenuPopup}
                onClick={handleCloseProfileMenuPopup}
                PaperProps={Styles.profileIconMenuProps}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {authUser()?.user_types.length > 1 && <MenuItem> Switch User </MenuItem>}
                {authUser()?.user_types.map(
                  (login, index) =>
                    login !== authUser()?.user_type_id && (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          props.setIsPasswordPopup();
                          props.getUserTypeId(login);
                        }}
                      >
                        <Avatar alt={UserProfile} src={UserProfile} /> {usertype(login)}
                      </MenuItem>
                    )
                )}
                {authUser()?.user_types.length > 1 && <Divider />}
                <Link to={route.path} component={RouterLink} sx={Styles.desktopProfileNavLink}>
                  <MenuItem sx={Styles.resetIcon}>
                    <Avatar alt={profilePhoto || UserProfile} src={profilePhoto || UserProfile} /> Profile
                    {/* {authUser()?.user_type} */}
                  </MenuItem>
                </Link>
                <MenuItem
                  onClick={() => {
                    props.setIsResetPasswordPopup();
                    // props.getUserTypeId(login)
                  }}
                  sx={Styles.resetIcon}
                >
                  <ListItemIcon>
                    {/* <svg data-testid="DeleteIcon"></svg> */}
                    {/* <FormatColorReset fontSize="small" ></FormatColorReset> */}
                    <LockClockIcon fontSize="small" sx={Styles.resetIcon} />
                  </ListItemIcon>
                  Reset Password
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout} sx={Styles.logoutIcon}>
                  <ListItemIcon>
                    <PowerSettingsNewIcon fontSize="small" sx={Styles.logoutIcon} />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          )
      )}
    </Box>
  );
};

export default DesktopMenu;
