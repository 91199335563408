import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const EditorInput = (props) => {
  const editorRef = useRef(null);

  return (
    <>
      <Editor
        apiKey="4cb7llw9xpc87xh5p1g8jftaieayrxasq7fm6xsddokjoove"
        initialValue={props.initialValue}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: 200,
          width: "100%",
          menubar: false,
          plugins: [
            "advlist autolink lists charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste ",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | ",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          selector: "textarea#filepicker",
          images_upload_url: "postAcceptor.php",
          automatic_uploads: false,
          branding: false,
          forced_root_block: "",
        }}
        onEditorChange={props.onEditorChange}
      />
    </>
  );
};

export default EditorInput;
