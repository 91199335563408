import API from "Services";
import Cookies from "js-cookie";
import { SEND_EMAIL } from "Utils/ApiConstants";

export const sendEmailService = async (data) => {
  try {
    const response = await API.post(SEND_EMAIL, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
