import React from "react";
import Styles from "Common/Form/Style";
import { CloseIcon } from "Assets/Icons";
import { Modal, Box, H1 } from "Assets/Material";

const Form = (props) => {
  return (
    <Modal
      open={props?.onOpen}
      onClose={props?.onClose}
    >
      <Box sx={props?.formWrapper || Styles.formWrapper}>
        <Box>
          <H1 sx={Styles.formHeading}>
            {props?.title}
            <CloseIcon sx={Styles.formCloseIcon} onClick={props?.onClose} />
          </H1>
        </Box>
        <Box>
          {props?.children}
        </Box>
      </Box>
    </Modal>
  );
}

export default Form;