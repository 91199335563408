import React from "react";
import { SPAN, Box } from "Assets/Material";

const Styles = {
  dataWrapper: {
    overflow: "hidden",
    paddingRight: "2rem",
    position: "relative",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    color: (theme) => theme.palette.primary.main,
  },
  count: {
    fontWeight: "700",
    float: "right",
    position: "absolute",
    right: "0",
    color: (theme) => theme.palette.primary.main,
  },
};

const MobileTableDataPopupCollapsable = (props) => {
  return (
    <Box sx={Styles.dataWrapper} onClick={() => props?.openFunc()}>
      {props?.data && JSON.parse(props?.data).map((i) => i.name).length > 0 && (
        <>
          {JSON.parse(props?.data)
            .map((i) => i.name)
            .join(", ")}
          <SPAN sx={Styles.count}>
            {JSON.parse(props?.data).length - 1 > 0 ? `  +${JSON.parse(props?.data).length - 1}` : null}
          </SPAN>
        </>
      )}
    </Box>
  );
};

export default MobileTableDataPopupCollapsable;
