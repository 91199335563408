import React from "react";
import { TextField } from "Assets/Material";

const TextInput = (props) => {
  return (
    <React.Fragment>
      <TextField
        fullWidth
        id={props?.name}
        name={props?.name}
        label={props?.label}
        value={props?.value}
        disabled={props?.disabled}
        type="search"
        size="small"
        autoComplete="off"
        margin="dense"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={props?.onChange}
        onBlur={props?.onBlur}
        error={props?.onError}
        helperText={props?.onError}
        InputProps={props?.adornment || null}
        sx={props?.sx}
        placeholder={props?.placeholder}
        autoFocus={props?.autoFocus}
      />
    </React.Fragment>
  );
};

export default TextInput;
