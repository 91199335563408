import API from "Services";
import Cookies from "js-cookie";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Add_NEW_FUNCTIONAL_CAPABILITY,
  Add_NEW_FUNCTIONAL_LANGUAGE,
  FETCH_ADDED_SKILLS,
  FETCH_COSTING_REQUESTS,
  UPDATE_SKILLS_STATUS_REMOVE,
  UPDATE_SKILLS_STATUS_ADD,
  APPROVE_REJECT_REQUEST,
  Add_NEW_UNAPPROVED_INDUSTRIES,
  GET_ALL_UNAPPROVED_INDUSTRIES,
  UPDATE_INDUSTRIES_STATUS_REMOVE,
  UPDATE_INDUSTRIES_STATUS_ADD,
} from "Utils/ApiConstants";

export const addNewUnapprovedIndustries = async (data) => {
  try {
    const response = await API.post(Add_NEW_UNAPPROVED_INDUSTRIES, data);
    if (response && response.data) return response.data.list;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const addNewFunctionalCapabilityService = async (data) => {
  try {
    const response = await API.post(Add_NEW_FUNCTIONAL_CAPABILITY, data);
    if (response && response.data) return response.data.list;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const addNewFunctionalLanguageService = async (data) => {
  try {
    const response = await API.post(Add_NEW_FUNCTIONAL_LANGUAGE, data);
    if (response && response.data) return response.data.list;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchCostingRequestService = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}`;
    const response = await API.get(FETCH_COSTING_REQUESTS + filters);
    // const response = await API.get(
    //   `${FETCH_COSTING_REQUESTS}?from=0&offset=10&sort_order=DESC&sort_by=id&search_term=&search_by=1`
    // );
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchUnSkillService = async (data) => {
  try {
    const response = await API.get(`${FETCH_ADDED_SKILLS}?offset=${data.offset}&from=${data.from}`);
    if (response && response.data) return response?.data?.data?.count > 0 ? response?.data : [];
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchUnIndustriesService = async (data) => {
  try {
    const response = await API.get(`${GET_ALL_UNAPPROVED_INDUSTRIES}?offset=${10}&from=${data.from}`);
    if (response && response.data) return response?.data?.data?.count > 0 ? response?.data?.data : [];
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useGetAllUnSkill = (onSuccess, onError, data) => {
  return useQuery(["all-ul-skill", data], () => fetchUnSkillService(data), {
    onSuccess,
    onError,
  });
};
export const useGetAllUnIndustries = (onSuccess, onError, data) => {
  return useQuery(["all-ul-skill", data], () => fetchUnIndustriesService(data), {
    onSuccess,
    onError,
  });
};

export const skillRemove = async (data) => {
  try {
    const response = await API.post(UPDATE_SKILLS_STATUS_REMOVE, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useSkillRemoveServices = () => {
  const queryClient = useQueryClient();
  return useMutation(skillRemove, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-ul-skill");
      return;
    },
  });
};

export const industriesRemove = async (data) => {
  try {
    const response = await API.post(UPDATE_INDUSTRIES_STATUS_REMOVE, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useIndustriesRemoveServices = () => {
  const queryClient = useQueryClient();
  return useMutation(industriesRemove, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-ul-skill");
      return;
    },
  });
};

export const skillsAdd = async (data) => {
  try {
    const response = await API.post(UPDATE_SKILLS_STATUS_ADD, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useSkillAddServices = () => {
  const queryClient = useQueryClient();
  return useMutation(capabilityAdd, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-ul-skill");
      return;
    },
  });
};

export const industriesAdd = async (data) => {
  try {
    const response = await API.post(UPDATE_INDUSTRIES_STATUS_ADD, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const capabilityAdd = async (data) => {
  try {
    const response = await API.post(UPDATE_SKILLS_STATUS_ADD, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useIndustriesAddServices = () => {
  const queryClient = useQueryClient();
  return useMutation(industriesAdd, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-ul-skill");
      return;
    },
  });
};

export const approveRejectRequestService = async (data) => {
  try {
    const response = await API.post(APPROVE_REJECT_REQUEST, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
