const ProjectDetailsStyles = {
  positionHrs: {
    position: "relative",
    top: "10px",
  },
  projectDetailsWrapper: {
    padding: ["1rem", "2rem", "2rem 3rem 1rem", "2rem 3rem 1rem", "2rem 3rem 1rem"],
  },
  projectDetailsDataWrapperMobile: {
    marginBottom: "2rem",
    border: "1px solid #4444",
    borderRadius: "5px",
    padding: "0.5rem",
  },
  projectDetailsDataWrapperDesktop: {
    marginBottom: "2rem",
    background: "#F7FEFF 0% 0% no-repeat padding-box",
    padding: "12px",
    border: "1px solid #BCF3FF",
    borderRadius: "4px",
  },
  projectTitle: {
    fontWeight: "700",
    fontSize: ["1.2rem", "1.5rem", "1.5rem", "1.5rem", "1.5rem"],
    paddingLeft: "0.5rem",
    margin: "0",

    color: (theme) => theme.palette.text_heading.main,
    borderLeft: (theme) => [`5px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  projectDetailsTitle: {
    fontSize: ["1rem", "1.2rem", "1.2rem", "1.2rem", "1.2rem"],
    width: "100%",
  },
  editIcon: {
    float: "right",
    // marginBottom: "1rem",
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
  },
  submitIcon: {
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  deleteIcon: {
    marginLeft: "1rem",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  editLoader: {
    width: "1rem !important",
    height: "1rem !important",
    float: "right",
    marginBottom: "1rem",
  },
  dataTitle: {
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
  },
  mobileHeadingWrapper: {
    width: "100%",
    border: ["1px solid #4444", "1px solid #4444", "0", "0", "0"],
    padding: ["0.5rem", "0.5rem", "0", "0", "0"],
    borderRadius: ["5px", "5px", "0", "0", "0"],
    marginBottom: "0",
  },
  approvalTabWrapper: {
    margin: "1rem 0",
    border: (theme) => `1px solid ${theme.palette.disable}`,
    borderRadius: "5px",
  },
  approvalTabelTitle: {
    fontWeight: "700",
    color: (theme) => theme.palette.text_heading.main,
  },
  approvalTabelTitleWrapper: {
    alignItems: "center",
    borderBottom: (theme) => `1px solid ${theme.palette.disable}`,
    padding: "0 0 1rem 0",
  },
  approvalTabelDataWrapper: {
    borderBottom: (theme) => `1px solid ${theme.palette.disable}`,
    padding: "0.5rem 0 0.5rem 0",
    margin: "0.5rem 0",
  },
  approvalTabelDataWrapperLastItem: {
    border: "0",
    padding: "0.5rem 0 0.5rem 0",
    margin: "0.5rem 0",
  },
  approvalTabelDataProfileImage: {
    width: "50px",
    borderRadius: "50px",
  },
  approvalTabelData: {
    alignItem: "center",
    display: "flex",
  },
  consultantName: {
    fontWeight: "700",

    color: (theme) => theme.palette.text_heading.main,
  },
  mobile: {
    position: "relative",
    top: ["9px", "9px", 0, 0, 0],
  },
  consultantData: {
    textAlign: "center",
  },
  consultantDataMobile: {
    textAlign: "left",
    margin: "0.5rem 0",
  },
  diabledApprovalAction: {
    color: (theme) => theme.palette.disable,
    cursor: "default",
    position: "relative",
    top: "-5px!important",
  },
  approvalPanelLoader: {
    width: "15px !important",
    height: "15px !important",
  },
};

export default ProjectDetailsStyles;
