const ApprovalPanelStyles = {
  checkBoxWidth: {
    width: ["4%", "5%", "3%", "3%", "3%"],
  },
  checkFont: {
    fontSize: "19px",
  },
  headerResetButton: {
    width: ["4%", "4%", "4%", "4%", "3%"],
    // width: "300px",
  },
  headerResetICon: {
    width: ["20px", "none", "none", "none", "none"],
    color: "primary",
  },
  AddButton: {
    width: ["4%", "2%", "4%", "4%", "3%"],
  },
  removeButtonWrapper: {
    width: ["4%", "2%", "5%", "4%", "3%"],
  },
  removeButton: {
    "&:hover": {
      backgroundColor: (theme) => `${theme.palette.error.main} !important`,
      color: "#fff",
      border: (theme) => `${theme.palette.error.main} !important`,
    },
  },
  headIconAdd: {
    fontSize: ["25px", "none", "none", "none", "none"],
    color: "#607ebb",
  },
  headIconRemove: {
    fontSize: ["25px", "none", "none", "none", "none"],
    color: "red",
  },
  capabilityTableCell: {
    width: ["10%", "10%", "10%", "10%", "10%"],
  },
  AddIcon: {
    fontSize: ["25px", "none", "none", "none", "none"],
    color: "#607ebb",
  },
  RemoveIcon: {
    fontSize: ["25px", "none", "none", "none", "none"],
    color: "red",
  },
};

export default ApprovalPanelStyles;
