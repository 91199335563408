const ProjectDetailsAddConsultantStyles = {
  modalWrapper: {
    overflowY: "hidden",
    position: "relative",
    bgcolor: "background.paper",
    margin: ["0", "0", "10vh auto auto"],
    width: ["100%", "100%", "80%", "80%", "80%"],
    height: ["100vh", "100vh", "80vh", "80vh", "80vh"],
    maxHeight: ["100vh", "100vh", "80vh", "80vh", "80vh"],
    zIndex: "1000000",
  },
  heading: {
    fontSize: "1.4rem",
    fontWeight: "700",
    color: " #607ebb",
    margin: " 10px 0px",

    // fontSize: "1rem",
    // fontWeight: "700",
    // color: (theme) => theme.palette.text_heading.main
  },
  filterWrapper: {
    background: "#90c3cf29",
  },
  formCloseIcon: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    cursor: "pointer",
    zIndex: "9",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  contentWrapper: {
    padding: "1rem",
    height: "100%",
    width: "100%",
    position: "relative",
    paddingBottom: "5rem",
  },
  searchInputWrapper: {
    border: (theme) => `1px solid ${theme.palette.text_heading.main}`,
    borderRadius: "5px",
    padding: "0.5rem",
    maxHeight: ["50vh", "50vh", "23vh", "35vh", "30vh"],
    height: ["50vh", "40vh", "23vh", "35vh", "30vh"],
    overflow: "auto",
    width: "100%",
  },
  searchInputFormWrapper: {
    width: "100%",
  },
  searchInput: {
    borderRadius: "50px",
    fontSize: "1rem",
    "& .MuiInputBase-input": {
      xs: { padding: "6px 12px" },
      md: { padding: "10px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: (theme) => theme.palette.text_heading.main,
    },
  },
  searchInputDesktop: {
    width: "100%",
  },
  searchInputMobile: {
    width: "90%",
  },
  searchInputIcon: {
    color: (theme) => theme.palette.text_heading.main,
  },
  consultantWrapper: {
    borderBottom: "1px solid #444444",
    padding: "0.5rem 0",
    cursor: "pointer",
  },
  consultantWrapper1: {
    padding: "0.5rem 0",
    cursor: "pointer",
  },
  consultantName: {
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
    display: "block",
    textTransform: "capitalize",
  },
  consultantCurrentEmployer: {
    color: (theme) => theme.palette.text_data.main,
    display: "block",
    textTransform: "capitalize",
  },
  leftInputWrapper: {
    paddingRight: ["0", "0", "1rem"],
  },
  rightInputWrapper: {
    paddingLeft: ["0", "0", "1rem"],
    marginBottom: "1rem",
  },
  inputlabel: {
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
    display: "block",
  },
  currentJob: {
    "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
      background: "#fff",
    },
  },
  currentEmployer: {
    "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
      background: "#fff",
    },
  },
  yearsOfExperience: {
    "& .css-1xoa8d-MuiInputBase-root-MuiOutlinedInput-root": {
      background: "#fff",
    },
  },
  radioButtonWrapper: {
    display: "inline",
  },
  radioButtonForm: {
    "& .css-1xnhj9c-MuiTypography-root": {
      fontSize: "0.9rem",
    },
  },
  radioButton: {
    color: "#607ebb",
  },
  capabilitiesWrapper: {
    margin: "0.5rem 0 1rem 0",
  },
  filterButtonWrapper: {
    width: ["90%", "96%", "90%", "90%", "90%"],
    position: "absolute",
    bottom: "1rem",
  },

  filterButtonCaption: {
    marginTop: "1rem",
    display: "block",
  },
  addConsultantButtonsMobile: {
    width: "100%",
  },
  loaderWrapper: {
    height: "100%",
    textAlign: "center",
  },
  loader: {
    margin: "3rem auto 0",
  },
  toogleIcon: {
    float: "right",
    color: (theme) => theme.palette.primary.main,
  },
  filterIcon: {
    right: "0",
    fontSize: "1.8rem",
    position: "absolute",
    color: (theme) => theme.palette.primary.main,
  },
  filterButtonClearMobile: {
    width: "100%",
  },
  filterButtonSearchMobile: {
    width: "100%",
  },
  consultantChipData: {
    textTransform: "capitalize",
  },
  consultantListWrapper: {
    marginTop: "3rem",
    width: "100%",
    overflow: "auto",
    height: ["6rem", "30rem", "30rem", "6rem", "25rem"],
  },
  consultantChip: {
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    padding: "1.5rem 0",
    borderRadius: "50px",
  },
  consultantChipName: {
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
  },
};

export default ProjectDetailsAddConsultantStyles;
