import React, { useState } from "react";
import Cookies from 'js-cookie';
import routes from "Routes";
import Styles from "Common/Header/Styles";
import { ClearIcon, MenuIcon, PowerSettingsNewIcon, UserProfile } from "Assets/Icons";
import { NavLink as RouterLink } from "react-router-dom";
import { List, IconButton, Link, ListItem, ListItemText, SwipeableDrawer, Box, Avatar, Menu, MenuItem, Divider, ListItemIcon } from "Assets/Material";
import { authUser, usertype } from "Utils";
import { useHistory } from "react-router-dom";
import { useRecoilState, isLoginGlobal, profilePhotoGlobal } from "Atom";
import {forgetPasswordService } from "Services/AuthService";

const MobileMenu = (props) => {

  const history = useHistory();
  const [state, setState] = useState({ left: true });
  const setLogout = useRecoilState(isLoginGlobal)[1];
  const [profilePhoto, setProfilePhoto] = useRecoilState(profilePhotoGlobal);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickProfileMenuPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenuPopup = () => {
    setAnchorEl(null);
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const logout = () => {
    setLogout(false);
    setProfilePhoto("");
    Cookies.remove("token");
    history.push("/admin-login");
  }
 
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <IconButton aria-label="Close Navigation" sx={Styles.mobileNavLinkListCloseButton}>
          <ClearIcon sx={Styles.mobileNavLinkListCloseButtonIcon} />
        </IconButton>
        {
          routes.map((route, index) => (
            route.inHeader &&
            route.path !== "/" &&
            route.access_level.includes(authUser()?.user_type_id) &&
            <Link
              component={RouterLink}
              to={route.path}
              key={index}
              activeStyle={Styles.mobileNavActiveLink}
              sx={Styles.mobileNavLink}
            >
              <ListItem>
                <ListItemText primary={route.title} sx={Styles.mobileNavListItem} />
              </ListItem>
            </Link>
          ))
        }
      </List>
    </Box>
  );

  return (
    <>
      {
        routes.map(
          (route, index) =>
            route.isProfile &&
            route.profile_access_level === +authUser()?.user_type_id && (
              <React.Fragment key={index}>
                <Avatar
                  key={index}
                  alt={profilePhoto || UserProfile}
                  src={profilePhoto || UserProfile}
                  onClick={handleClickProfileMenuPopup}
                  sx={Styles.desktopProfileMobile}
                />
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseProfileMenuPopup}
                  onClick={handleCloseProfileMenuPopup}
                  PaperProps={Styles.profileIconMenuProps}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {authUser()?.user_types.length > 1 && <MenuItem> Switch User </MenuItem>}

                  {
                    authUser()?.user_types.map(
                      (login, index) =>
                        login !== authUser()?.user_type_id &&
                        <MenuItem
                          key={index}
                          onClick={() => {
                            props.setIsPasswordPopup();
                            props.getUserTypeId(login)
                          }}
                        >
                          <Avatar alt={UserProfile} src={UserProfile} />
                          {usertype(login)}
                        </MenuItem>)
                  }
                  {authUser()?.user_types.length > 1 && <Divider />}
                  <Link
                    to={route.path}
                    component={RouterLink}
                    sx={Styles.desktopProfileNavLink}
                  >
                    <MenuItem>
                      <Avatar
                        alt={profilePhoto || UserProfile}
                        src={profilePhoto || UserProfile}
                      /> Profile
                    </MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      props.setIsResetPasswordPopup();
                      // props.getUserTypeId(login)
                    }} 
                    sx={Styles.logoutIcon}
                  >
                    <ListItemIcon>
                    {/* <svg data-testid="DeleteIcon"></svg> */}
                      {/* <LockResetIcon fontSize="small" ></LockResetIcon> */}
                      <PowerSettingsNewIcon fontSize="small" sx={Styles.logoutIcon} />
                    </ListItemIcon>
                    Reset Password
                  </MenuItem>
                  <MenuItem onClick={logout} sx={Styles.logoutIcon}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon fontSize="small" sx={Styles.logoutIcon} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )
        )
      }
      <IconButton onClick={toggleDrawer("right", true)}>
        <MenuIcon sx={Styles.mobileNavLinkListOpenButtonIcon} />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={!!state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
}

export default MobileMenu;