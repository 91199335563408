import { atom } from "recoil";
export { useRecoilState } from "recoil";

export const searchByFilter = atom({
  key: "searchByFilter",
  default: "1",
});

export const searchByFilterClient = atom({
  key: "searchByFilterClient",
  default: "1",
});

export const searchTermFilter = atom({
  key: "searchTermFilter",
  default: "",
});

export const searchTermFilterClient = atom({
  key: "searchTermFilterClient",
  default: "",
});

export const sortByFilter = atom({
  key: "sortByFilter",
  default: "id",
});

export const sortOrderFilter = atom({
  key: "sortOrderFilter",
  default: "DESC",
});

export const sortOrderListGlobal = atom({
  key: "sortOrderListGlobal",
  default: [{ id: 1, name: "Default", value: "DESC" }],
});

export const isLoginGlobal = atom({
  key: "isLoginGlobal",
  default: false,
});

export const toasterGlobal = atom({
  key: "toasterGlobal",
  default: {
    isOpen: false,
    message: null,
    status: null,
  },
});

export const isLoaderGlobal = atom({
  key: "isLoaderGlobal",
  default: false,
});

export const confirmGlobal = atom({
  key: "confirmGlobal",
  default: {
    isOpen: false,
    delete: () => {},
  },
});

export const approvalGlobal = atom({
  key: "approvalGlobal",
  default: {
    isOpen: false,
    submit: () => {},
    cancel: () => {},
  },
});

export const editProjectData = atom({
  key: "editProjectData",
  default: null,
});

export const profilePhotoGlobal = atom({
  key: "profilePhotoGlobal",
  default: null,
});

export const stepperClose = atom({
  key: "stepperClose",
  default: false,
});

export const searchTermValue = atom({
  key : 'searchTerm',
  default : ""
})

export const searchByValue = atom({
  key : 'search_by',
  default : "1"
})

export const chatWindows = atom({
  key: 'conversationIds',
  default: []
})
