const Styles = {
  noDataWrapper: {
    textAlign: "center",
  },
  noDataImage: {
    width: ["50%", "30%", "20%", "15%", "15%"],
    margin: "2rem auto"
  },
  noDataText: {
    marginBottom: "2rem",
    fontSize: "1rem",
    fontWeight: "700",
    color: (theme) => theme.palette.text_data.main
  },
  noData: {
    height: "260px", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center"
  }
}

export default Styles;
