import React, { useState } from "react";
import { SwapperStyles } from "Assets/Styles";
import { formatDate } from "Utils";
import { Edit, Delete } from "Assets/Icons";
import { Grid, Box } from "Assets/Material";
import { MobileTableDataPopupCollapsable, MobileTableDataPopup, Swapper, ContactAction } from "Common";

const ProjectLeadsSwapper = (props) => {
  const [isTableIndustryPopup, setIsTableIndustryPopup] = useState(false);
  const [isTableFunctionalCapabilityPopup, setIsTableFunctionalCapabilityPopup] = useState(false);

  return (
    <Swapper onOpen={props?.isOpen} onClose={() => props?.onClose()} swapperTitle="Project Leads">
      <Grid container>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Name</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData}>{props?.swapperData && props?.swapperData?.name}</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Organization</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData} style={{ position: "relative" }}>
            {props?.swapperData && props?.swapperData?.company_name}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>budget</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData} style={{ position: "relative" }}>
            {props?.swapperData && props?.swapperData?.budget_min}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Industries</Box>
        </Grid>
        <Grid item xs={6}>
          <MobileTableDataPopupCollapsable
            data={props?.swapperData?.industries}
            openFunc={() => setIsTableIndustryPopup(true)}
          />
          <MobileTableDataPopup
            onOpen={isTableIndustryPopup}
            onClose={() => setIsTableIndustryPopup(false)}
            data={props?.swapperData?.industries}
          />
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Functional Capabilities</Box>
        </Grid>
        <Grid item xs={6}>
          <MobileTableDataPopupCollapsable
            data={props?.swapperData?.fc_data}
            openFunc={() => setIsTableFunctionalCapabilityPopup(true)}
          />
          <MobileTableDataPopup
            onOpen={isTableFunctionalCapabilityPopup}
            onClose={() => setIsTableFunctionalCapabilityPopup(false)}
            data={props?.swapperData?.fc_data}
          />
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Date</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData} onClick={() => {}}>
            {props?.swapperData && formatDate(props?.swapperData?.estimated_start_date)}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Contact Details</Box>
        </Grid>
        <Grid item xs={6}>
          <ContactAction onEmailOpen={() => props?.onEmailOpen(props?.swapperData)} data={props?.swapperData} />
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Action</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ ...SwapperStyles.contentData, ...SwapperStyles.swapperActionMenuWrapper }}>
            <Edit onClick={props?.edit} sx={SwapperStyles.swapperIcon} />
            <Delete onClick={props?.delete} sx={SwapperStyles.deleteIcon} />
          </Box>
        </Grid>
      </Grid>
    </Swapper>
  );
};

export default ProjectLeadsSwapper;
