const Styles = {
  uploadContainer: {
    paddingLeft: "16px",
    paddingTop: "16px !important",
    margin: "8px 0",
  },
  uploadTitle: {
    top: "-0.5rem",
    left: "5px",
    fontSize: "12px",
    fontWeight: "300",
    padding: "0px 4px",
    position: "absolute",
    background: "#fff",
  },
  uploadWrapper: {
    padding: "12px 5px",
    textAlign: "center",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    position: "relative",
    height: ["8rem", "8rem", "6rem", "6rem", "6rem"],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
  uploadFileIconWrapper: {
    display: "block",
  },
  uploadFileIcon: {
    fontSize: "3rem",
    color: " #607ebb",
  },
  supportText: {
    fontSize: ["0.8rem", "0.7rem", "0.9rem", "0.9rem", "0.9rem"],
  },
  filesContainer: {
    padding: "12px 5px",
  },
  fileContent :{
    border :['1px solid #0000003b', 'none', 'none', 'none', 'none'],
    maxWidth: ['96%','100%', '100%', '100%', '100%'],
    position: ['relative', 'none','none', 'none', 'none'],
    left: ['4%','0%', '0%', '0%','0%'],
    borderRadius:['5px','0px', '0px', '0px','0px'],
  },
  filesWrapper1: {
    padding: "12px",
    textAlign: "center",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    height: ["8rem", "8rem", "10rem", "10rem", "10rem"],
    overflow: "auto",
  },
  fileName: {
    fontSize: "0.8rem",
    textAlign: "left",
  },
  deleteIcon: {
    float: "right",
    color: (theme) => theme.palette.enable,
    "&:hover": { color: "red" },
  },
  progressWrapper: {
    // marginTop: "1rem"
  },
  progressBar: {
    marginTop: "0.5rem",
    paddingRight: "0.5rem",
  },
};

export default Styles;
