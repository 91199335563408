const TableStyles = {
  tableWrapper: {
    backgroundColor: "#90c3cf29",
    padding: ["1rem", "1rem", "2rem", "2rem", "2rem"],
  },
  tableContainer: {
    // border: "1px solid #4444",
    padding: "0 0.5rem",
    overflow: "hidden",
    boxShadow: "0px 2px 6px #00000030",
    borderRadius: "4px",
  },
  table: {
    minWidth: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  tableCell: {
    wordBreak: "break-word",
    fontWeight: "700",
    padding: "16px",
    color: (theme) => theme.palette.text_heading.main,
  },
  tabledata: {
    fontSize: ["0.8rem", "0.8rem", "1rem"],
    padding: "16px",
    position: "relative",
  },
  actionMenuItem: {
    color: (theme) => theme.palette.primary.main,
    fontSize: "1.2rem",
  },
  actionMenuItemDisable: {
    cursor: "auto",
    fontSize: "1.2rem",
    pointerEvent: "none",
    color: (theme) => theme.palette.disable,
  },
  buttonIcon: {
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    border: "2px solid",
    borderRadius: "50px",
    padding: "4px",
    width: "25px",
    height: "25px",
    transition: "0.2s",
    "&:hover": {
      boxShadow: (theme) => `inset 0px 0px 0px 2px ${theme.palette.primary.main}`,
      transition: "0.2s",
    },
  },
  buttonIconDisable: {
    border: "2px solid",
    color: (theme) => theme.palette.disable,
    borderColor: (theme) => theme.palette.disable,
    borderRadius: "50px",
    padding: "4px",
    width: "25px",
    height: "25px",
    pointerEvent: "none",
    cursor: "auto",
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.primary.main,
    },
  },
  link2: {
    cursor: "pointer",
    color: '#444444'
  },
  mobilelink: {
    color: (theme) => theme.palette.primary.main,
    fontWeight: "700",
    "&::first-letter": {
      textTransform: "capitalize"
    }
  },
  appliedBadge: {
    border: "none",
    outline: "none",
    backgroundColor: "green",
    minWidth: "30px",
    color: "white",
    textAlign: "center",
    borderRadius: "10px",
    padding: "1px"
  }
};

export default TableStyles;
