import React from "react";
import { TableContent, SPAN, useTheme, useMediaQuery } from "Assets/Material";
import { TableStyles } from "Assets/Styles";

const Styles = {
  count: {
    fontWeight: "700",
    position: "absolute",
    right: "1rem",
    color: (theme) => theme.palette.primary.main,
  },
};

const DesktopTableDataPopupCollapsable = (props) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  if (!props.isUnapprovedLead) {

    return (
      <TableContent sx={TableStyles.link} onClick={props?.openFunc}>
        {props?.data && JSON.parse(props?.data).map((i) => i.name).length > 0 && (
          <>
            {JSON.parse(props?.data).map((i) => {
              if (i.name) {
                return i.name;
              }
            })}
  
            {!xs && !sm && (
              <SPAN sx={Styles.count}>
                {JSON.parse(props?.data).length - 1 > 1 ? `  +${JSON.parse(props?.data).length - 1}` : null}
              </SPAN>
            )}
          </>
        )}
      </TableContent>
    );

  } else {
    return (
      <TableContent sx={TableStyles.link} onClick={props?.openFunc}>
        {props?.data && JSON.parse(props?.data).map((i) => i).length > 0 && (
          <>
            {JSON.parse(props?.data).map((i) => {
              if (i) {
                return i;
              }
            })}
  
            {!xs && !sm && (
              <SPAN sx={Styles.count}>
                {JSON.parse(props?.data).length - 1 > 1 ? `  +${JSON.parse(props?.data).length - 1}` : null}
              </SPAN>
            )}
          </>
        )}
      </TableContent>
  )}

};

export default DesktopTableDataPopupCollapsable;
