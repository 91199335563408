import React from "react";
import Styles from "Common/Confirm/Styles";
import { Modal, Box, Typography, Button } from "Assets/Material";
import { useRecoilState, approvalGlobal } from "Atom";
import { HelpOutlineIcon } from "Assets/Icons";

const ApprovalConfirm = () => {
  const [arrovalConfirm, setApprovalConfirm] = useRecoilState(approvalGlobal);

  const onClose = () => {
    arrovalConfirm.cancel();
    setApprovalConfirm({ isOpen: false, submit: null, cancel: null });
  };

  return (
    <>
      <Modal open={arrovalConfirm.isOpen} onClose={onClose}>
        <Box sx={Styles.confirmWrapper}>
          <HelpOutlineIcon sx={Styles.approvalConfirmDeleteIcon} />
          <Typography sx={Styles.message}>Do you want to save and submit it for Admin's approval?</Typography>
          <Box sx={Styles.buttonWrapper}>
            <Button variant="radius" onClick={() => arrovalConfirm.submit()} sx={Styles.submitButton}>
              {" "}
              Submit
            </Button>
            <Button variant="radius" onClick={onClose} sx={Styles.deleteButton}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ApprovalConfirm;
