import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

const CustomAlert = ({isOpen, setIsOpen, message}) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setIsOpen(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    return (
        <Snackbar 
        sx={{width: "40vw"}}
        open={isOpen} 
        fullWidth 
        autoHideDuration={2000} 
        onClose={handleClose} 
        anchorOrigin={{vertical: "top", horizontal: "center"}}
    >
        <Alert elevation={6} onClose={handleClose} severity="info" sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>
    )
};

export default CustomAlert;