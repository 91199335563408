import React from "react";
import { durationTypes, durationTypesLeadEdit } from "Utils";
import { TextField, MenuItem } from "Assets/Material";

const DurationTypeSelect = (props) => {
  return (
    <React.Fragment>
      <TextField
        select
        fullWidth
        id={props?.name}
        name={props?.name}
        label={props?.label}
        value={props?.value ? props?.value :  props?.type && props?.type === 'Lead edit'  ? 'Hours' : ''}
        size="small"
        margin="dense"
        autoComplete="off"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={props?.onChange}
        onBlur={props?.onBlur}
        error={props?.onError}
        helperText={props?.onError}
        sx={props?.sx}
      >
        {
          props?.type && props?.type === 'Lead edit' 
          ? durationTypesLeadEdit.map((item, index) => (
            <MenuItem key={index} value={item.key}>
              {item.value}
            </MenuItem>
          ))
          : durationTypes.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.value}
            </MenuItem>
          ))
        }
      </TextField>
    </React.Fragment>
  );
};

export default DurationTypeSelect;