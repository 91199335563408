import React from "react";
import Styles from "Common/PageHeader/Styles";
import { H1, Grid, Button, Box, useMediaQuery, useTheme } from "Assets/Material";

const PageHeader = (props) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Grid container sx={Styles.pageHeaderWrapper}>
        <Grid item xs={9} sm={6}>
          <H1 sx={Styles.pageHeaderTitle}>{props?.pageTitle}</H1>
        </Grid>
        {/* {
          !props?.isSwitch &&
          <Grid item xs={3} sm={6}>
            {
              props?.isButton &&
              <Box sx={Styles.pageHeaderButtonWrapper}>
                <Button variant="radius" onClick={props?.buttonFunc}>
                  {props?.buttonLabel}
                </Button>
              </Box>
            }
          </Grid>
        } */}

        {props?.isSwitch && (
          <Grid item xs={12} sm={6}>
            <Box sx={xs ? Styles.pageHeaderButtonWrapper1 : Styles.pageHeaderButtonWrapper}>
              {/* <button>Costing</button>&nbsp;&nbsp;&nbsp;
              <button>Capabilities</button>&nbsp;&nbsp;&nbsp;
              <button>Industries</button> */}
              <Button
                variant="radius" 
                id ="costing" 
                style={{backgroundColor :!props?.costing ? '#607ebb' : '', color : !props?.costing ? 'white' : '' }}
                onClick={(e) => props.change_Component(e)
              }>Costing
              </Button>&nbsp;
              <Button 
                variant="radius" 
                id ="capabilities" 
                style={{backgroundColor :props?.capabilities ? '#607ebb' : '', color : props?.capabilities ? 'white' : '' }}
                onClick={(e) => props.change_Component(e)
              }> Capabilities
              </Button>&nbsp;
              <Button 
                variant="radius" 
                id ="industries" 
                style={{backgroundColor :props?.industries ? '#607ebb' : '', color : props?.industries ? 'white' : '' }}
                onClick={(e) => props.change_Component(e)
              }> Industries
              </Button>
              {/* {props?.switch} */}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PageHeader;
