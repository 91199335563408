import { useEffect, useState } from "react";
// import classes from "../../ProjectLeads/component/ProjectLeadsDetails/Details.module.css";
import classes from "../../ProjectLeads/component/ProjectLead.module.css"
import { searchByFilter, searchTermFilter, sortByFilter, sortOrderFilter, sortOrderListGlobal, toasterGlobal, useRecoilState } from "Atom";
import { CityAutocomplete, CreatableAutocomplete, DurationTypeSelect, Form, FormButton, IndustryAutocomplete, LanguageAutocomplete, LeadStatusTypeSelect, SelectCountryCode, TextInput } from "Common";
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Zoom } from "@mui/material";
import { Formik } from "formik";
import ClientAutocomplete from "Common/ClientAutocomplete/ClientLAutoComplete";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import { CustomDatePicker } from "Common/DateInput/DatePicker";
import Styles from "../../../Assets/Styles/TalentManagement/TalentManagementStyles"
import { emailRegex, isValidString } from "Utils";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import UnapprovedLeadIndustryAutoComplete from "Common/IndustryAutocomplete/UnapprovedLeadIndustryAutoComplete";
import UnapprovedLeadCapabilityAutoComplete from "Common/CreatableAutocomplete/UnapprovedLeadCapabilityAutoComplete";
import UnapprovedLeadLanguageAutocomplete from "Common/LanguageAutocomplete/UnapprovedLeadLanguageAutoComplete";
import { useEditUnapproveLeadService } from "Services/ProjectLeadsServices";
import { isValidPhoneNumber } from "libphonenumber-js";

const EditMarvinLeads = (props) => {
    const [skipped, setSkipped] = useState(new Set());
  
    const [savedLeadId, setSavedLeadId] = useState(null);
    const [error, setError] = useState([{ a: false, b: false, c: false, d: false, e:false, f: false }]);
    const setToaster = useRecoilState(toasterGlobal)[1];
    const setSortBy = useRecoilState(sortByFilter)[1];
    const setSearchBy = useRecoilState(searchByFilter)[1];
    const setSortOrder = useRecoilState(sortOrderFilter)[1];
    const setSearchTerm = useRecoilState(searchTermFilter)[1];
    const setSortOrderList = useRecoilState(sortOrderListGlobal)[1];
    
    const [onSite, setOnSite] = useState(false);
    const [locationValue, setLocationValue] = useState("");
    const [city, setCity] = useState();
    const [cityErrorMessage, setCityErrorMessage] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
  
    const [isCountryCodeError, setIsCountryCodeError] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [value, setValue] = useState();
    let consultantRequirment_data;
    let errorData;
  
    const [consultantRequirment, setConsultantRequirment] = useState([consultantRequirment_data]);
  
    const [length, setLength] = useState(0);
    const [questionnaireLength, setQuestionnaireLength] = useState(0);
    const [questionnaireError, setQuestionnaireError] = useState(false);



    const onSuccesss = (data) => {};

    const onError = (error) => {
      console.log({ error });
    };

    const {mutate: editUnapprovedLead} = useEditUnapproveLeadService(onSuccesss, onError)
  

    const steps = ["Basic Details", "Project Details", "Consultant Requirements"];

    const isStepOptional = (step) => {
        return step === 1;
      };
      
      const isStepSkipped = (step) => {
        return skipped.has(step);
      };
      
      const handleNext = () => {
    
        let newSkipped = skipped;
        if (isStepSkipped(props.activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(props.activeStep);
        }
        props.handleNext();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      };
      
      const handleBack = () => {
        props.handleBack();
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
      const handleSkip = () => {
        
        if (!isStepOptional(props.activeStep)) {
          // You probably want to guard against something like this,
          // it should never occur unless someone's actively trying to break something.
          throw new Error("You can't skip a step that isn't optional.");
        }
        props.handleNext();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
          const newSkipped = new Set(prevSkipped.values());
          newSkipped.add(props.activeStep);
          return newSkipped;
        });
      };

      const resetFilters = () => {
        setSearchBy("1");
        setSearchTerm("");
        setSortBy("id");
        setSortOrder("DESC");
        setSortOrderList([{ id: 1, name: "Default", value: "DESC" }]);
      };
    
      const handleRichTextChange = (values, content) => {
        values.problem_description = content
      }


      const validateForm = (values) => {
        const errors = {};
        if (!values.name) {
          errors.name = "Name is required.";
        } else if (values.name.trim() === "") {
          errors.name = "Invalid input"
        }
        if (!values.organization || values.organization.trim() === ""){
          errors.organization = "Organization is required.";
        }
        if (!values.email && values.email.trim() === ""){
          errors.email = "Email is required";
        } else if (values.email && !emailRegex.test(values.email)) {
          errors.email = "Invalid email address";
        }
        if (values.mobile && isNaN(values.mobile) && values.mobile.trim() === "") {
          errors.mobile = "Only number is allowed.";
        } 
    
        
        if (values.country_id && values.country_code && values.mobile) {
          let isValidPhone = isValidPhoneNumber(values.mobile, values.country_id);
          if (!isValidPhone) {
            errors.mobile = "Invalid contact number format";
          }
        }
    
        if (values.website && values.website.trim() === "") {
          errors.website = "Input valid website link";
        }
    
    
        if (values.budget && isNaN(values.budget)) {
          errors.budget = "Only number is allowed."; 
        }
    
        
        if (values.duration && isNaN(values.duration)){
          errors.duration = "Only number is allowed.";
        }
        if (values.noOfCon && isNaN(values.noOfCon)) {
          errors.noOfCon = "Only number is allowed.";
        }
    
        return errors;
      };


      useEffect(() => {
        if (props.dataEdit && props.dataEdit.consultant_requirements.length > 0) {
          consultantRequirment_data = props.dataEdit.consultant_requirements.map((val) => ({
            capabilities: val.capabilities,
            number_of_consultants: val.number_of_consultants,
            years_of_experience: val.years_of_experience,
            remote_working: val.remote_working,
            compensation_low: val.compensation_low || 0,
            compensation_high: val.compensation_high || 0,
            engagement_preference: val.engagement_preference,
            // id: val.id,
            capabilities: val.capabilities,
            capabilitiesAdd: val.capabilities.map((val, index) => ({value: index + 1, label: val})),
            duration_type : val?.duration_type,
            requirement_title: val?.requirement_title,
            // status: val?.status,
            languages: val?.languages,
            languagesAdd: val?.languages.map((val, index) => ({value: index + 1, label: val})),
            
          }));

        //   console.log(props.dataEdit.location)
    
          if (props.dataEdit.location === "remote") {
            setLocationValue("remote")
            setOnSite(false)
            setCity("")
          } else if (props.dataEdit.location === "" || props.dataEdit.location === null || props.dataEdit.location === undefined){
            //do nothing
          } else {
            setLocationValue("onsite")
            setOnSite(true)

            if (props.dataEdit.location !== "onsite") {
              setCity(props.dataEdit.location)
            }else{
              setCity("")
            }
          }
    
          errorData = props.dataEdit.consultant_requirements.map((val) => ({ a: false, b: false, c: false, d: false }));
        } else {
          consultantRequirment_data = [
            {
              capabilities: null,
              number_of_consultant: 0,
              years_of_experience: 0,
              remote_working: true,
              compensation_low: 0,
              compensation_high: 0,
              engagement_preference: "",
              duration_type : "",
              questionnaireField: [],
              requirement_title: null,
              status: 0,
              languages: []
            },
          ];
    
          errorData = [{ a: false, b: false, c: false, d: false }];
        }
    
        setConsultantRequirment(consultantRequirment_data);
        setError(errorData);
        return () => {
          resetFilters();
          // setActiveStep(0);
        };
      }, [props?.dataEdit]);


      const handleLeadSubmit = async (values) => {
        setIsFormSubmit(true)

        //loop through the consultant_requirement array and remove id, duration_type and capabilitiesAdd key from all the objects
        consultantRequirment.map((val) => {
            delete val.id;
            delete val.duration_type;
            delete val.capabilitiesAdd;
            delete val.languagesAdd;
            delete val.remote_working
        });
        
        let data = {...values, consultant_requirements: consultantRequirment};

        // console.log(data)

        editUnapprovedLead(data, {
            onSuccess: (data) => {
                setIsFormSubmit(false)
                // console.log(data)
                props?.onClose()
                setToaster({ isOpen: true, status: "success", message: data.message });
            },
            onError: () => {
                setIsFormSubmit(false)
            }
        })
        
      }

    return (
        <>
           <Form  onOpen={props?.onOpen} onClose={props?.onClose} title={props.isClientLead ? "Review & Approve project brief " : "Project Leads"} >
           <Stepper activeStep={props.activeStep} variant="dots" style={{ marginButtom: "2rem" }}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                stepProps.completed = false;
                }
                return (
                <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
                );
            })}
            </Stepper>

            <>
              <Formik 
              initialValues={
                {...props.dataEdit, 
                 industriesAdd:  props?.dataEdit?.industries.map((val, index) => ({value: index + 1, label: val}))
                }}
                onSubmit={(values) => handleLeadSubmit(values)}
                validate={(values) => validateForm(values)}
            >
                    {({ values, errors, handleChange, isValid, dirty, handleSubmit, setFieldValue, setFieldTouched, handleBlur, touched }) => (
                    <form onSubmit={handleSubmit} >
                 {props.activeStep === 0 && (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginTop: "1rem" }}
                      title={""}   
                    >
                      <Grid item xs={12} sm={6}>
                      <ClientAutocomplete 
                          label="Name *"
                          limit={10}
                          value={{label: values.name, value: values.name}}
                          type="name"
                          initialValues={values.name}
                          getValue = {(client) => {
                            
                            setFieldValue("name", client.name, true);
                            setFieldValue("email", client.email, true);
                            setFieldValue("mobile", client.phone, true);
                            setFieldValue("country_code", client?.country_code ? "+" + client?.country_code : "", true);
                            setFieldValue("organization", client?.organization, true);

                            //this is a fix i found on the internet to keep the setFieldValue in sync with the formik state
                            setTimeout(() => setFieldTouched('name', true));
                          }}
                          placeholder="Search client by name" 
                          onError={errors.name}
                        />

                      </Grid>
                      <Grid item xs={12} sm={6}> 
                      <ClientAutocomplete 
                          label="Email ID *"
                          limit={10}
                          type="email"
                          initialValues={values.email}
                          getValue = {(client) => {
                            
                            setFieldValue("name", client.name, true);
                            setFieldValue("email", client.email, true);
                            setFieldValue("mobile", client.phone, true);
                            setFieldValue("country_code", client?.country_code ? "+" + client?.country_code : "", true);
                            setFieldValue("organization", client?.organization, true);

                            //this is a fix i found on the internet to keep the setFieldValue in sync with the formik state
                            setTimeout(() => setFieldTouched('email', true));
                          }}
                          placeholder="Search client by email" 
                          onError={errors.name}
                          value={{label: values.email, value: values.email}}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <SelectCountryCode
                          requiredForm={false}
                          // isRequired={isCountryCodeError}
                          initialValue={values.country_code.split("+")[1]}
                          getValue={(country) => {
                            values.country_code = `+${country.phone}`;
                            values.country_id = country.code;
                            // setIsCountryCodeError(false);
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <TextInput
                          type="text"
                          label="Contact Number"
                          name="mobile"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile}
                          onError={errors.mobile && touched.mobile && errors.mobile}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          type="text"
                          label="Organization *"
                          name="organization"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.organization}
                          onError={errors.organization && touched.organization && errors.organization}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextInput
                          type="text"
                          label="Budget Min "
                          name="budget_min"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.budget_min}
                        //   onError={errors.organization && touched.organization && errors.organization}
                        />
                      </Grid>


                      <Grid item xs={12} sm={3}>
                        <TextInput
                          type="text"
                          label="Budget Max "
                          name="budget_max"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.budget_max}
                        //   onError={errors.organization && touched.organization && errors.organization}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} sx={{ paddingTop: "0!important" }}>
                        <UnapprovedLeadIndustryAutoComplete
                          initialValues={values.industriesAdd }
                          getValue={(value) => {
                            values.industries = value;
                          }}
                        />
                      </Grid>

                      
                      <Grid item xs={12} sm={6}>
                        {/* <LeadStatusTypeSelect
                          value={values.lead_status}
                          label="Lead Status *"
                          name="lead_status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onError={errors.lead_status && touched.lead_status && errors.lead_status}
                        /> */}
                      </Grid>
                    </Grid>
                  )}

                    {props.activeStep === 1 && (
                    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                      <Grid item xs={12} sm={12}>
                        <ReactQuill value={values.problem_description} onChange={value => handleRichTextChange(values, value)} theme="snow" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="duration"
                          label="Duration"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration}
                          onError={errors.duration && touched.duration && errors.duration}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <DurationTypeSelect
                          name="duration_type"
                          label="Duration Type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration_type}
                          onError={errors.duration_type && touched.duration_type && errors.duration_type}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <CustomDatePicker
                          // sx={{width: "50%"}}
                          label="Start Date"
                          initialValues={values?.estimated_start_date ? new Date(values?.estimated_start_date) : new Date()}
                          getDate={(date) => (values.estimated_start_date = new Date(date))}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl
                          fullWidth
                          component="fieldset"
                          sx={Styles.engagementPreferenceWrapper}
                        >
                          <FormLabel
                            sx={{ ...Styles.engagementPreferenceFormLabel }}
                          >
                            Location Preference
                          </FormLabel>
                          <RadioGroup
                            row
                            fullWidth
                            style={{
                              ...Styles.radioButtonWrapper,
                              ...{ justifyContent: "start", gap: "1rem" },
                            }}
                            name="commitment_preference_id"
                            value={locationValue}
                            onChange={(e) => {
                              const {value} = e.target;
                              if (value === "onsite") {
                                 values.location = "onsite";
                                 setLocationValue("onsite")
                                 setOnSite(true)
                              } else {
                                values.location = value;
                                setLocationValue("remote")
                                setOnSite(false)
                              }
                            }}
                          >
                            <FormControlLabel
                              value={"remote"}
                              label="Remote Only"
                              control={<Radio size="small" />}
                              sx={Styles.radioButtonLabel}
                            />
                            <FormControlLabel
                              value={"onsite"}
                              label="On Site Possible"
                              control={<Radio size="small" />}
                              sx={Styles.radioButtonLabel}
                            />
                          </RadioGroup>
                          
                        </FormControl>
                      </Grid>

                      {onSite && 
                        <Grid item xs={12} md={4}>
                          <CityAutocomplete initialValues={city}  
                          label="City"
                          getValue={(value) => {
                            values.location = value;
                            setCity(value)
                            setCityErrorMessage(false);
                          }}
                          placeholder="Search Cities"
                          />
                          {cityErrorMessage && <div style={{color: 'red'}}>Please select a city</div>}
                        </Grid>
                      }
                      
                    </Grid>
                  )}

                    {props.activeStep === 2 && (
                    <Grid
                      container
                      style={{
                        marginTop: "1rem",
                        transition: "30s",
                        transition: "width 3s",
                        transitionTimingFunction: "linear",
                      }}
                    >
                      {consultantRequirment &&
                        consultantRequirment.map((res, index) => {
                          if (index === length) {
                            return (
                              <div
                                key={index}
                                style={{
                                  background: "aliceblue",
                                  padding: "12px",
                                  transition: "30s",
                                  transitionTimingFunction: "linear",
                                }}
                              >
                                <Zoom
                                  in={true}
                                  // style={{ transformOrigin: "0 0 0" }}
                                  // {...{ timeout: 1000 }}
                                  style={{ transitionDelay: "500ms" }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} sx={{ paddingTop: "0!important" }}>
                                      <TextInput
                                        type="text"
                                        label="Requirement title *"
                                        name="requirement_title"
                                        required
                                        onChange={(e) => {
                                          consultantRequirment[index].requirement_title = e.target.value
                                          setConsultantRequirment([...consultantRequirment]);
                                          let requirement = e.target.value;

                                          if (requirement == "" || requirement == null || requirement == undefined || requirement.trim() == "") {
                                            error[index].e = "Requirement title is required";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].e = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                        value={consultantRequirment[index].requirement_title}
                                        onBlur={handleBlur}
                                        onError={error[index] && error[index].e}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} sx={{ paddingTop: "0!important" }}>
                                      <UnapprovedLeadCapabilityAutoComplete
                                        bg={true}
                                        isSelectOnly={true}
                                        isLabel={true}
                                        initialValues={
                                          consultantRequirment[index].capabilitiesAdd
                                        }
                                        getValue={(value) => {
                                          consultantRequirment[index].capabilities = value;
                                          setConsultantRequirment([...consultantRequirment]);
                                          
                                          let capabilities = value;
                                          if (capabilities == "" || capabilities == null || capabilities == undefined && capabilities.trim() == "") {
                                            error[index].f = "Capabilities are required";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].f = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                      />
                                       {error[index].f && <p style={{fontSize: '0.75rem', color: "#ff1744", margin: "4px 0 0 14px"}}>{error[index].f}</p>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextInput
                                        type="text"
                                        label="No. of Consultants *"
                                        name="noOfCon"
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          let number_of_consultant = e.target.value;

                                          consultantRequirment[index].number_of_consultants = number_of_consultant
                                          setConsultantRequirment([...consultantRequirment]);

                                          if (k == false) {
                                            error[index].a = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if ( number_of_consultant == undefined || number_of_consultant == null || number_of_consultant.trim() == "" || number_of_consultant == "" || number_of_consultant == 0) {
                                            error[index].a = "Number of consultants is required and cannot be zero";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].a = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                          
                                        }}
                                        onError={error[index] && error[index].a}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].number_of_consultants}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextInput
                                        // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        type="text"
                                        label="Years of Experience"
                                        name="experience"
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          if (k == undefined || k == "" || k == null) {
                                            consultantRequirment[index].experience = e.target.value;
                                            setConsultantRequirment([...consultantRequirment]);

                                            // setError([...(error[index].b = false)]);
                                          } else {
                                            consultantRequirment[index].experience = e.target.value;
                                            setConsultantRequirment([...consultantRequirment]);
                                            // setError([...(error[index].b = true)]);
                                          }

                                          let experience = e.target.value;
                                          consultantRequirment[index].years_of_experience = experience;
                                          setConsultantRequirment([...consultantRequirment]);


                                          if (k == false) {
                                            error[index].b = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (experience.trim() == "") {
                                            error[index].b = "Enter valid input";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].b = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                        onError={error[index] && error[index].b && "Only number is allowed."}
                                        // onChange={(e) => {
                                        //   console.log(typeof e.target.value);
                                        //   consultantRequirment[index].experience = e.target.value;
                                        //   setConsultantRequirment([...consultantRequirment]);
                                        // }}
                                        // onError={errors.experience && touched.experience && errors.experience}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].years_of_experience}
                                      />
                                    </Grid>
                                   
                                    <Grid item xs={12} sm={6}>
                                      <FormControl
                                        fullWidth
                                        component="fieldset"
                                        sx={Styles.engagementPreferenceWrapper}
                                      >
                                        <FormLabel
                                          sx={{ ...Styles.engagementPreferenceFormLabel, background: "aliceblue" }}
                                        >
                                          Engagement Preference
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          fullWidth
                                          style={{
                                            ...Styles.radioButtonWrapper,
                                            ...{ justifyContent: "start", gap: "6px" },
                                          }}
                                          name="commitment_preference_id"
                                          onChange={(e) => {
                                            consultantRequirment[index].engagement_preference = e.target.value;
                                            setConsultantRequirment([...consultantRequirment]);
                                          }}
                                          value={consultantRequirment[index].engagement_preference}
                                        >
                                          <FormControlLabel
                                            value={"Full Time"}
                                            label="Full Time"
                                            control={<Radio size="small" />}
                                            sx={Styles.radioButtonLabel}
                                          />
                                          <FormControlLabel
                                            value={"Part Time"}
                                            label="Part Time"
                                            control={<Radio size="small" />}
                                            sx={Styles.radioButtonLabel}
                                          />
                                          <FormControlLabel
                                            value={"Both"}
                                            label="Both"
                                            control={<Radio size="small" />}
                                            sx={Styles.radioButtonLabel}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={2.5}>
                                      <TextInput
                                        type="text"
                                        label="Compensation Range"
                                        name="organization"
                                        placeholder = "Min"
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          let compensation_low = e.target.value;
                                          consultantRequirment[index].compensation_low = compensation_low;
                                          setConsultantRequirment([...consultantRequirment])

                                          if (k == false) {
                                            error[index].c = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (compensation_low.trim() == "") {
                                            error[index].c = "Enter valid input";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].c = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }

                                          
                                        }}
                                        onError={error[index] && error[index].c && "Only number is allowed."}
                                        // onChange={(e) => {
                                        //   consultantRequirment[index].compensation_low = e.target.value;
                                        //   setConsultantRequirment([...consultantRequirment]);
                                        // }}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].compensation_low}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={1}
                                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                      To
                                    </Grid>
                                    <Grid item xs={6} sm={2.5}>
                                      <TextInput
                                        type="number"
                                        // label="Compensation"
                                        name="second"
                                        placeholder = "Max"
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          let compensation_high = e.target.value;
                                          consultantRequirment[index].compensation_high = compensation_high;
                                          setConsultantRequirment([...consultantRequirment]);

                                          if (k == false) {
                                            error[index].d = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (compensation_high.trim() == "") {
                                            error[index].d = "Enter valid input";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].d = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                        onError={error[index] && error[index].d && "Only number is allowed."}
                                        // onChange={(e) => {
                                        //   consultantRequirment[index].compensation_high = e.target.value;
                                        //   setConsultantRequirment([...consultantRequirment]);
                                        // }}
                                        value={consultantRequirment[index].compensation_high}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                       <UnapprovedLeadLanguageAutocomplete
                                          initialValues={consultantRequirment[index].languagesAdd}
                                          isLabel={true}
                                          getValue={(value) => {
                                            consultantRequirment[index].languages = value;
                                            // consultantRequirment[index].languagesAdd = value.languagesAdd;
                                            setConsultantRequirment([...consultantRequirment]);
                                          }
                                        } 
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6}>
                                      <DurationTypeSelect
                                        name="duration_type"
                                        label="Duration Type"
                                        onChange={(e) => {
                                          consultantRequirment[index].duration_type = e.target.value;
                                          setConsultantRequirment([...consultantRequirment]);
                                        }}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].duration_type}
                                        onError={errors.duration_type && touched.duration_type && errors.duration_type}
                                        type= "Lead edit"
                                      />
                                    </Grid> */}

                                    <Grid item xs={12} sm={6}>
                                      
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                    </Grid>
                                  </Grid>
                                </Zoom>
                              </div>
                            );
                          }
                        })}

                      <div className={classes.grid}>
                      <div className={classes.flexCenter}>
                          <button
                            type="button"
                            // style={
                            //   consultantRequirment[0].capabilitiesAdd &&
                            //   consultantRequirment[0].capabilitiesAdd.length > 0 &&
                            //   consultantRequirment[0].number_of_consultant > 0
                            //     ? {}
                            //     : { color: "gray", pointerEvents: "none" }
                            // }
                            className={classes.addButton}
                            onClick={() => {
                              let addData = [...consultantRequirment];
                              addData.push({
                                capabilities: null,
                                number_of_consultant: null,
                                experience: null,
                                compensation_low: null,
                                compensation_high: null,
                                engagement_preference: "",
                                status: 0,
                                languages: null
                              });
                              setConsultantRequirment(addData);
                              // setQuestionnaireField([])
                              let errorSet = [...error];
                              errorSet.push({ a: false, b: false, c: false, d: false });
                              setError(errorSet);
                              setLength((pre) => pre + 1);
                            }}
                          >
                            <AddCircleOutlinedIcon
                              style={
                                consultantRequirment[0].capabilitiesAdd &&
                                consultantRequirment[0].capabilitiesAdd.length > 0 &&
                                consultantRequirment[0].number_of_consultant > 0
                                  ? {}
                                  : { color: "gray", pointerEvents: "none" }
                              }
                            />
                          </button>
                          Add Consultant Requirement
                        </div>
                        {consultantRequirment.length !== 1 && (
                          <div className={classes.flexCenter} style={{ justifyContent: "end" }}>
                            <KeyboardArrowLeft
                              style={length === 0  ? { color: "gray", pointerEvents: "none" } : {}}
                              
                              onClick={() => {
                                setLength((pre) => pre - 1);
                              }}
                            />
                            {length + 1} of {consultantRequirment.length}
                            <KeyboardArrowRight
                              style={
                                length + 1 === consultantRequirment.length || error[length].e || error[length].a || error[length].f || error[length].b || error[length].c || error[length].d
                                  ? { color: "gray", pointerEvents: "none" }
                                  : {}
                              }
                              onClick={() => {
                                setLength((pre) => pre + 1);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Special Request"
                          name="special_request"
                          fullWidth
                          multiline
                          disabled={true}
                          margin="dense"
                          variant="outlined"
                          rows={2}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.special_request}
                          onError={errors.special_request && touched.special_request && errors.special_request}
                        />
                      </Grid>
                    </Grid>
                  )}


                <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pb: window.innerWidth === 900 ? 0 : 10 }}>
                    <Button
                      type="button"
                      sx={{ padding: "0 6px", minWidth: "auto", mr: 1 }}
                      size="small"
                      variant="contained"
                      disabled={props.activeStep === 0}
                      onClick={handleBack}
                    >
                      <KeyboardDoubleArrowLeftIcon sx={{ color: "white" }} />
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(props.activeStep) && !onSite && (
                      <Button
                        type="button"
                        size="small"
                        sx={{ padding: "0 6px", minWidth: "auto", mr: 1 }}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {props.activeStep === 2 ? (
                      <FormButton
                        size="small"
                        sx={{ padding: "0 6px", minWidth: "auto" }}
                        disabled={isFormSubmit}
                        isDisabled = {disableSaveButton}
                        label={props?.isEdit ? ( !props.isClientLead ? "Save" : "Save & Approve") : "Add"}
                      />
                    ) : (
                      <Button
                        type="button"
                        sx={{ padding: "0 6px", minWidth: "auto" }}
                        size="small"
                        variant="contained"
                        onClick={handleNext}
                        disabled={props.dataEdit ? !(isValid && errors) : !(isValid && errors && dirty)}
                        // dirty
                        style={props.activeStep === 2 ? { pointerEvents: "none", background: "gray" } : {}}
                      >
                        <KeyboardDoubleArrowRightIcon sx={{ color: "white" }} />
                      </Button>
                    )}
                  </Box>
                </form>
                )}
                
              </Formik>
            </>
           </Form>
        </>
    )
};

export default EditMarvinLeads;