import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
const AdminChatInfo = (props) => {
    const data = props.data;
    // console.log(props.data);
    return (
        <Box sx={{ padding: 2, borderBottom: '2px solid #E5E6EB' }}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 700, color: '#111128' }}>{data.response.lead.name}</Typography>
            <Typography sx={{ fontSize: '0.875rem', fontWeight: 500, color: '#6B7180' }}>at {data.response.lead.organization} </Typography>
        </Box>
    )
}

export default AdminChatInfo;