import API from "Services";
import Cookies from "js-cookie";
import {
  GET_CLIENT_PROFILE_DETAILS,
  GET_CITY,
  FILE_UPLOAD,
  UPDATE_CLIENT_PROFILE,
  GET_CLIENTS_PROJECTS,
  GET_CLIENTS_SUGGESTED_CONSULTANT,
  GET_CLIENT_DETAILS,
} from "Utils/ApiConstants";

export const getClientProfileDetailsService = async (id) => {
  try {
    const response = await API.get(`${GET_CLIENT_PROFILE_DETAILS}?user_id=${id}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getClientNameOrEmail = async (search_term, limit, type) => {
  try {
    const response = await API.get(`${GET_CLIENT_DETAILS}?search_term=${search_term}&limit=${limit}&type=${type}`); 
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}

export const getCityService = async (data) => {
  try {
    const response = await API.get(`${GET_CITY}?search_term=${data}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const uploadProfilePhoto = async (data) => {
  try {
    const response = await API.post(FILE_UPLOAD, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const updateClientProfile = async (data) => {
  try {
    const response = await API.post(UPDATE_CLIENT_PROFILE, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getClientsProjects = async (data) => {
  try {
    const response = await API.post(GET_CLIENTS_PROJECTS, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getClientsSuggestedConsultant = async (data) => {
  try {
    const response = await API.post(GET_CLIENTS_SUGGESTED_CONSULTANT, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
