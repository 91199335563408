import React from 'react'
import { CloseIcon } from 'Assets/Icons';
import { UL, LI, Box, Modal } from "Assets/Material";
import Styles from "Common/MobileTableDataPopup/Styles";

const MobileTableDataPopup = (props) => {
  return (
    <Modal
      open={props?.onOpen}
      onClose={() => props?.onClose()}
    >
      <Box sx={Styles.tablePopupWrapper} >
        <CloseIcon
          onClick={() => props?.onClose()}
          sx={Styles.tablePopupCloseIcon}
        />
        <UL sx={Styles.tablePopupList}>
          {
            props?.data &&
            JSON.parse(props?.data)?.map((i, index) =>
              <LI
                key={index}
                sx={
                  (JSON.parse(props?.data).length > 1 && +JSON.parse(props?.data).length - 1 !== +index)
                    ? Styles.tablePopupListItem1
                    : Styles.tablePopupListItem2
                }
              > {i.name}</LI>
            )
          }
        </UL>
      </Box>
    </Modal>
  )
}

export default MobileTableDataPopup;