import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import Styles from "Common/Toaster/Styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Modal, Box, Typography } from '@mui/material';
import { useRecoilState, toasterGlobal } from "Atom";

const Toaster = () => {

  const [toaster, setToaster] = useRecoilState(toasterGlobal);
  const onClose = () => {
    if(typeof toaster.onClose === 'function'){
      toaster.onClose();
    }
    setToaster({ isOpen: false, staus: null, message: null });
    
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <Box sx={Styles.toasterWrapper}>
          <CloseIcon sx={Styles.closeIcon} onClick={onClose} />
          {
            toaster.status === "success"
              ? <CheckCircleOutlineIcon sx={Styles.toasterSuccessIcon} />
              : toaster.status === "fail"
                ? <HighlightOffIcon sx={Styles.toasterFailIcon} />
                : null
          }
          <Typography sx={Styles.message}> {toaster.message} </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default Toaster;