const Styles = {
  contactWrapperDesktop: {
    display: "flex",
    justifyContent: "start",
  },
  contactWrapperMobile: {
    display: "flex",
    justifyContent: "space-between",
  },
  contactNumber: {
    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
      padding: "5px 20px",
      borderRadius: "50px",
      border: "2px solid",
      borderColor: (theme) => theme.palette.primary.main,
    },
    "& .css-16mkh9p-MuiPaper-root-MuiPopover-paper": {
      padding: "5px 20px",
      borderRadius: "50px",
      border: "2px solid",
      borderColor: (theme) => theme.palette.primary.main,
    },
  },
  contactNumber1: {
    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
      padding: "0",
      borderRadius: "0",
      border: "0",
      borderColor: "none",
      boxShadow: "0",
    },
    "& .css-16mkh9p-MuiPaper-root-MuiPopover-paper": {
      padding: "0",
      borderRadius: "0",
      border: "0",
      borderColor: "none",
      boxShadow: "0",
    },
  },
  anchorOrigin: {
    vertical: "center",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "right",
  },
};

export default Styles;
