import React from 'react'
import LoaderImage from "Assets/Images/Loader.gif"
import Styles from "Common/Loader/Styles";
import { Box, IMG } from "Assets/Material";

const Loader = () => {
  return (
    <Box sx={Styles.loaderWrapper}>
      <IMG src={LoaderImage} alt={LoaderImage} sx={Styles.loader} />
    </Box>
  )
}

export default Loader;