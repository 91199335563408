import React, { useState, useEffect, useRef } from "react";
import { TextField, Autocomplete, LI, Typography, Box } from "Assets/Material";
import CountryCode from "Utils/CountryCode";
import AsyncSelect from "react-select";
import { getClientNameOrEmail } from "Services/ClientProfileServices";

const ClientAutocomplete = (props) => {

  const [value, setValue] = useState({label: props.initialValues, value: props.initialValues});
  const [dataList, setDataList] = useState([]);




  const getClientDetails = async (search_term, limit) => {
    try {

        //do not api call if search term is empty
        if (search_term.length < 1) {
            return;
        }

        let search = search_term.trim();

        const response = await getClientNameOrEmail(search, limit, props.type);  
        
        if (response && response.status == "success") {
            let clients = response.data.map((item) => (
                {   value: props.type == "name" ? item.name : item.email, 
                    label:  props.type == "name" ? item.name : item.email, 
                    client_id: item.client_id, 
                    country_code: item.country_code, 
                    phone: item.phone,
                    email: item.email,
                    organization: item.company_name,
                    name: item.name,
                }
            ))
            setDataList(clients)
        } else {
            setDataList([])
        }
    } catch (error) {
        console.log(error)
    }
  }


  const Styles = {
    selectTitle: {
      top: "-4px",
      left: "9px",
      fontSize: "12px",
      fontWeight: "300",
      padding: "0px 4px",
      position: "relative",
      background: "#fff",
      zIndex: 1,
    },
  };


  const clearValue = () => {

    //clear all values
    let value = {
      label: "",
      value: "",
      client_id: null,
      country_code: "",
      phone: "",
      email: "",
      organization: "",
      name: "",
    }

    props.getValue(value)
  }


  return (
    <>
      <Typography component="span" sx={Styles.selectTitle}>
        {props.label}
      </Typography>
      <AsyncSelect
        id={props.type}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
        control: (baseStyles) => ({...baseStyles, marginTop: "-15px",...props.styles}) }}
        menuPosition="fixed"
        label={props.placeholder}
        defaultInputValue={props.initialValues}
        options={dataList}
        onInputChange={(e) => {
          if (e.length >= 1 && e.trim().length >= 1) {
            getClientDetails(e, props.limit)
          } else {
            setDataList([])
          }
        }}
        onChange={(value, _) => {
          if (!value) {
            props.disableClientFields(false);
            props.disableEditModeClientFields(false);
            return clearValue();
          }
          setValue({label: value.label, value: value.value});
          //if the country code contains a + sign, remove it
          
          if (value.country_code) {
            let code = value.country_code;
            //if not string convert to string
            if (typeof code !== "string") {
              code = code.toString();
            }

            if (code.includes("+")) {
              code = code.replace("+", "");
            }

            value.country_code = code;
          }
          props.getValue(value)
          props.disableClientFields(true)
          props.disableEditModeClientFields(true)
        }}
        isClearable={true}
        value={props.value}
      />
    </>
  )

};

export default ClientAutocomplete;