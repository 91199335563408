import React from "react";
import { useHistory } from "react-router";
import { Swapper, ContactAction } from "Common";
import { Grid, Box, IMG } from "Assets/Material";
import { TalentManagementStyles as Styles, TableStyles, SwapperStyles } from "Assets/Styles";
import { Delete, Edit, ShareOutlinedIcon, LinkedIn, LinkedInDisabled } from "Assets/Icons";
import { getHashFromId, authUser } from "Utils";

const TalentManagementSwapper = (props) => {

  const history = useHistory();

  return (
    <Swapper
      onOpen={props?.isOpen}
      onClose={() => props?.onClose()}
      swapperTitle="Talent Management"
      isAction={false}
    >
      <Grid container>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Name</Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{ ...SwapperStyles.contentData, ...TableStyles.mobilelink }}
            onClick={() => history.push(`/consultant-details/${getHashFromId(props?.swapperData?.user_id)}`)}
          >
            {props?.swapperData && props?.swapperData?.first_name}{" "}
            {props?.swapperData && props?.swapperData?.last_name}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Organization</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ ...SwapperStyles.contentData, ...{ "&::first-letter": { textTransform: "capitalize" } } }}>
            {props?.swapperData && props?.swapperData?.current_employer}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Role</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ ...SwapperStyles.contentData, ...{ "&::first-letter": { textTransform: "capitalize" } } }}>
            {props?.swapperData && props?.swapperData?.current_role}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Experience</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData}>
            {props?.swapperData &&
              props?.swapperData?.total_work_experience_years}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Charges/Hr</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData}>
            {props?.swapperData && props?.swapperData?.remote_per_day_rate}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Status</Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentData}>
            {props?.swapperData && props?.swapperData?.status}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={SwapperStyles.contentTitle}>Contact Details</Box>
        </Grid>
        <Grid item xs={6}>
          <ContactAction
            onEmailOpen={() => props?.onEmailOpen(props?.swapperData)}
            data={props?.swapperData}
          />
        </Grid>

        {
          authUser().user_type === "Admin" &&
          <>
            <Grid item xs={6}>
              <Box sx={SwapperStyles.contentTitle}>Profile</Box>
            </Grid>
            <Grid item xs={6}>
              <IMG
                sx={props?.swapperData?.linkedin_profile && Styles.linkedIn}
                src={props?.swapperData?.linkedin_profile ? LinkedIn : LinkedInDisabled}
                alt={props?.swapperData?.linkedin_profile ? LinkedIn : LinkedInDisabled}
                onClick={() => props?.swapperData?.linkedin_profile && window.open(props?.swapperData?.linkedin_profile, "blank")}
              />
            </Grid>
          </>
        }
        {
          authUser().user_type === "Admin" &&
          <>
            <Grid item xs={6}>
              <Box sx={SwapperStyles.contentTitle}>Action</Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...SwapperStyles.contentData, ...SwapperStyles.swapperActionMenuWrapper }}>
                <ShareOutlinedIcon onClick={() => props?.shareConsultant()} sx={SwapperStyles.swapperIcon} />
                <Edit onClick={() => props?.edit()} sx={SwapperStyles.swapperIcon} />
                <Delete onClick={() => props?.delete()} sx={SwapperStyles.deleteIcon} />
              </Box>
            </Grid>
          </>
        }

      </Grid>
    </Swapper>
  );
};

export default TalentManagementSwapper;
