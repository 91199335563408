const SuggestClientStyles = {
  block: { display: "inline-block" },

  formWrapper: {
    margin: "auto",
    padding: "1rem",
    display: "block",
    overflowY: "auto",
    position: "relative",
    bgcolor: "background.paper",
    top: ["0", "0", "10%", "10%", "10%"],
    width: ["100%", "100%", " 50%", " 50%", " 50%"],
    height: ["100vh", "100vh", "auto", "auto", "auto"],
    maxHeight: ["100vh", "100vh", "80vh", "80vh", "80vh"],
  },
  suggestClientTableWrapper: {
    height: "44Vh",
    overflowY: "auto",
    position: "relative",
  },
  filterWrapper: {
    padding: "2% 0",
  },
  resetWrapper: {
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  checkboxCell: {
    width: "10%",
    padding: "none",
  },
  industry: {
    position: "relative",
  },
  shareButton: {
    paddingTop: "5%",
    margin: ["1rem 0", "0.5rem 0", "0.5rem 0", "0.5rem 0 0", "0.5rem 0 0"],
  },
  activeCheckbox: {
    color: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
  shareDisabled: {
    color: (theme) => theme.palette.disable,
    borderColor: (theme) => theme.palette.disable,
    "&:hover": {
      backgroundColor: "#fff",
      color: (theme) => theme.palette.disable,
      cursor: "default",
    },
  },
  formButton: {
    width: "80%",
    margin: "0 auto",
    display: "block",
  },
  consultantInformationWrapper: {
    padding: "1rem",
    position: "relative",
    background: " #F7FEFF 0% 0% no-repeat padding-box",
    border: " 1px solid #BCF3FF",
    borderRadius: " 4px",
    marginBottom: "1.5rem",
  },
  flexCenter: {
    display: " flex",
    alignItems: "center",
  },
  textLabelIn: {
    display: "inline-block",
    margin: "10px",
    fontSize: "1rem",
    fontWeight: "500",
    color: (theme) => theme.palette.text_heading.main,
    "&:first-letter": { textTransform: "capitalize" },
  },
  bg: {
    padding: "1rem",
    backgroundColor: "#90c3cf29",
  },
  consultantProfileImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
  },
  textLabel: {
    width: "200px",
    fontSize: "1rem",
    fontWeight: "500",
    display: "block",
    color: (theme) => theme.palette.text_heading.main,
    "&:first-letter": { textTransform: "capitalize" },
  },
  textLabelW: {
    fontSize: "1rem",
    fontWeight: "400",
    color: (theme) => theme.palette.text_heading.main,
    "&:first-letter": { textTransform: "capitalize" },
  },
  inputWrapper: {
    marginTop: "0.5rem",
  },
  mtb1: {
    margin: "1.5rem 0",
  },
  inputField: {
    backgroundColor: "#fff",
    width: "90%",
  },
  org: {
    paddingRight: ["1rem"],
  },
  role: {
    paddingRight: ["0", "1rem"],
  },
  exp: {
    paddingRight: ["1rem"],
  },
  saveas: {
    paddingRight: ["1rem"],
  },
  actionWrapper: {
    position: "absolute",
    right: "1rem",
  },
  actionButton: {
    color: "#607ebb",
    cursor: "pointer",
  },
  actionButtonSubmit: {
    "&:hover": {
      color: (theme) => theme.palette.success.main,
      cursor: "pointer",
    },
  },
  fileWrapper: {
    position: "relative",
  },
  uploadButtonWrapper: {
    position: "absolute",
    bottom: "0",
  },
  uploadButton: {
    fontSize: "2rem",
    color: (theme) => theme.palette.primary.main,
  },
  uploadButtonDisabled: {
    fontSize: "2rem",
    color: (theme) => theme.palette.disable,
  },
  fileNameWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: "2rem",
  },
  deleteFile: {
    position: "absolute",
    right: "0.5rem",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  consultantOrganization: {
    marginTop: "1px",
    overflow: "hidden",
    whiteSpace: "pre",
    textOverflow: "ellipsis",
    "&:first-letter": { textTransform: "capitalize" },
  },
  consultantExecutiveSummery: {
    marginTop: "1px",
    maxHeight: "5rem",
    overflow: "auto",
    "&:first-letter": { textTransform: "capitalize" },
  },
  formCloseIcon: {
    float: "right",
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  saveIcon: {
    float: "right",
    // cursor: "pointer",
    // color: (theme) => theme.palette.primary.main,
    // "&:hover": {
    //   color: (theme) => theme.palette.error.main,
    // },
  },
  hover: {
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
    fontSize: "1.5rem",
    padding: "0px 1px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#607ebb",
      color: "white",
    },
  },

  hoverDelete: {
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
    fontSize: "1.5rem",
    padding: "0px 1px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: (theme) => theme.palette.error.main,
      color: "white",
    },
  },
  commonPadding: {
    paddingTop: "0px",
    padding: ["1rem 1rem", "2rem 1.5rem 1.5rem", "1.5rem 2rem", "1.5rem 2rem", "1.5rem 2rem"],
  },
  pt1: {
    // backgroundColor: "#90c3cf29",
    padding: "1rem",
    // boxShadow: "2px 2px 4px #00000029",
  },
  onePager: {
    display: "flex",
    gap: "0.5rem",
    /* border: 1px solid gray, */
    padding: "0.5rem",
    borderRadius: "4px",
    boxShadow: "0px 1px 4px #00000029",
    margin: "10px 0px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    margin: "1rem 0px",
  },
  gap: { display: "flex", gap: "1rem" },
  header: {
    margin: 0,
    fontWeight: 600,
    color: "#444444",
    fontSize: "1.2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    paddingLeft: "0.5rem",
    borderLeft: (theme) => [`4px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  summery: {
    // "& .MuiInputBase-input": {
    //   height: "100px",
    //   padding: "4px",
    // },
  },
};
export default SuggestClientStyles;
