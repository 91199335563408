import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from "Utils";
import { DEFAULT_AUTH_LANDING } from "Utils/ApiConstants";

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  console.log("Component :: ", isLogin(), rest);
  // <Redirect to={{ pathname: "/admin-login", state: { from: props?.location } }} />

  return (
    <Route
      {...rest}
      render={props =>
        !isLogin()
          ? <Component />
          : <Redirect to={{ pathname: DEFAULT_AUTH_LANDING, state: { from: props?.location } }} />
      }
    />
  )
}

export default UnauthenticatedRoute;