import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
const AdminConversationRow = ({ toggleDrawer, list, select }) => (
    <Box
        sx={{ width: 250 }}
        role="presentation"
    // onClick={() => toggleDrawer()}
    // onKeyDown={toggleDrawer()}
    >
        <List>
            {list.map((conv, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButton onClick={() => select(conv.id)}>
                        <ListItemIcon>
                            
                        </ListItemIcon>
                        <ListItemText primary={`${conv.consultant.first_name} ${conv.consultant.last_name}`} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
        <Divider />

    </Box>
);

export default AdminConversationRow;