import API from "Services";
import { DOWNLOAD_FILE , SIGNED_URL} from "Utils/ApiConstants";

// export const FileDownloadService = async (id) => {
//   return false;
// const response = await API.get(DOWNLOAD_FILE + id);
// if (response && response.data.meta.ok === 1 && response.data.data) {
//   return response.data.data;
// }
// };


export const FileDownloadService = async (id) => await API.get(DOWNLOAD_FILE + id);

export const FetchSignURLService = async (id) => await API.get(SIGNED_URL + id);