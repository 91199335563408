import { createTheme, red } from "Assets/Material";

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outline" },
          style: {
            color: "#607ebb",
            fontSize: "1rem",
            fontWeight: "bold",
            borderRadius: "50px",
            backgroundColor: "#fff",
            padding: "0.2rem 1.2rem",

            border: "1px solid #607ebb",

            "&:hover": {
              backgroundColor: "red",
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "radius" },
          style: ({ theme }) => ({
            color: "#607ebb",
            fontWeight: "bold",
            borderRadius: "50px",
            backgroundColor: "#fff",
            border: "1px solid #607ebb",
            textTransform: "none",
            boxShadow: "0px 2px 6px #00000030",
            border: "1px solid #607ebb",

            padding: "6.5px 1rem",
            fontSize: ["0.8rem"],
            [theme.breakpoints.up("sm")]: { fontSize: "0.8rem", padding: "0.3rem 1rem" },
            [theme.breakpoints.up("md")]: { fontSize: "0.8rem", padding: "0.3rem 2rem" },
            [theme.breakpoints.up("lg")]: { fontSize: "0.8rem", padding: "0.3rem 2rem" },
            [theme.breakpoints.up("xl")]: { fontSize: "0.8rem", padding: "0.3rem 2rem" },
            "&:hover": {
              color: "#fff",
              backgroundColor: "#607ebb",
            },
          }),
        },
        {
          props: { variant: "radiusSmall" },
          style: ({ theme }) => ({
            color: "#607ebb",
            fontWeight: "bold",
            borderRadius: "30px",
            backgroundColor: "#fff",
            border: "1px solid #607ebb",
            lineHeight: "30px",
            padding: "0px 16px!important",
            fontSize: ["0.8rem"],
            boxShadow: "0px 2px 6px #00000030",
            border: "1px solid #607ebb",
            // [theme.breakpoints.up("sm")]: { fontSize: "1rem", padding: "6.5px 1rem" },
            // [theme.breakpoints.up("md")]: { fontSize: "1rem", padding: "6.5px 2rem" },
            // [theme.breakpoints.up("lg")]: { fontSize: "1rem", padding: "6.5px 2rem" },
            // [theme.breakpoints.up("xl")]: { fontSize: "1rem", padding: "6.5px 2rem" },
            "&:hover": {
              color: "#fff",
              backgroundColor: "#607ebb",
            },
          }),
        },
      ],
    },
  },
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    color: "#515d5e",
  },
  palette: {
    primary: {
      main: "#607ebb",
    },
    secondary: {
      main: "#778dfd",
    },
    text_heading: {
      main: "#444444",
    },
    text_data: {
      main: "#515d5e",
    },
    error: {
      main: red.A400,
    },
    success: {
      main: "#21BA45",
    },
    enable: "#515d5e",
    disable: "#C4C4C4",
  },
});

export default theme;
