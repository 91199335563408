import React, { useState } from "react";
import { IconButton, Box, Popover, useTheme, useMediaQuery, Tooltip } from "Assets/Material";
import { DraftsIcon, CallIcon } from "Assets/Icons";
import { TableStyles } from "Assets/Styles";
import Styles from "Common/ContactAction/Styles";

const ContactAction = (props) => {

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  const [selectedRow, setSelectedRow] = useState(null);
  const [contactNumberAnchor, setContactNumberAnchor] = useState(null);

  const onCloseContactNumber = () => {
    setContactNumberAnchor(null);
    setSelectedRow(null);
  };

  const onOpenContactNumber = (e, contact) => {
    setContactNumberAnchor(e.currentTarget);
    setSelectedRow(contact);
  }

  return (
    <Box sx={(!xs && !sm) ? Styles.contactWrapperDesktop : { ...Styles.contactWrapperMobile, ...props?.sx }}>
      <Tooltip title={props?.data?.email} placement="top" arrow >
        <IconButton sx={(xs || sm) ? { paddingLeft: "0" } : {}} onClick={() => props?.onEmailOpen()}>
          <DraftsIcon
            sx={TableStyles.buttonIcon}
          />
        </IconButton>
      </Tooltip>

      <IconButton
        onClick={(e) =>
          (props?.data?.country_code && (props?.data?.mobile || props?.data?.phone)) &&
          onOpenContactNumber(e, props?.data)
        }
      >
        <CallIcon
          sx={(props?.data?.country_code ? (props?.data?.mobile || props?.data?.phone) : {})
            ? TableStyles.buttonIcon
            : TableStyles.buttonIconDisable
          }
          
        />
      </IconButton>

      <Popover
        open={Boolean(contactNumberAnchor)}
        anchorEl={contactNumberAnchor}
        onClose={onCloseContactNumber}
        anchorOrigin={Styles.anchorOrigin}
        transformOrigin={Styles.transformOrigin}
        sx={selectedRow ? Styles.contactNumber : Styles.contactNumber1}
      >
        {selectedRow && `${selectedRow?.country_code} ${selectedRow?.mobile || selectedRow?.phone}`}
      </Popover>
    </Box>
  );
};

export default ContactAction;
