const ConsultantProfilePreferencesStyles = {
  preferencesContainer1: {
    padding: "0 0.2rem",
    marginBottom: "0.5rem",
  },
  preferencesContainer2: {
    padding: "0 0.2rem",
    marginBottom: "0.5rem",
  },
  preferencesContainer3: {
    padding: "0 0.2rem",
  },
  preferencesContainer4: {
    padding: "0 0.2rem",
  },
  preferencesContentWrapper: {
    overflow: "auto",
    padding: ["0.5rem", "1rem"],
    borderRadius: "5px",
    border: "1px solid #4444",
    backgroundColor: "#ffffff",
    boxShadow: "2px 2px 4px #00000029",
    minHeight: ["10rem", "12rem", "12rem", "13rem", "13rem"],
    maxHeight: ["10rem", "12rem", "12rem", "13rem", "13rem"],
  },
  label: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "#444444",
      fontSize: ["0.8rem", "1rem"],
      position: "relative",
      marginRight: ["0px", "none", "none", "none", "none"],
      marginBottom: ["-8px", "none", "none", "none", "none"],
      marginTop: ["-10px", "none", "none", "none", "none"],
    },
  },
  radiobt: {
    "& .MuiRadio-root": {
      size: ["5px", "13px", "15px", "15px", "15px"],
    },
  },
  fieldset: {
    marginTop: "2rem",
  },
  inputStyle: {
    "& .MuiTypography-root": {
      fontSize: ["0.8rem", "1rem"],
    },
  },
  boxWrapper: {
    paddingLeft: "9px",
  },
  preferenceData: {
    fontSize: ["12px", "13px", "15px", "15px", "15px"],
  },
  capabilities: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1rem"],
    border: "1px solid #4444",
    borderRadius: "30px",
    padding: "3px 13px",
    marginRight: "8px",
    marginBottom: "7px",
    display: "inline-block",
    wordBreak: "break-word",
    boxShadow: "2px 2px 4px #00000029",
  },
  languages: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1rem"],
    border: "1px solid #4444",
    borderRadius: "30px",
    padding: "3px 13px",
    marginRight: "8px",
    marginBottom: "7px",
    display: "inline-block",
    wordBreak: "break-word",
    boxShadow: "2px 2px 4px #00000029",
  },
  perHourChargesWrapper: {
    marginTop: "1rem",
  },
  perHourChargesInput: {
    width: "100%",
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "0.5rem",
    },
  },
  consultantNameEditInput: {
    marginTop: "0",
  },
  errorMessage: {
    color: (theme) => theme.palette.error.main,
    fontSize: "0.8rem",
  },
  loader: {
    width: "19px !important",
    height: "19px !important",
    float: "right",
  },
  editIconWrapper: {
    display: "flex",
    justifyContent: "end",
  },
  preferencesEditIcon: {
    color: (theme) => theme.palette.primary.main,
    fontSize: ["1rem", "1.5rem"],
    cursor: "pointer",
    position: "relative",
    float: "right",
  },
  preferencesSubmitIcon: {
    color: (theme) => theme.palette.primary.main,
    fontSize: ["1rem", "1.5rem"],
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  preferencesCancelIcon: {
    color: (theme) => theme.palette.primary.main,
    fontSize: ["1rem", "1.5rem"],
    marginLeft: "0.5rem",
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  capabilitySuggestion: { 
    margin: '0.25rem 0.5rem 0.25rem 0', 
    cursor: 'pointer',
    border: '1px solid transparent',
  },
  selectedCapabilitySuggestion: {
    border: '1px solid #999',
    borderColor: (theme) => theme.palette.primary.main,
    margin: '0.25rem 0.5rem 0.25rem 0',
    cursor: 'pointer',
    color: (theme) => `${theme.palette.primary.main} !important`
  },
  suggestionDialogWrapper: {
    padding: '2rem'
  },
  suggestionDialogTitle: {
    paddingTop: '4rem'
  },
  suggestionDialogCloseIcon: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    cursor: 'pointer'
  }
};

export default ConsultantProfilePreferencesStyles;
