const ConsultantProfileSharedProfile = {
  consultantTitle: {
    fontWeight: "700",
    paddingLeft: "0.5rem",
    color: (theme) => theme.palette.text_heading.main,
    fontSize: "1.2rem",
    borderLeft: (theme) => [`4px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  edit: {
    float: "right",
    color: (theme) => theme.palette.primary.main
  },
  profile: {
    width: "30%"
  },
  profile1: {
    width: "15%"
  },
  shareWith: {
    paddingX: 0,
    width: ["30%", "45%", "47%", "47%", "47%"],
    textAlign: ["center", "left", "left", "left", "left"],
  },
  date: {
    width: "15%",
  },
  space: {
    width: "7%",
    textAlign: "right"
  },
  chip: {
    margin: "0 0.5rem 0.5rem 0",
    "& .MuiChip-root": {
      fontSize: "0.7rem",
      margin: "0"
    }
  },
  chipContent: {
    height: "5rem",
    overflow: "auto",
    whiteSpace: "normal",
    textOverflow: "initial",
  }
}

export default ConsultantProfileSharedProfile;