import React from 'react';
import { Button, CircularProgress, useMediaQuery, useTheme } from "Assets/Material";

const FormButton = (props) => {

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  const Styles = {
    formButton: {
      width: "80%",
      margin: "0 auto",
      display: "block",
    },
    buttonLoader: {
      width: "auto !important",
      height: "auto !important",
      "& MuiCircularProgress-svg": {
        width: "1rem !important",
      },
    },
    loader: {
      width: "20px !important",
      height: "20px !important",
      margin: "5px"
    }
  }

  return (
    <Button
      variant="radius"
      type="submit"
      disabled={props?.isDisabled}
      label={props?.label}
      sx={(xs || sm) && Styles.formButton}
    >
      {props?.disabled ? <CircularProgress sx={Styles.loader} /> : props?.label}
    </Button>
  )
}

export default FormButton;