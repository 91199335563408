import React, { useState } from "react";
import { isLogin } from "Utils";
import { Header } from "Common";
import { authUser } from "Utils";
import { Unauthorised } from "Common";
import { TourProvider } from "@reactour/tour";
import { Route, Redirect, useHistory } from "react-router-dom";
import { Delete, EditIcon, ShareOutlinedIcon } from "Assets/Icons";
import { useRecoilState, stepperClose } from "Atom";
import { DeleteIcon, DownloadIcon, FileUploadIcon, VisibilityIcon, SaveIcon } from "Assets/Icons";
import { finishQuochmark } from "Services/QuochMark";
import "../QuochMark/QuochMark.css";

import { IMG } from "Assets/Material";

import { FiberNewIcon, MoreVert } from "Assets/Icons";
import { RemoveCircleOutlineIcon, CheckCircleOutlinedIcon, Retained } from "Assets/Icons";

const AuthenticatedRoute = ({ component: Component, access_level, ...rest }) => {
  const [stepperCloseState, setStepperCloseState] = useRecoilState(stepperClose);
  const SuggestedClientSteps = [
    {
      selector: ".first-step",
      content: (
        <div>
          <div className="grid-2-q">
            <span className="icon-q">
              <FiberNewIcon className="ss" />
            </span>
            <h2> Newly suggested consultants are highlighted in green</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <EditIcon className="ss" />
            </span>
            <h2>You can click and check the complete profile</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <MoreVert className="ss" />
            </span>
            <h2>Or click the three dots and take the action directly</h2>
          </div>
        </div>
      ),
    },

    {
      selector: ".second-step",
      content: (
        <div>
          <div className="grid-2-q">
            <span className="icon-q">
              <CheckCircleOutlinedIcon className="ss" style={{ color: "green" }} />
            </span>
            <h2> Approve this profile</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <IMG title="Decide later" src={Retained} alt={Retained} />
            </span>
            <h2>Keep this profile & decide later</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <RemoveCircleOutlineIcon className="ss" style={{ color: "red" }} />
            </span>
            <h2>Reject this profile</h2>
          </div>
          <div className="ul">
            <div>
              <button
                onClick={() => {
                  setStepperCloseState(true);
                  finishQuochmark({ user_id: authUser()?.user_id, request_for: "suggested_consultants" });
                }}
              >
                Finish{" "}
              </button>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const TalentSteps = [
    {
      selector: ".first-step",
      content: (
        <div>
          <div className="grid-2-q">
            <span className="icon-q">
              <ShareOutlinedIcon className="ss" />
            </span>
            <h2> Share this consultant profile with the client</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <EditIcon className="ss" />
            </span>
            <h2>Edit this consultant profile</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <Delete className="ss" />
            </span>
            <h2>Delete this consultant profile</h2>
          </div>
          <div className="ul">
            <div>
              <button
                onClick={() => {
                  finishQuochmark({ user_id: authUser().user_id, request_for: "talents" });
                  setStepperCloseState(true);
                  window.location.reload();
                }}
              >
                Finish{" "}
              </button>
            </div>
          </div>
        </div>
      ),
    },

    // Suggested Consultant
    // fourth-step
    // ...
  ];
  const ConsultantDetails = [
    {
      selector: ".second-step",
      content: (
        <div>
          <div>
            <h2>
              Clicking the share button will create a copy of the selected consultant profile. These fields will be
              editable and can be change according to the client's requirements.
            </h2>
          </div>
        </div>
      ),
    },
    {
      selector: ".third-step",
      content: (
        <div>
          <div className="grid-2-q">
            <span className="icon-q">
              <VisibilityIcon className="ss" />
            </span>
            <h2> Already uploaded document can be viewed</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <FileUploadIcon className="ss" />
            </span>
            <h2>Older document can be replaced by uploading new document</h2>
          </div>
          <div className="grid-2-q">
            <span className="icon-q">
              <DownloadIcon className="ss" />
            </span>
            <h2>Uploaded document can be downloaded</h2>
          </div>

          <div className="grid-2-q">
            <span className="icon-q">
              <DeleteIcon className="ss" />
            </span>
            <h2>Document can be deleted</h2>
          </div>
        </div>
      ),
    },
    {
      selector: ".fourth-step",
      content: (
        <div>
          <div className="grid-2-q">
            <span className="icon-q">
              <SaveIcon className="ss" />
            </span>
            <h2>Name this file before sharing</h2>
          </div>
          <div className="ul">
            <div>
              {/* <button>Save & share </button> */}
              <button
                onClick={() => {
                  finishQuochmark({ user_id: authUser().user_id, request_for: "share_consultant" });
                  setStepperCloseState(true);
                }}
              >
                Finish{" "}
              </button>
            </div>
          </div>
        </div>
      ),
    },
  ];
  // const user_tyep_id = localStorage.getItem("user_type_id");
  const history = useHistory();
  const [step, setStep] = useState(0);
  const stepValue = () => {
    let envFile = rest.location.pathname.toString();
    let envFileSplit = envFile.split("/talent-management");
    if (envFileSplit[1] == "") return TalentSteps;
    if (rest.location.pathname.includes("/suggested-consultants")) return SuggestedClientSteps;
    if (envFileSplit[1]) return ConsultantDetails;
  };

  return (
    <TourProvider
      steps={stepValue}
      showBadge={false}
      currentStep={step}
      styles={{ popover: (base) => ({ ...base, borderRadius: "8px", padding: "8px", paddingTop: "24px" }) }}
    >
      <Route
        {...rest}
        render={(props) => {
          if (isLogin()) {
            return access_level.includes(authUser()?.user_type_id) ? (
              <>
                <Header />
                {props.location.pathname === "/" ? (
                  <Redirect
                    to={{
                      pathname: "/projects",
                      // user_tyep_id == 3 ? "/projects" : user_tyep_id == 2 ? "/consultant-profile" : "/client-profile",
                    }}
                  />
                ) : (
                  <Component step={props} {...props} />
                )}
              </>
            ) : (
              <>
                <Header />
                <Unauthorised isHome={props.location.pathname === "/"} />;
              </>
            );
          } else {
            return <Redirect to={{ pathname: "/admin-login", state: { from: props?.location } }} />;
          }
        }}
      />
    </TourProvider>
  );
};

export default AuthenticatedRoute;
