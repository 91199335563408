import Axios from "axios";
import Cookies from "js-cookie";

const API = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

API.interceptors.request.use(
  (request) => {
    const token = Cookies.get("token");

    if (token) {
      request.headers["Authorization"] = "Bearer " + token;
      request.headers["namespace"] = "STRATEGY";
      request.headers["x-fwd-authorization"] = "test";
      request.headers["user-id"] = Cookies.get("user_user_id");
    } else {
      request.headers["namespace"] = "STRATEGY";
      request.headers["x-fwd-authorization"] = "test";
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default API;
