import React from "react";
import Styles from "Common/Header/Styles";
import BrandLogo from "Assets/Images/SCLogo.svg";
import { IMG, Box, Link, Typography } from "Assets/Material";
import { NavLink as RouterLink } from "react-router-dom";

const Brand = () => {
  return (
    <Link to="/" component={RouterLink} sx={Styles.brandLink}>
      <Box sx={Styles.brandWrapper}>
        <IMG src={BrandLogo} alt={BrandLogo} sx={Styles.brandLogo} />
        <Typography sx={Styles.brandName}> StrategyConnect </Typography>
      </Box>
    </Link>
  );
}

export default Brand;