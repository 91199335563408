import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { TableStyles, ClientsStyles as Styles } from "Assets/Styles";
import { H1, Grid, Button, H3 } from 'Assets/Material';
import { authUser, usertype } from "Utils";
import {
    Loader,
    NoData,
    Pagination,
    DesktopTableDataPopup,
    ActionMenu,
    DesktopTableDataPopupCollapsable,
    ContactAction,
    Email,
  } from "Common";
  import {
    Box,
    Table,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    useMediaQuery,
    useTheme,
    TableContent,
    IconButton,
  } from "Assets/Material";
import { getConsultantInvoiceListService } from 'Services/InvoiceService';
import { ClearIcon, DownloadIcon, HelpOutlineIcon, LaunchIcon, VisibilityIcon } from 'Assets/Icons';
import MarkPaidForm from './MarkPaidForm';
import { FetchSignURLService } from 'Services/FileDownloadService';
export default function ConsultantInvoices(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    

    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [limit, setLimit] = useState(5);
    const [total, setTotal] = useState(0);

    const [invoiceList, setInvoiceList] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const [selectedInvoiceToMarkPaid, selectInvoiceToMarkPaid] = useState(null);
    const [showBillingInfo, setShowBillingInfo] = useState(false);
    
    const fetchInvoices = useCallback(
        async () => {
            let response = await getConsultantInvoiceListService(props.user_id, from, limit);
            if(response.status === 200 && Array.isArray(response.list)){
                
                setInvoiceList(response.list);
                setTotal(response.count);
                setTo(from+response.list.length);
            }
            setLoading(false);
            
        }
    )

    useEffect(() => {
        fetchInvoices();
    }, [from]);

    return (
        <div>
            <Dialog 
                fullWidth
                maxWidth={'md'}
                open={true} 
                onClose={() => props.close()}>
                {/* <DialogTitle>Raise Invoice</DialogTitle> */}
                    
                    {isLoading && <Loader />}

                    <Box sx={TableStyles.tableWrapper}>
                        <Grid container>
                            <Grid item sm>
                                <H3 sx={{fontSize: '1.6rem', padding: '0 0 1rem 0'}}>{props.consultant.first_name} {props.consultant.last_name} </H3>
                            </Grid>
                            <Grid item sm={6}>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end'}}>
                                    <ContactAction onEmailOpen={() => props.onEmailToggle()} data={props.consultant} />
                                    <IconButton>
                                        <HelpOutlineIcon 
                                            onClick={() => setShowBillingInfo(true)}
                                            sx={TableStyles.buttonIcon}  />
                                    </IconButton>
                                </div>
                                
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={TableStyles.tableContainer}>
                        <Table sx={TableStyles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={TableStyles.tableCell}> Invoice Number </TableCell>
                                    <TableCell sx={TableStyles.tableCell}> Invoice Date </TableCell>
                                    <TableCell sx={TableStyles.tableCell}> Invoice Amount </TableCell>
                                    <TableCell sx={TableStyles.tableCell}> Status </TableCell>
                                    <TableCell sx={TableStyles.tableCell}> Actions </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {invoiceList &&
                                invoiceList.map((invoice, key) => {
                                return (
                                    <TableRow key={key} sx={TableStyles.tableRow}>
                                        <TableCell
                                            sx={
                                            xs || sm
                                                ? { ...TableStyles.tabledata, ...TableStyles.mobilelink }
                                                : { ...TableStyles.tabledata, ...TableStyles.link }
                                            }
                                        >
                                            <TableContent sx={TableStyles.link}>
                                                <a onClick={async () => {
                                                            let signed_url_resp = await FetchSignURLService(invoice.invoice_file_id);
                                                            if(signed_url_resp.data.status === 200){
                                                                window.open(signed_url_resp.data.signed_url, "_blank");
                                                            } else {
                                                                alert('Something went wrong while fetching secure resource url');
                                                            }
                                                        }}>{invoice.invoice_number}</a>
                                            </TableContent>
                                        </TableCell>

                                        <TableCell sx={TableStyles.tabledata}>
                                            <TableContent>{invoice.invoice_date}</TableContent>
                                        </TableCell>

                                        <TableCell sx={TableStyles.tabledata}>
                                            <TableContent>${invoice.final_payable}</TableContent>
                                        </TableCell>
                                        <TableCell sx={TableStyles.tabledata}>
                                            <TableContent>{invoice.status === 1 ? 'Pending' : 'Paid'}</TableContent>
                                        </TableCell>
                                        <TableCell sx={TableStyles.tabledata}>
                                            <TableContent>
                                                {/* <IconButton >
                                                    <VisibilityIcon 
                                                        // sx={TableStyles.buttonIcon} 
                                                        onClick={async () => {
                                                            let signed_url_resp = await FetchSignURLService(invoice.invoice_file_id);
                                                            if(signed_url_resp.data.status === 200){
                                                                window.open(signed_url_resp.data.signed_url, "_blank");
                                                            } else {
                                                                alert('Something went wrong while fetching secure resource url');
                                                            }
                                                        }} />
                                                </IconButton> */}
                                                
                                                {
                                                    invoice.status === 1
                                                    ?   <Button 
                                                            variant="text"
                                                            // sx={TableStyles.buttonIcon} 
                                                            onClick={() => selectInvoiceToMarkPaid(invoice)} >Mark Paid</Button>
                                                    :   <Button 
                                                            variant="text"
                                                            onClick={async () => {
                                                                let signed_url_resp = await FetchSignURLService(invoice.payment_file_id);
                                                                if(signed_url_resp.data.status === 200){
                                                                    window.open(signed_url_resp.data.signed_url, "_blank");
                                                                } else {
                                                                    alert('Something went wrong while fetching secure resource url');
                                                                }
                                                            }} >View Proof</Button>
                                                }
                                                
                                            </TableContent>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        {
                            total
                            ?   (
                                <Pagination
                                    perPage={limit}
                                    from={from+1}
                                    to={to}
                                    totalDataItem={total}
                                    previous={() => setFrom(from-limit)}
                                    next={() => setFrom(from+limit)} /> 
                            )  
                            :   ''
                            
                            
                        }

                        {invoiceList && invoiceList.length === 0 && <NoData message="No Invoices sumbmitted yet" />}
                        </TableContainer>
                    </Box>
            </Dialog>
            {
                showBillingInfo
                ?   (
                    <Dialog 
                        fullWidth
                        maxWidth={'md'}
                        open={true} 
                        onClose={() => setShowBillingInfo(false)}>
                        <Box sx={{padding: '4rem'}}>
                            <Grid container>
                                <Grid item sm={6}>
                                    <H3 sx={{fontSize: '1.2rem', fontWeight: 800}}>Billing Address</H3>
                                    <p style={{whiteSpace: 'pre-wrap'}}>
                                        {props.consultant.billing_address}
                                    </p>
                                </Grid>
                                <Grid item sm={6}>
                                    <H3 sx={{fontSize: '1.2rem', fontWeight: 800}}>Account Details</H3>
                                    <p style={{whiteSpace: 'pre-wrap'}}>
                                        {props.consultant.bank_details}
                                    </p>
                                    
                                </Grid>
                            </Grid>
                        </Box>
                    </Dialog>
                )
                :   ''
            }
            {
                selectedInvoiceToMarkPaid !== null 
                ?   <MarkPaidForm 
                        invoice={selectedInvoiceToMarkPaid} 
                        handleClose={(refreshList) => {
                            fetchInvoices();
                            selectInvoiceToMarkPaid(null);
                        }} />
                :   ''
            }
            
        </div>
    );
}
