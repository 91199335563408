import { chatWindows } from "Atom";
import { authUser } from "Utils";
import { useRecoilState } from "recoil";
import ChatBox from "./components/ChatBox";
import AdminChatHeader from "./components/AdminChatHeader";
import AdminChatInfo from "./components/AdminChatInfo";
import { Box } from "@mui/material";
import { useState } from "react";

const ChatWindows = () => {

    const loggedInUser = authUser();
    const [conversationIds, setConversationIds] = useRecoilState(chatWindows);
    const [ windowStateMap, updateWindowStateMap] = useState({});

    const closeWindow = (conversationId) => {
        let _list = JSON.parse(JSON.stringify(conversationIds));
        let index = _list.indexOf(conversationId);
        if(index !== -1){
            _list.splice(index, 1);
            setConversationIds(_list);
        }
    }

    const toggleChatWindow = (convId) => {
        const key = `${convId}`;
        let windowState = windowStateMap[key] || {};
        windowState.minimized = !windowState.minimized;
        updateWindowStateMap(Object.assign({}, windowStateMap, {[key]: windowState }));
    }

    if(conversationIds.length === 0){
        return <></>
    }


    const WIDTH_PX = 400;
    const commonStyle = { 
        position: 'fixed', 
        bottom: 0, 
        zIndex: 100000, 
        width: `${WIDTH_PX}px`, 
        height: '500px', 
        background: '#FFF'
    };

    return (
        <>
        {conversationIds.map((convId, k) => {
            const isMinimized = windowStateMap[`${convId}`] && windowStateMap[`${convId}`].minimized;
            return (
                <Box key={convId} sx={[commonStyle, { right: `${(k * WIDTH_PX) + 2}px` }, isMinimized ? { height: 'auto' } : {}]}>
                    <ChatBox
                        isMinimized={isMinimized}
                        conversationId={convId}
                        user_id={loggedInUser.user_id}
                        user_type_id={loggedInUser.user_type_id}
                        viewComponents={{
                            header: (props) => (
                                <AdminChatHeader 
                                    {...props} 
                                    isMinimized={isMinimized}
                                    toggle={() => toggleChatWindow(convId)} 
                                    close={() => closeWindow(convId)} />
                            ),
                            info: AdminChatInfo
                        }} />
                </Box>
            )
        })}
        </>
    )
}

export default ChatWindows;