const Styles = {
  progressWrapper: {
    background: "#fff",
    padding: " 0.2rem",
    border: " 1px solid #444",
    borderRadius: " 50px",
    height: " 1.8rem",
    width: "100%",
  },
  progress: {
    border: "none",
    padding: "0.1rem 0.4rem",
    borderRadius: " 50px",
    backgroundColor: " #778dfd",
    color: "#fff",
    height: "1.3rem",
    fontSize: "0.8rem",
    minWidth: "14%",
  },
  MsTeam: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  MsTeamDisable: {
    pointerEvent: "none",
    "&:hover": {
      cursor: "auto",
    },
  },
  swapperProjectTitle: {
    fontWeight: "700 !important",
    marginBottom: "1rem",
    color: "#607ebb !important",
    cursor: "pointer",
  },
  projectHeadWidth: {
    width: "20%",
  },
  projectRowHighlight: {
    background: "#D5FFDF",
    borderLeft: "5px solid #21BA45",
  },
};

export default Styles;
