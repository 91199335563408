const AdminProfileHeaderStyles = {
  adminProfileHeaderWrapper: {
    margin: "2rem 0",
    padding: "2rem 0",
    boxShadow: "2px 2px 4px #00000029",
  },
  backArrow: {
    paddingLeft: "2rem",
    cursor: "pointer",
  },
  profileImageWrapper: {
    width: "4rem",
    height: "4rem",
    margin: "0 auto",
    position: "relative",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "block",
    marginLeft: [{ lg: "0" }],
  },
  adminName: {
    color: (theme) => theme.palette.text_heading.main,
    margin: "0",
    padding: "0",
    fontSize: "1.2rem",
    textTransform: "capitalize",
  },
  adminNameEditIcon: {
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
  },
  adminNameSubmitIcon: {
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  editAdminNameError: {
    textTransform: "initial",
    fontWeight: "normal",
    margin: "0.5rem 0 0 0",
    fontSize: "0.8rem",
    color: (theme) => theme.palette.error.main,
  },
  adminNameCancelIcon: {
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  adminNameEditInput: {
    width: "50%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #4444",
    fontSize: "1rem",
    boxShadow: "2px 2px 4px #00000029",
    "&:focus": {
      outline: "none",
    },
  },
  adminNameUpdateLoader: {
    width: "15px !important",
    height: "15px !important",
    margin: "0 0 -0.5rem 1rem",
  },
  profileImageEditIcon: {
    position: "absolute",
    bottom: "-0.2rem",
    right: "-0.2rem",
    border: "1px solid",
    borderRadius: "50px",
    padding: "2px",
    fontSize: "1.5rem",
    backgroundColor: "#fff",
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
};

export default AdminProfileHeaderStyles;
