const ProjectDetailsStylesCommunicationChannel = {
  communicationChannelWrapper: {
    maxHeight: "10rem",
    overflow: "auto",
    width: "100%",
    marginTop: "1rem",
  },
  disabled: {
    color: (theme) => theme.palette.disable,
    cursor: "default",
  },
  communicationChannelInputContainer: {},
  communicationChannelInputWrapper: {
    width: "20rem",
  },
  communicationIconsWrapper: {
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  communicationIcons: {
    color: (theme) => theme.palette.primary.main,
    width: "2rem",
    margin: "auto",
  },
  actionButtons: {
    flexDirection: "row-reverse",
    display: "flex",
    alignItems: "center",
  },
  communicationChannelListItemWrapper: {
    display: "flex",
    alignItems: "center",
  },
  listItemImage: {
    marginRight: "1rem",
  },
  communicationURL: {
    position: "relative",
    top: "-8px",
    left: "6px",
    color: (theme) => theme.palette.primary.main,
    fontWeight: "500",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  communicationURLWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  communicationDeleteIcon: {
    float: "right",
    cursor: "pointer",
    margin: "0 1rem",
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
};

export default ProjectDetailsStylesCommunicationChannel;
