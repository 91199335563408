const AdminProfilePhotoViewerStyles = {
  photoCaptureWrapper: {
    padding: "1rem",
    overflowY: "auto",
    position: "absolute",
    bgcolor: "background.paper",
    top: ["10vh", "10vh", "40%", "40%", "40%"],
    left: ["10%", "10%", "50%", "50%", "50%"],
    width: ["80%", "80%", "50%", "50%", "50%"],
    height: ["auto", "auto", "auto", "auto", "auto"],
    transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"],
  },
  closeIcon: {
    float: "right",
    cursor: "pointer",
    "&:hover": { color: "red" }
  },
  camera: {
    width: ["10%", "95%", "95%", "95%", "95%"],
    maxWidth: ["10%", "95%", "95%", "95%", "95%"],
    float: "left"
  },
  preview: {
    width: ["100%", "95%", "95%", "95%", "95%"],
    float: "right"
  },
  previewText: {
    marginTop: "1rem",
    marginLeft: ["0", "5%", "5%", "5%", "5%"],
  },
  captureButton: {
    marginTop: "1rem"
  },
  uploadButton: {
    marginTop: "1rem",
    marginLeft: "1rem !important"
  },
}

export default AdminProfilePhotoViewerStyles;