import React, { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { AuthStyles as Styles } from "Assets/Styles";
import { NavLink as RouterLink } from "react-router-dom";
import { ResetPasswordService } from "Services/AuthService";
import { VpnKeyIcon, PasswordIcon, VisibilityIcon, VisibilityOffIcon, SCLogo } from "Assets/Icons";
import {
  Box,
  P,
  H1,
  TextField,
  CircularProgress,
  Button,
  InputAdornment,
  IconButton,
  Link,
  IMG,
} from "Assets/Material";
import { Email } from "@mui/icons-material";
import API from "Services";
import { RESER_PASSWORD_PROFILE } from "Utils/ApiConstants";
import ConsultantResponsePopup from "Modules/ConsultantProfile/Component/ConsultantResponsePopup";
import CustomAlert from "Modules/ProjectLeads/component/ProjectLeadsDetails/CustomAlert";

const ResetProfilePassword = (props) => {
  const history = useHistory();
  const [setPasswordError, setSetPasswordError] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");


  const onSetPassword = async (values) => {
    const response = await API.post(RESER_PASSWORD_PROFILE, {
        user_email: values.email,
        old_password: values.old_password,
        password: values.password,
        password_again: values.password_again
    });

    if (response.status === 200) {
        setOpenAlert(true);
        setMessage("Password successfully changed");
    }

  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Enter the new email sent via email.";
      setSetPasswordError("");
    }
    if (!values.old_password) {
        errors.old_password = "Enter your old password.";
        setSetPasswordError("");
    }
    if (!values.password) {
      errors.password = "New password is required.";
      setSetPasswordError("");
    }
    if (!values.password_again) {
      errors.password_again = "Confirm password is required.";
      setSetPasswordError("");
    } else if (values.password_again && values.password !== values.password_again) {
      errors.password_again = "Password and confirm password should match.";
      setSetPasswordError("");
    }
    return errors;
  };

  return (
    <>
      {openAlert && <CustomAlert isOpen={openAlert} setIsOpen={setOpenAlert} message={message} />}
      <Box sx={{ ...Styles.authWrapper, marginTop: "15%" }}>
        <IMG src={SCLogo} alt={SCLogo} sx={Styles.scLogo} />
        <H1 sx={Styles.authTitle}> Set New Password </H1>
        <Formik
          initialValues={{
            email: "",
            old_password: "",
            password: "",
            password_again: "",
          }}
          validate={(values) => validateForm(values)}
          onSubmit={(values) => onSetPassword(values)}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <P sx={Styles.formError}> {setPasswordError} </P>
              <TextField
                fullWidth
                name="email"
                type="text"
                size="small"
                margin="dense"
                variant="outlined"
                placeholder="New Email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={Styles.inputField}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <Email />{" "}
                    </InputAdornment>
                  ),
                }}
                error={errors.email && touched.email && errors.email}
                helperText={errors.email && touched.email && errors.email}
              />
              <TextField
                fullWidth
                name="old_password"
                type={!isShowNewPassword ? "password" : "text"}
                size="small"
                margin="dense"
                variant="outlined"
                placeholder="Old Password"
                value={values.old_password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={Styles.inputField}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <PasswordIcon />{" "}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsShowNewPassword((prev) => !prev)}
                        edge="end"
                      >
                        {isShowNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.old_password && touched.old_password && errors.old_password}
                helperText={errors.old_password && touched.old_password && errors.old_password}
              />

                <TextField
                fullWidth
                name="password"
                type={!isShowNewPassword ? "password" : "text"}
                size="small"
                margin="dense"
                variant="outlined"
                placeholder="New Password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={Styles.inputField}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <PasswordIcon />{" "}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsShowNewPassword((prev) => !prev)}
                        edge="end"
                      >
                        {isShowNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.password && touched.password && errors.password}
                helperText={errors.password && touched.password && errors.password}
              />


              <TextField
                fullWidth
                name="password_again"
                type={!isShowConfirmPassword ? "password" : "text"}
                size="small"
                margin="dense"
                variant="outlined"
                placeholder="Confirm Password"
                value={values.password_again}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={Styles.inputField}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <PasswordIcon />{" "}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsShowConfirmPassword((prev) => !prev)}
                        edge="end"
                      >
                        {isShowConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.password_again && touched.password_again && errors.password_again}
                helperText={errors.password_again && touched.password_again && errors.password_again}
              />

              {/* <Link component={RouterLink} to="/login" sx={Styles.link}> Login</Link> */}

              <Button type="submit" disabled={isFormSubmit} sx={Styles.authButton}>
                {isFormSubmit ? <CircularProgress sx={Styles.loader} /> : "Set New Password"}
              </Button>
            </form>
          )}
        </Formik>
        <P>
          {/* <Link to="/register" component={RouterLink} sx={Styles.linkCreateAccount}>
            {" "}
            Create an Account{" "}
          </Link> */}
        </P>
      </Box>
    </>
  );
};

export default ResetProfilePassword;
