import React, { useState } from "react";
// import { TextField, AdapterDateFns, LocalizationProvider, MobileDatePicker } from "Assets/Material";
import { TextField } from "Assets/Material";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import utc from 'dayjs/plugin/utc';

//Set utc timezone for date only.
dayjs.extend(utc);

const DateInput = (props) => {
  const [dateVal, setDateVal] = useState(dayjs.utc(props?.initialValues));
  // console.log("dateVal :: ", dateVal);
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          InputProps={{ readOnly: true }}
          inputFormat="dd/MM/yyyy"
          label={props?.label}
          value={dateVal}
          size="small"
          disablePast={props.disablePast}
          onChange={(newValue) => {
            setDateVal(newValue);
            props?.getDate(newValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                size="small"
                margin="dense"
                fullWidth
                sx={props?.sx}
                helperText={params?.error ? "Invalid date format" : ""}
              />
            );
          }}
        />
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default DateInput;
