import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";

import { Box, Grid } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckBoxIcon } from "Assets/Icons";
import { SPAN, Button } from "Assets/Material";
import { ThemeStyles } from "Assets/Styles";
import RequirementOverview from "Modules/ProjectLeads/component/ProjectLeadsDetails/RequirementOverview";
import React, { useState, useEffect } from "react";
import { useRecoilState, toasterGlobal } from "Atom";
import { DateInput, Loader } from "Common";
import { saveConsultantResponse } from "Services/ProjectLeadsServices";

const ConsultantResponsePopup = (props) => {

    const leadData = props.leadData;

    const RESPONSE_YES = 'Yes';
    const RESPONSE_NO = 'No';

    const [ response, setResponse ] = useState('');
    const [ availableFrom, setAvailableFrom ] = useState(new Date());
    const [ availableTill, setAvailableTill ] = useState(null);

    const [submiting, setSubmitting] = useState(false);
    

    const setToaster = useRecoilState(toasterGlobal)[1];

    const submitResponse = async () => {
        const data = {
            response, 
            lead_id: leadData.lead_id,
            consultant_id: props.consultant_id,
            requirement_id: props.requirement_id,
            available_from: availableFrom, 
            available_till: availableTill
        }
        // console.log(data);
        if(response === RESPONSE_YES && availableFrom === null){
            setToaster({ isOpen: true, status: "fail", message: "Plase provide the date from from which you are available from!" });
            return;
        }
        if(availableFrom && availableTill){
            if(availableFrom.getTime() > availableTill.getTime()){
                // alert('Available till date should not be less than available from date');
                setToaster({ isOpen: true, status: "fail", message: "Available till date should not be less than available from date!" });
                return;
            }
        }

        
        // return;
        setSubmitting(true);
        let resp = await saveConsultantResponse(data);
        // console.log(resp);
        setSubmitting(false);
        
        if(resp.status === 200){
            setToaster({ isOpen: true, status: "success", message: resp.message });
            props.onClose();
        } else {
            setToaster({ isOpen: true, status: "fail", message: "Something went wrong!" });
        }
    }

    
    return (
        <Dialog fullWidth
            maxWidth={'sm'}
            open={true}
            onClose={() => props.onClose()} >
            <Box sx={ThemeStyles.dialogWrapper}>
                <DialogTitle sx={ThemeStyles.dialogHeading}>New Project Opportunity</DialogTitle>
                <DialogContent>
                    <RequirementOverview
                        leadData={leadData}
                        requirement_id={props.requirement_id}
                        consultantRequirement={leadData.consultant_requirements.find(r => r.id == props.requirement_id)} />
                    <Box sx={ThemeStyles.paddedBox}>
                        <strong>Are you interested?</strong>
                        <Grid container>
                            {[RESPONSE_YES, RESPONSE_NO].map((resp, k) => {
                                const CB = resp === response ? CheckBoxOutlined : CheckBoxOutlineBlank;
                                return (
                                    <Grid 
                                        display={'flex'} 
                                        alignItems="center" 
                                        justifyContent={'center'} 
                                        alignContent={'center'} 
                                        paddingRight={'2rem'}
                                        paddingTop={'0.5rem'}>
                                        <CB onClick={() => setResponse(resp)} /> <SPAN>{resp}</SPAN>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    {
                        response === RESPONSE_YES
                            ? (
                                <Box sx={ThemeStyles.paddedBox}>
                                    <strong>When are you available?</strong>
                                    <Box>
                                        <Grid container>
                                            <Grid
                                                display={'flex'}
                                                alignItems="center"
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                // paddingRight={'2rem'}
                                                paddingTop={'0.5rem'}>
                                                <DateInput
                                                    label="Available From"
                                                    initialValues={availableFrom}
                                                    disablePast={true}
                                                    getDate={(date) => setAvailableFrom(new Date(date))} />
                                            </Grid>
                                            <Grid
                                                display={'flex'}
                                                alignItems="center"
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                // paddingRight={'2rem'}
                                                paddingTop={'0.5rem'}>
                                                <DateInput
                                                    label="Available Till (optional)"
                                                    initialValues={availableTill}
                                                    disablePast={true}
                                                    getDate={(date) => setAvailableTill(new Date(date))} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            ) : ''
                    }
                    {submiting ? <Loader /> : ''}
                </DialogContent>
                <DialogActions>
                    <Box sx={ThemeStyles.paddedBox}>
                        <Button onClick={() => props.onClose()} variant={'text'}>Cancel</Button> &nbsp; &nbsp;
                        <Button 
                            onClick={submitResponse} 
                            variant={'radius'} 
                            disabled={submiting || response === ''}>
                            Submit
                        </Button>
                    </Box>
                </DialogActions>
            </Box>
            
            
        </Dialog>
    )
}

export default ConsultantResponsePopup;