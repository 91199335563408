const ProjectDetailsAttachmentDocumentsStyles = {
  attachmentDownloadWrapper: {
    position: "relative",
    display: "flex",
    paddingRight: ["2rem", "3rem"],
    marginBottom: "2rem"
  },
  attachmentDownload: {
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    border: "2px solid",
    borderRadius: "50px",
    padding: "1px",
    width: "1.4rem",
    height: "1.4rem",
    transition: "0.2s",
    cursor: "pointer",
    position: "absolute",
    right: ["0", "2rem", "2rem", "0rem", "0rem"]
  },
  fileName: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitLineClamp: 1,
    webkitBoxOrient: 'vertical',
  },

  attachmentDownloadBorder: {
    borderBottom: "1px solid #4444",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
  },
  attachmentDownloadContainer: {
    maxHeight: "10rem",
    overflow: "auto",
    width: "100%",
    paddingRight: "0.5rem",
    marginTop: "1rem"
  },
  collapsibleButton: {
    float: "right",
    fontSize: "2rem",
    color: (theme) => theme.palette.primary.main
  }
}

export default ProjectDetailsAttachmentDocumentsStyles;