import React, { useState } from "react";
import { Formik } from "formik";
import { emailRegex, alphabatesRgex, authUser } from "Utils";
import { isValidPhoneNumber } from "libphonenumber-js";
import { getCityService } from "Services/AdminProfileServices";
import { TalentManagementStyles as Styles } from "Assets/Styles";
import { addNewFunctionalCapabilityService } from "Services/ApprovalPanel";
import { saveLinkedinUrlService, useAddConsultantService } from "Services/TalentManagementService";
import { TextInput, Form, FormButton, SelectCountryCode, CreatableAutocomplete, CityAutocomplete } from "Common";
import {
  Grid,
  Select,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  Autocomplete,
} from "Assets/Material";
import { useRecoilState, toasterGlobal } from "Atom";

const AddTalentManagement = (props) => {
  const setToaster = useRecoilState(toasterGlobal)[1];

  const [cityList, setCityList] = useState([]);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [countryCodeValueError, setCountryCodeValueError] = useState(false);
  const onSuccesss = (data) => {};

  const onError = (error) => {
    console.log({ error });
  };

  const { mutate } = useAddConsultantService(onSuccesss, onError);

  const validation = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = "First Name is required.";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required.";
    }

    if (!values.email) {
      errors.email = "Email is required.";
    } else if (values.email && !emailRegex.test(values.email)) {
      errors.email = "Invalid email address.";
    }
    /* Author : Tarsem Jangra
      ****** Remove validation of phone number */
     
    // if (!values.country_code && values.mobileNo) {
    //   setCountryCodeValueError(true);
    // } else {
    //   setCountryCodeValueError(false);
    // }
    // if (values.country_code && !values.mobileNo) {
    //   errors.mobileNo = "Contact number is required.";
    // }
    if (values.mobileNo && isNaN(values.mobileNo)) {
      errors.mobileNo = "Only number is allowed.";
    }
    if (values.country_id && values.country_code && values.mobileNo) {
      let isValidPhone = isValidPhoneNumber(values.mobileNo, values.country_id);
      if (values.mobileNo && isNaN(values.mobileNo)) {
        errors.mobileNo = "Only number is allowed.";
      } else if (!isValidPhone) {
        errors.mobileNo = "Invalid contact number format.";
        setCountryCodeValueError(false);
      } else {
        setCountryCodeValueError(false);
      }
    } else {
      setCountryCodeValueError(false);
    }
    if (!values.total_work_experience_years) {
      errors.total_work_experience_years = "Experience is required.";
    } else if (values.total_work_experience_years && isNaN(values.total_work_experience_years)) {
      errors.total_work_experience_years = "Only number is allowed.";
    }
    if (!values.remote_per_day_rate) {
      errors.remote_per_day_rate = "Charges are required.";
    } else if (values.remote_per_day_rate && isNaN(values.remote_per_day_rate)) {
      errors.remote_per_day_rate = "Only number is allowed.";
    }
    if (values.onsite_per_day_rate && isNaN(values.onsite_per_day_rate)) {
      errors.onsite_per_day_rate = "Only number is allowed.";
    }
    if (!values.current_employer) {
      errors.current_employer = "Current organization is required.";
    }
    if (!values.current_role) {
      errors.current_role = "Current designation is required.";
    }

    return errors;
  };

  const onAddConsultant = async (values) => {
    values.onsite_per_day_rate = parseInt(values.onsite_per_day_rate);
    values.remote_per_day_rate = parseInt(values.remote_per_day_rate);
    values.total_work_experience_years = parseInt(values.total_work_experience_years);

    //set registration type admin
    values.registration_type = 'admin';

    // customData = {values.onsite_per_day_rate = ...values}
    setIsFormSubmit(true);

    mutate(values, {
      onSuccess: async (data) => {
        if (data && data.status === 200) {
          const unapprovedSkillsParams = {
            request_for: "consultant",
            consultant_id: data.consultant_id,
            user_platform_id: authUser().user_id,
            createdby_userid: authUser()?.user_id,
            unapproved_capabilities: values.unapproved_skills,
          };
          // if (values.linkedin_url) await saveLinkedinUrlService({ urls: values.linkedin_url });
          if (values.unapproved_skills.length > 0) await addNewFunctionalCapabilityService(unapprovedSkillsParams);
          onCloseAddConsultant(true, data.message);
        } else {
          onCloseAddConsultant(false, data.message);
        }
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const onCloseAddConsultant = (isSuccess, message) => {
    props?.onClose();
    setIsFormSubmit(false);
    setToaster({ isOpen: true, status: isSuccess ? "success" : "fail", message: message });
  };

  const getCities = async (country_code) => {
    const cityRes = await getCityService(country_code);
    if (cityRes && cityRes.data && cityRes.data) {
      let cities = cityRes.data.map((i) => `${i.city_ascii}, ${i.country}`);
      setCityList(cities);
    }
  };

  return (
    <div>
      <Form onOpen={props?.onOpen} onClose={props?.onClose} title="Add Consultant">
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            linkedin_url: "",
            email: "",
            country_code: "",
            country_id: "",
            mobileNo: "",
            current_city: "",
            commitment_preference_id: 3,
            capabilities: [],
            unapproved_skills: [],
            total_work_experience_years: null,
            working_remotely: 1,
            onsite_per_day_rate: null,
            remote_per_day_rate: null,
            remote_rate_unit: 'hour',
            onsite_rate_unit: 'hour',
            current_employer: "",
            current_role: "",
            user_platform_id: 1,
            createdby_userid: authUser()?.user_id,
            user_type_id: 2,
          }}
          validate={(values) => validation(values)}
          onSubmit={(values) => onAddConsultant(values)}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="First Name"
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    onError={errors.first_name && touched.first_name && errors.first_name}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="Last Name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.last_name && touched.last_name && errors.last_name}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="LinkedIn URL"
                    name="linkedin_url"
                    value={values.linkedin_url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.linkedin_url && touched.linkedin_url && errors.linkedin_url}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.email && touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <SelectCountryCode
                    initialValue={values.country_code}
                    isRequired={countryCodeValueError}
                    getValue={(country) => {
                      values.country_code = `+${country.phone}`;
                      values.country_id = country.code;
                      getCities(country.code);
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextInput
                    label="Phone Number"
                    name="mobileNo"
                    value={values.mobileNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.mobileNo && touched.mobileNo && errors.mobileNo}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CityAutocomplete 
                   initialValues={values.location}
                   label="Location"
                   placeholder="Search cities"
                   getValue={(value) => {
                    values.current_city = value
                   }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth component="fieldset" sx={Styles.engagementPreferenceWrapper}>
                    <FormLabel sx={Styles.engagementPreferenceFormLabel}>Engagement Preference</FormLabel>
                    <RadioGroup
                      row
                      fullWidth
                      style={Styles.radioButtonWrapper}
                      name="commitment_preference_id"
                      value={values.commitment_preference_id}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={1}
                        label="Full Time"
                        control={<Radio size="small" />}
                        sx={Styles.radioButtonLabel}
                      />
                      <FormControlLabel
                        value={2}
                        label="Part Time"
                        control={<Radio size="small" />}
                        sx={Styles.radioButtonLabel}
                      />
                      <FormControlLabel
                        value={3}
                        label="Both"
                        control={<Radio size="small" />}
                        sx={Styles.radioButtonLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} sx={Styles.skills}>
                  <CreatableAutocomplete
                    isLabel={true}
                    // initialValues={
                    //   props?.editCaseStudyData?.skillset
                    //   && JSON.parse(props?.editCaseStudyData?.skillset)
                    //   && validateNull("skill", JSON.parse(props?.editCaseStudyData?.skillset))
                    // }
                    getValue={(value) => {
                      values.capabilities = value.capabilities;
                      values.unapproved_skills = value.unapproved_skills;
                    }}
                  />
                </Grid>

                

                <Grid item xs={6} sm={3}>
                  <TextInput
                    label="Remote Charges"
                    name="remote_per_day_rate"
                    value={values.remote_per_day_rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.remote_per_day_rate && touched.remote_per_day_rate && errors.remote_per_day_rate}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl variant="outlined" fullWidth sx={Styles.remoteWprking}>
                    <InputLabel>Duration Unit</InputLabel>
                    <Select
                      label="Duration Unit"
                      name="remote_rate_unit"
                      value={values.remote_rate_unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={'hour'}>per hour</MenuItem>
                      <MenuItem value={'day'}>per day</MenuItem>
                      <MenuItem value={'week'}>per week</MenuItem>
                      <MenuItem value={'month'}>per month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextInput
                    label="On Site Charges"
                    name="onsite_per_day_rate"
                    value={values.onsite_per_day_rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.onsite_per_day_rate && touched.onsite_per_day_rate && errors.onsite_per_day_rate}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl variant="outlined" fullWidth sx={Styles.remoteWprking}>
                    <InputLabel>Duration Unit</InputLabel>
                    <Select
                      label="Duration Unit"
                      name="onsite_rate_unit"
                      value={values.onsite_rate_unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={'hour'}>per hour</MenuItem>
                      <MenuItem value={'day'}>per day</MenuItem>
                      <MenuItem value={'week'}>per week</MenuItem>
                      <MenuItem value={'month'}>per month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="Current Organization"
                    name="current_employer"
                    value={values.current_employer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.current_employer && touched.current_employer && errors.current_employer}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="Current Designation"
                    name="current_role"
                    value={values.current_role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.current_role && touched.current_role && errors.current_role}
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextInput
                    label="Years of Experience"
                    name="total_work_experience_years"
                    value={values.total_work_experience_years}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={
                      errors.total_work_experience_years &&
                      touched.total_work_experience_years &&
                      errors.total_work_experience_years
                    }
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <FormControl variant="outlined" fullWidth sx={Styles.remoteWprking}>
                    <InputLabel>Location Preference</InputLabel>
                    <Select
                      label="Remote Working"
                      name="working_remotely"
                      value={values.working_remotely}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={1}>Remote Only</MenuItem>
                      <MenuItem value={0}>On Site Possible</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sx={Styles.submitButton}>
                  <FormButton disabled={isFormSubmit} label="Add" />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Form>
    </div>
  );
};

export default AddTalentManagement;
