const Styles = {
  appbar: {
    background: "#fff",
    boxShadow: "0px 2px 6px #00000030",
  },
  toolbar: {
    justifyContent: "space-between",
    display: "flex",
  },
  brandLink: {
    textDecoration: "none",
  },
  brandWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  brandLogo: {
    width: ["20px", "25px", "25px", "25px", "25px"],
  },
  brandName: {
    color: (theme) => theme.palette.text_heading.main,
    fontSize: "1rem",
    fontWeight: "bold",
  },
  desktopNavbarWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: ["start", "start", "start", "center", "center"],
  },
  desktopNavlink: {
    color: (theme) => theme.palette.text_data.main,
    textDecoration: "none",
  },
  desktopActiveLink: {
    color: "#607ebb",
  },
  desktopNavLinkList: {
    display: "flex",
  },
  desktopNavLinkListItem: {
    paddingRight: ["0", "0", "0", "15px", "15px"],
  },
  desktopNavLinkListItemText: {
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    color: "inherit",
    "& hover": {
      color: (theme) => theme.palette.primary.main,
    },
  },
  desktopProfileNavLinkList: {
    display: "flex",
    position: "absolute",
    right: "0",
  },
  desktopProfileNavLink: {
    textDecoration: "none",
    cursor: "default",
  },
  desktopProfileNavLinkListItem: {
    paddingLeft: "0",
    paddingRight: "0",
    cursor: "pointer",
  },
  desktopProfileIcon: {
    backgroundColor: "#fff",
    cursor: "pointer",
    position: "absolute",
    right: "1rem",
    top: "0.8rem",
  },
  desktopProfileMobile: {
    backgroundColor: "#fff",
    cursor: "pointer",
    width: 30,
    height: 30,
    position: "absolute",
    right: "5rem",
  },
  desktopProfileText: {
    cursor: "pointer",
    textDecoration: "none",

    color: (theme) => theme.palette.text_data.main,
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  desktopLogoutButton: {
    cursor: "pointer",
  },
  desktopLogoutButtonIcon: {
    color: (theme) => theme.palette.primary.main,
    fontSize: "2rem",
  },
  desktopLogoutButtonText: {
    color: (theme) => theme.palette.text_data.main,
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  mobileNavLinkListCloseButton: {
    justifyContent: "flex-end",
    width: "100%",
  },
  mobileNavLinkListCloseButtonIcon: {
    fontSize: "1.8rem",
    marginRight: "1.4rem",
    color: (theme) => theme.palette.primary.main,
  },
  mobileNavLink: {
    color: (theme) => theme.palette.text_data.main,
    textDecoration: "none",
  },
  mobileNavActiveLink: {
    color: "#607ebb",
  },
  mobileNavListItem: {
    color: "inherit",
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  mobileProfileLink: {
    textDecoration: "none",
    color: (theme) => theme.palette.text_data.main,
  },
  mobileProfileLinkText: {
    color: "inherit",
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  mobileLogoutLinkText: {
    color: "inherit",
    "& .MuiListItemText-primary": {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#515d5e",
    },
  },
  mobileNavLinkListOpenButtonIcon: {
    fontSize: "1.8rem",
    color: (theme) => theme.palette.primary.main,
  },
  profileIconMenuProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  logoutIcon: {
    color: (theme) => theme.palette.error.main,
  },
  resetIcon: {
    color: "#3561bb",
  },
  confirmWrapper: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "#fff",
    padding: "1rem",
    width: ["80%", "50%", "40%", "50%", "30%"],
    textAlign: "center",
    borderRadius: "4px",
  },
  loginProfileImageWrapper: {
    width: "5rem",
    height: "5rem",
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50px",
    overflow: "hidden",
    margin: "auto",
    marginTop: "1rem",
  },
  loginProfileImage: {
    width: "100%",
  },
  loader: {
    width: "16px !important",
    height: "16px !important",
    margin: "5px",
    color: "#fff",
  },
  authButton: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "5px",
    margin: "1rem 0",
    color: "#fff",
    fontWeight: "700",
    backgroundColor: (theme) => theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#7b94c6",
    },
  },
  closeIcon: {
    float: "right",
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  formError: {
    color: (theme) => theme.palette.error.main,
    marginBottom: "0",
    fontSize: "1rem",
    textAlign: "left",
  },
  resetFormError : {
    color: (theme) => theme.palette.error.main,
    marginBottom: "0",
    textAlign: "left",
    fontFamily: "Nunito Sans,sans-serif",
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "1.66",
  }
};

export default Styles;
