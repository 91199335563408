const Styles = {
  filterContainer: {
    display: "grid",
    gridTemplateColumns: "80% 20%",
    padding: ["1rem 1rem", "0 1.5rem 1rem", "0 2rem 1rem", "0 2rem 1rem", "0 2rem 1rem"],
  },
  filterContainer1: {
    display: "grid",
    gridTemplateColumns: ["80% 20%", "80% 20%", "40% 20%", "40% 20%"],
    padding: ["1rem 1rem", "0 1.5rem 1rem", "0 2rem 1rem", "0 2rem 1rem", "0 2rem 1rem"],
  },
  filterWrapper: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: {
      md: "1fr 1fr",
    },
  },
  filterWrapper1: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: {
      md: "1fr",
    },
  },
  resetContainer: {
    alignSelf: "center",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr"],
    height: ["auto", "auto", "43px", "43px", "43px"],
  },
  resetWrapper: {
    display: "grid",
    padding: "0px",
    // paddingLeft: "1rem",
    "&.MuiButtonBase-root:hover": {
      bgcolor: "transparent",
    },
  },
  resetIcon: {
    margin: "auto",
    width: ["20px", "20px", "18px", "18px", "18px"],
  },
  pageHeaderButtonWrapper: {
    textAlign: "right",
    position: ["relative", "relative", "none", "none", "none"],
    // bottom:['5rem', '5rem', '0','0','0'],
    right: ["5rem", "5rem", "0", "0", "0"],
  },
  resetText: {
    color: "#607ebb",
  },
  searchFilterContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  searchFilter1: {
    borderRadius: "50px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    fontSize: "1rem",
    "& .MuiInputBase-input": {
      xs: { padding: "6px 12px" },
      md: { padding: "6px 12px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#607ebb",
    },
  },
  searchFilter2: {
    borderRadius: "50px",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    borderLeft: "0",
    fontSize: "1rem",
    "& .MuiInputBase-input": {
      xs: { padding: "6px 12px" },
      md: { padding: "6px 12px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#607ebb",
    },
  },
  sortFilterContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  sortFilter1: {
    borderRadius: "50px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    fontSize: "1rem",
    "& .MuiInputBase-input": {
      xs: { padding: "6px 12px" },
      md: { padding: "6px 12px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#607ebb",
    },
  },
  sortFilter2: {
    borderRadius: "50px",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    fontSize: "1rem",
    "& .MuiInputBase-input": {
      xs: { padding: "6px 12px" },
      md: { padding: "6px 12px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#607ebb",
    },
  },
};

export default Styles;
