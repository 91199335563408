import React from "react";
import { leadStatusType } from "Utils";
import { TextField, MenuItem } from "Assets/Material";
import { StopOutlined } from "Assets/Icons";

const LeadStatusTypeSelect = (props) => {
  return (
    <React.Fragment>
      <TextField
        select
        fullWidth
        id={props?.name}
        name={props?.name}
        label={props?.label}
        value={props?.value}
        size="small"
        margin="dense"
        autoComplete="off"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={props?.onChange}
        onClick={props?.onClick}
        onBlur={props?.onBlur}
        error={props?.onError}
        helperText={props?.onError}
        sx={props?.sx}
      >
        {leadStatusType.map((item, index) => (
          <MenuItem key={index} value={item.value} disabled={item.isDisabled} onClick={() =>props?.handelOnDataaa(item)}>
            <StopOutlined style={{ color: item.color, backgroundColor: item.color, width: "2%", height: "10px" }} />{" "}
            &nbsp;
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </React.Fragment>
  );
};

export default LeadStatusTypeSelect;
