import React from 'react';
import { Box, H1, HR } from "Assets/Material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Styles from "Common/Swapper/Styles";

const Swapper = (props) => {

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={!!props?.onOpen}
      onOpen={props?.onOpen ? props.onOpen : () => {}}
      onClose={() => props?.onClose()}
    >
      <Box sx={Styles.swapper}>
        <HR sx={Styles.swapperHook} />
        <H1 sx={Styles.swapperTitle}>
          {props?.swapperTitle}
        </H1>
        <HR sx={Styles.swapperDevider} />
        {props?.children}
      </Box>
    </SwipeableDrawer>
  )
}

export default Swapper;