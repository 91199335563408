const Styles = {
  actionButtonIcon: {
    color: (theme) => theme.palette.primary.main,
  },
  actionMenuWrapper: {
    marginLeft: "-6.5%",
    marginTop: "-36px",
    "& .MuiList-root": {
      padding: "0px!important",
      display: "flex",
    },
    "& .MuiPaper-root": {
      boxShadow: "0px 0px 4px 0px #dfdfdf29",
      borderRadius: "30px",
    },
  },
};

export default Styles;
