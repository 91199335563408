import React, { useCallback, useState } from 'react';
import { useRecoilState, toasterGlobal } from "Atom";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { H1, Box, Grid, Button, CircularProgress } from 'Assets/Material';
import { ConsultantProfileStyles } from "Assets/Styles";
import { DateInput } from "Common";
import {markInvoicePaidService, submitInvoiceService} from "Services/InvoiceService";
import { authUser, usertype } from "Utils";
import ProofOfPaymentUploader from './ProofOfPaymentUploader';

export default function MarkPaidForm(props) {

    const setToaster = useRecoilState(toasterGlobal)[1];

    const [refNumber, setRefNumber] = useState('');
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paymentFile, setPaymentFile] = useState('');

    const [ isLoading, setLoading ] = useState(false);
    const [ submitSuccess, setSubmitSuccess] = useState(false);

    const submit = async () => {

        if(isLoading){
            return;
        }
        

        const invoice_data = {
            id: props.invoice.consultant_invoice_id,
            user_id: authUser().user_id,
            payment_file_id: paymentFile,
            ref_number: refNumber,
            payment_date: paymentDate.toISOString().split('T')[0],
            consultant_user_id: props.invoice.FK_user_id,
            invoice_number: props.invoice.invoice_number
        };


        if(!paymentFile || !refNumber){
            setToaster({ isOpen: true, status: "fail", message: "Please complete the form and submit!" });
        } else {
            setLoading(true);
            setTimeout(() => {
                
            }, 2000);
            let response = await markInvoicePaidService(invoice_data);
            setLoading(false);
            if(response.status === 200){
                setSubmitSuccess(true);
                setToaster({ isOpen: true, status: "success", message: "Invoice has been marked as paid!" });
                props.handleClose(true);
                
            } else {
                setToaster({ isOpen: true, status: "fail", message: response.message || "Something went wrong!" });
            }
        }
        
        
    }

  return (
    <div>
        
      <Dialog 
        maxWidth={'md'}
        open={true} 
        onClose={props.handleClose}>
        <DialogTitle>Mark Paid - {props.invoice.invoice_number}</DialogTitle>
        <DialogContent>
            
            <DialogContentText>
                
            </DialogContentText>
            <Grid container>
                <Grid item xs="6" sx={{padding: 1}}>
                    <TextField
                        required
                        autoFocus
                        size="small"
                        margin="dense"
                        id="ref-number"
                        label="Reference Number"
                        type="text"
                        value={refNumber}
                        onChange={(e) => setRefNumber(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs="6" sx={{padding: 1}}>
                    <DateInput
                        label="Payment Date"
                        initialValues={paymentDate}
                        getDate={(date) => setPaymentDate(new Date(date))}
                    />
                </Grid>
                <Grid item xs="12" sx={{padding: 1}}>
                    <ProofOfPaymentUploader 
                        initialValues={[]} 
                        getData={(file_id) => {
                            setPaymentFile(file_id);
                        }} />
                </Grid>
            </Grid>
            <Grid item xs="6" sx={{padding: 1}}>
                {
                    submitSuccess 
                    ?   <><div style={{padding: '1rem 0'}}>Marked as paid successfully!</div><Button variant="radius" onClick={() => props.handleClose(true)} >Close</Button></>
                    :   <><Button disabled={isLoading} variant="radius" onClick={() => submit()} >Submit</Button>{isLoading && <CircularProgress sx={ConsultantProfileStyles.UpdateLoader} />}</>
                }
                
            </Grid>
            
        </DialogContent>
        
        
      </Dialog>
    </div>
  );
}
