const Styles = {
  tablePopupWrapper: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "#fff",
    padding: "1rem",
    width: ["80%", "50%", "30%", "30%", "20%"],
    maxHeight: "50vh",
    height: "auto",
    overflow: "auto",
  },
  tablePopupCloseIcon: {
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  tablePopupList: {
    margin: "0",
    listStyle: "none",
    padding: "0",
    background: "#ffffff",
    borderRadius: "5px",
    width: "auto !important",
    zIndex: "99",
  },
  tablePopupListItem1: {
    padding: "5px",
    borderBottom: "1px solid",
    borderColor: "#44444480",
    whiteSpace: "pre-line !important",

    fontSize: "0.8rem",
  },
  tablePopupListItem2: {
    padding: "5px",

    fontSize: "0.8rem",
  },
};

export default Styles;
