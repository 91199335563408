const Styles = {
  countryCodeWrapper: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    position: "relative",
    top: "0.5rem",
  },
  countryCodeWrapper1: {
    border: "1px solid",
    borderRadius: "4px",
    position: "relative",
    top: "0.5rem",
    borderColor: (theme) => theme.palette.error.main,
  },
  countryCodeLabel: {
    fontSize: "0.8rem",
    color: "#666666",
    position: "absolute",
    top: "-0.6rem",
    left: "0.8rem",
    backgroundColor: "#fff",
    padding: "0 0.2rem",
  },
  countryCodeLabelError: {
    fontSize: "0.8rem",
    position: "absolute",
    top: "-0.6rem",
    left: "0.8rem",
    backgroundColor: "#fff",
    padding: "0 0.2rem",
    color: (theme) => theme.palette.error.main,
  },
  countryDataWrapper: {
    margin: "0",
    padding: "0",
    height: "100%",
    minHeight: "2.4rem",
    display: "flex",
    paddingTop: "0.6rem",
    gap: "1rem",
  },
  countryFlag: {
    width: "3rem",
    height: "1.7rem",
    minHeight: "1.7rem",
    maxHeight: "1.7rem",
    marginLeft: "1rem",
  },
  countryCode: {
    marginTop: "0.2rem",
  },
  countrySelect: {
    position: "absolute",
    width: "100%",
    top: "0",
    height: "100%",
    background: "transparent",
    color: "transparent",
    borderColor: "transparent",
    outline: "none",
  },
  countrySelectOption: {
    color: (theme) => theme.palette.text_heading.main,
  },
  errorCountryCode: {
    color: (theme) => theme.palette.error.main,
    padding: "0",
    position: "absolute",
    margin: " 0.3rem 0rem",
    fontSize: "0.75rem",
  },
};

export default Styles;
