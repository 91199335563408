const Styles = {
  confirmWrapper: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "#fff",
    padding: "1rem",
    width: ["80%", "50%", "40%", "30%", "30%"],
    borderRadius: "8px",
    outline: "none",
    boxShadow: "none",
  },

  confirmWrapperS: {
    // position: "absolute",
    // top: "30%",
    // left: "50%",
    // transform: "translate(-50%,-50%)",
    // background: "#fff",
    // padding: "1rem",
    // width: "50%",
    // borderRadius: "8px",
    // outline: "none",
    // heigth: "auto",
    // maxHeight: "80%",
    outline: "none!important",
    boxShadow: "none!important",
    overflowY: "auto",
    background: "#fff",
    width: "50%",
    borderRadius: "8px",
    outline: "none",
    marginTop: "4.5%!important",
    margin: "auto",
    outline: "none",
    border: "0px solid ",
    maxHeight: "80vh",
    padding: "1rem",
  },
  P1: {
    padding: "1.5rem",
  },
  confirmWrapperView: {
    background: "#fff",
    width: "70%",
    // borderRadius: "8px",
    outline: "none",
    marginTop: "10px !important",
  },
  message: {
    textAlign: "center !important",
    margin: "1rem 0 !important",
    fontSize: "1.5rem",
  },
  confirmDeleteIcon: {
    margin: "0 auto !important",
    display: "block !important",
    fontSize: "3rem !important",
    marginTop: "1rem !important",
    color: (theme) => theme.palette.error.main,
  },
  approvalConfirmDeleteIcon: {
    margin: "0 auto !important",
    display: "block !important",
    fontSize: "3rem !important",
    marginTop: "1rem !important",
    color: (theme) => theme.palette.primary.main,
  },
  approvalmDeleteIcon: {
    marginLeft: "auto",
    display: "block !important",
    fontSize: "1.5rem !important",
    marginTop: "0rem !important",
    cursor: "pointer",
    color: "#8d8a8a",
  },
  buttonWrapper: {
    textAlign: "center",
    margin: "1rem 0",
  },
  buttonWrapper2: {
    textAlign: "center",
    margin: "2rem 0",
  },
  deleteButton: {
    color: "#FF1744",
    borderColor: "#FF1744",
    backgroundColor: "#fff !important",
    marginRight: "0.5rem",
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1rem"],
    "&:hover": {
      background: "#FF1744 !important",
    },
  },
  submitButton: {
    marginRight: "0.5rem",
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1rem"],
  },

  uploadButton: {
    backgroundColor: "#fff",
    padding: " 7px 22px",
    borderRadius: "30px",
    textAlign: "center",
    border: " 1px solid #607ebb",
    fontWeight: "bold",
    color: "#607ebb",
    marginRight: "1rem",
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1rem"],
    cursor: "pointer",
    "&:hover": {
      background: "#607ebb !important",
      color: "#fff !important",
    },
  },

  cancelButton: {
    marginLeft: "0.5rem",
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1rem"],
  },
};

export default Styles;
