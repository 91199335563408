const PublishCaseStudyStyles = {
  coverImageIconContainer: {
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    width: "100%",
    height: "8rem",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItem: "center"
  },
  coverImageIconWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center"
  },
  coverImageIconTextWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center"
  },
  coverImageIconText: {
    color: (theme) => theme.palette.primary.main,
  },
  coverImageIcon: {
    fontSize: "2rem",
    position: "absolute",
    bottom: "0",
    color: (theme) => theme.palette.primary.main
  },
  coverImageWrapper: {
    width: "100%",
    height: "8rem",
    overflow: "hidden",
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    position: "relative"
  },
  coverImage: {
    width: "100%",
    height: "auto",
  },
  deleteImageIcon: {
    color: (theme) => theme.palette.error.main,
    position: "absolute",
    right: "0.5rem",
    top: "0.5rem",
  },
  submitPublishButton: {
    width: { xs: "48%", sm: "48%", md: "auto" },
    marginLeft: { xs: "2%", sm: "2%", md: "1rem" }
  },
  submitButton: {
    width: { xs: "48%", sm: "48%", md: "auto" },
    marginRight: { xs: "2%", sm: "2%" }
  },
  noImage: {
    margin: "auto",
    fontSize: "5rem",
    color: (theme) => theme.palette.primary.main
  },
  formButton: {
    width: "80%",
    margin: "0 auto",
    display: "block",
  },
  loader: {
    width: "20px !important",
    height: "20px !important",
    margin: "5px"
  }
}
export default PublishCaseStudyStyles;