const Styles = {
  dataProjectTitle: {
    padding: "16px 5px",
    position: "relative"
  },
  dataProjectTitleContent: {
    paddingRight: ["3rem", "0", "2rem", "1rem", "0"]
  },
  dataProjectTitleContentLaunchIcon: {
    right: ["1rem", "0", "0", "0", "2rem"],
    color: "#607ebb",
    marginRight: "1rem",
    position: "absolute",
    fontSize: "1.2rem",
    cursor: "pointer"
  },
  actionMenuWrapper: {
    marginLeft: "-6.5%",
    marginTop: "-36px",
    "& .MuiList-root": {
      padding: "0px!important",
      display: "flex",
      boxShadow: "0",
    },
    "& .MuiPaper-root": {
      boxShadow: "0px 1px 4px #00000029",
      borderRadius: "30px",
    },
  },
  publishIcon: {
    color: "#607ebb",
    width: "1.2rem"
  },
};

export default Styles;