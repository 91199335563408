import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, LI } from "Assets/Material";
import { getProjectsService, getIndustriesService } from "Services/CountAndDataService";
import { getCityService } from "Services/AdminProfileServices";
import CountryCode from "Utils/CountryCode";

const CustomAutocomplete = (props) => {
  const [value, setvalue] = useState(props?.initialValues);
  const [dataList, setDataList] = useState([]);

  const getProjectDataList = async () => {
    const projects_list = await getProjectsService("projects");
    if (projects_list) setDataList(projects_list);
  };

  const getIndustryDataList = async () => {
    const industries_list = await getIndustriesService("industry_categorizations");
    if (industries_list) setDataList(industries_list);
  };

  const getCityDataList = async () => {
    const country_code = props?.data?.country_code;
    const country = await CountryCode.filter((i) => +i.phone === country_code);
    if (country.length > 0) {
      const cityRes = await getCityService(country[0].code);
      setDataList(cityRes.data);
    }
  };

  const fetchAutocompleteData = async () => {
    if (props?.autocompleteFor === "project") await getProjectDataList();
    if (props?.autocompleteFor === "industry") await getIndustryDataList();
    if (props?.autocompleteFor === "location") await getCityDataList();
  };

  useEffect(() => {
    fetchAutocompleteData();
    return () => setDataList([]);
  }, [props?.data]);

  return (
    <>
      {props?.selectMultiple ? (
        <Autocomplete
          multiple
          id={props?.name}
          name={props?.name}
          value={props?.initialValues || value}
          disableClearable
          options={dataList}
          renderOption={
            props?.autocompleteFor === "industry"
              ? (props, option) => (
                  <LI {...props} key={option.id}>
                    {" "}
                    {option.name}{" "}
                  </LI>
                )
              : (props, option) => (
                  <LI {...props} key={option.id}>
                    {" "}
                    {option[props?.optionKey]}{" "}
                  </LI>
                )
          }
          getOptionLabel={(option) => option[props?.optionKey] || ""}
          onChange={(_, value) => {
            setvalue(value);
            props?.getValue(value.map((i) => i.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              label={props?.label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      ) : (
        <Autocomplete
          id={props?.name}
          name={props?.name}
          value={props?.initialValues || value}
          disableClearable
          options={dataList}
          renderOption={
            props?.autocompleteFor === "location"
              ? (props, option) => (
                  <LI {...props} key={option.id}>
                    {" "}
                    {option.city_ascii}, {option.country}{" "}
                  </LI>
                )
              : (props, option) => (
                  <LI {...props} key={option.id}>
                    {" "}
                    {option[props?.optionKey]}{" "}
                  </LI>
                )
          }
          getOptionLabel={(option) => option[props?.optionKey] || ""}
          onChange={(_, value) => {
            setvalue(value);
            props?.getValue(value.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              label={props?.label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default CustomAutocomplete;
