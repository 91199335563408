
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

export const CustomDatePicker = ({
    label,
    sx,
    initialValues,
    disablePast,
    getDate,
}) => {

  const [dateVal, setDateVal] = useState(initialValues);

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker 
        label={label}
        InputProps={{ readOnly: true }}
        inputFormat="dd/MM/yyyy"
        value={dayjs(dateVal)}
        disablePast={disablePast}
        onChange={(newValue) => {
          setDateVal(newValue);
          getDate(newValue);
        }}
      />
    </LocalizationProvider>
    </>
  );
};