const Styles = {
  createProjectWrapper: {
    padding: ["0", "0", "0 5rem", "0 5rem", "0 5rem"],
  },
  pageHeader: {
    fontWeight: "700",
    fontSize: ["1.2rem", "1.5rem", "1.5rem", "1.5rem", "1.5rem"],
    paddingLeft: "0.5rem",
    margin: ["1rem", " 1rem", " 1rem", " 1rem", " 1rem"],
    color: (theme) => theme.palette.text_heading.main,
    borderLeft: (theme) => [`5px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  formWrapper: {
    padding: ["0 1rem 2rem 1rem", "0 3rem 2rem 3rem", "0 1rem 2rem 1rem", "0 5rem 5rem 5rem", "0 5rem 5rem 5rem"],
    paddingTop: "0",
  },
  subTitleWrapper: {
    padding: "0",
  },
  subTitle: {
    padding: "0",
    marginBottom: "0",
    fontSize: "0.8rem",
    fontWeight: "700",
    color: (theme) => theme.palette.text_heading.main,
  },
  backToProject: {
    margin: ["1.2rem 1rem 0 1rem", "2rem 1rem 0 1rem", "3rem 1rem 0 1rem"],
    float: "right",
    // background: (theme) => theme.palette.primary.main,
    // borderColor: (theme) => theme.palette.primary.main,
    // color: "#fff",
  },
};

export default Styles;
