const ConsultantProfileStyles = {
  CancelIcon: {
    color: (theme) => theme.palette.primary.main,
    width: ["1rem", "1.5rem"],
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    }
  },
  UpdateLoader: {
    width: "20px !important",
    height: "20px !important",
    float: "right"
  },
  componentWrapper: {
    backgroundColor: '#90c3cf29',
    padding: ["1rem", "2rem", "2rem 4rem", "2rem 4rem", "2rem 4rem"]
  },
  preferencesContentWrapper: {
    boxShadow: "2px 2px 4px #00000029",
    backgroundColor: "#ffffff",
    border: "1px solid #4444",
    borderRadius: "5px",
  },
  insideWrapper: {
    padding: "0rem 1rem 0rem 1rem",
  },
  heading: {
    fontWeight: "700",
    margin: "0",
    padding: "0",
    color: (theme) => theme.palette.text_heading.main,
    fontSize: ["0.8rem", "1rem"],
  },
  shareURLWrapper: {
    marginTop: "2rem",
  },
  shareURL: {
    color: (theme) => theme.palette.primary.main,
    fontSize: ["1rem", "1.2rem"],
    cursor: "pointer",
    float: "right",
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  linkedInLogo: {
    marginLeft: "1rem",
  }
}
export default ConsultantProfileStyles;