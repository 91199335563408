const ChatStyles = {
  dialogWrapper: {
    padding: ["1rem", "1rem", "2rem", "2rem", "2rem"],
  },
  dialogHeading: {
    fontSize: '1.6rem',
    fontWeight: 'bold'
  },
  chatHeader: {
    padding: "0.5rem 1rem",
    color: "#FFF",
    background: (theme) => theme.palette.primary.main,
    borderRadius: "0.5rem 0.5rem 0 0"
  },
  activeCheckbox: {
    color: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
};

export default ChatStyles;
