export const SearchFilter = {
  project: [
    { name: "Search By", value: "1" },
    { name: "Project Title", value: "2" },
    { name: "Client", value: "3" },
  ],
  talentManagement: [
    {name: "ID", value: "6"},
    { name: "Search By", value: "1" },
    { name: "Name", value: "2" },
    { name: "Organization", value: "3" },
    { name: "Role", value: "4" },
    { name: "Capability", value: "5" },
  ],
  clients: [
    { name: "Search By", value: "1" },
    { name: "Name", value: "2" },
    { name: "Organization", value: "3" },
    { name: "Industry", value: "4" },
    {name: "Email", value: "5"}
  ],
  projectLeads: [
    { name: "Search By", value: "1" },
    { name: "Name", value: "2" },
    { name: "Organization", value: "3" },
    { name: "Industry", value: "4" },
    { name: "Capability", value: "5" },
    {name: "Requirement", value: "6"}
  ],

  unapprovedLeads: [
    { name: "Search By", value: "1" },  
    { name: "Name", value: "2" },
    { name: "Organization", value: "3" },
    {name: "Email", value: "4"},
    { name: "Industry", value: "5" },
    // { name: "Industry", value: "4" },
    // { name: "Capability", value: "5" },
  ], 
  caseStudies: [
    { name: "Search By", value: "1" },
    { name: "Case Study", value: "2" },
    { name: "Project", value: "7" },
    { name: "Organization", value: "3" },
    { name: "Industry", value: "4" },
    { name: "Capability", value: "5" },
    { name: "Consultant", value: "6" },
  ],
  publishedCaseStudies: [
    { name: "Search By", value: "1" },
    { name: "Title", value: "2" },
    { name: "Industry", value: "3" },
    { name: "Capability", value: "4" },
  ],
  approvalPanel: [
    { name: "Search By", value: "1" },
    { name: "Project Title", value: "2" },
    { name: "Client", value: "3" },
  ],
  suggestedConsultant: [
    { name: "Search By", value: "1" },
    { name: "Name", value: "2" },
    { name: "Organization", value: "3" },
    { name: "Role", value: "4" },
  ],
};

export const SortFilter = {
  project: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Start Date",
      value: "expected_start_date",
      options: [
        { name: "Newest to Oldest", value: "DESC" },
        { name: "Oldest to Newest", value: "ASC" },
      ],
    },
    {
      name: "Status",
      value: "project_stage",
      options: [
        { name: "In-progress", value: "DESC" },
        { name: "Completed", value: "ASC" },
      ],
    },
  ],
  talentManagement: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Experience",
      value: "total_work_experience_years",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    {
      name: "Charges/Hr",
      value: "remote_per_day_rate",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    {
      name: "Status",
      value: "status",
      options: [
        { name: "Engaged", value: "DESC" },
        { name: "Available", value: "ASC" },
      ],
    },
    {
      name: "Profile Completion",
      value: "profile_completion_score",
      options: [
        { name: "Most Complete", value: "DESC" },
        { name: "Least Complete", value: "ASC" },
      ],
    },
  ],
  billingConsultants: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Billed Amount",
      value: "total_billed_amount",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    {
      name: "Unbilled Amount",
      value: "total_unbilled_amount",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    {
      name: "Open Invoices",
      value: "open_invoice_count",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    {
      name: "Last Invoice Date",
      value: "last_invoice_date",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
  ],
  projectLeads: [
    {
      name: "Filter By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Budget",
      value: "budget",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    {
      name: "Date",
      value: "expected_start_date",
      options: [
        { name: "Newest to Oldest", value: "DESC" },
        { name: "Oldest to Newest", value: "ASC" },
      ],
    },
    {
      name: "Lead Status",
      value: "lead_status",
      options: [
        { name: "Ongoing Project", value: "Ongoing Project" },
        { name: "Under Client Evaluation", value: "Under Client Evaluation" },
        { name: "Demand Generation", value: "Demand Generation"},
        { name: "Open for Application", value: "Open for Application" },
        { name: "Project Completed", value: "Project Completed" },
        { name: "Project Discontinued", value: "Project Discontinued" },
      ],
    },
  ],
  unapprovedLeads: [
    {
      name: "Filter By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Date",
      value: "createdAt",
      options: [
        { name: "Newest to Oldest", value: "DESC" },
        { name: "Oldest to Newest", value: "ASC" },
      ],
    },
    {
      name: "Budget",
      value: "budget",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
    
  ],
  caseStudies: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Date",
      value: "date",
      options: [
        { name: "Newest to Oldest", value: "DESC" },
        { name: "Oldest to Newest", value: "ASC" },
      ],
    },
  ],
  publishedCaseStudies: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Date",
      value: "date",
      options: [
        { name: "Newest to Oldest", value: "DESC" },
        { name: "Oldest to Newest", value: "ASC" },
      ],
    },
  ],
  approvalPanel: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Start Date",
      value: "expected_start_date",
      options: [
        { name: "Newest to Oldest", value: "DESC" },
        { name: "Oldest to Newest", value: "ASC" },
      ],
    },
    {
      name: "Status",
      value: "stage_id",
      options: [
        { name: "Completed", value: "DESC" },
        { name: "In-progress", value: "ASC" },
      ],
    },
  ],
  suggestedConsultant: [
    {
      name: "Sort By",
      value: "id",
      options: [{ name: "Default", value: "DESC" }],
    },
    {
      name: "Experience",
      value: "work_experience",
      options: [
        { name: "High to Low", value: "DESC" },
        { name: "Low to High", value: "ASC" },
      ],
    },
  ],
};
