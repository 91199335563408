import React from "react";
import Styles from "Common/DesktopTableDataPopup/Styles";
import { Box, UL, LI, ClickAwayListener } from "Assets/Material";
import { CloseIcon } from "Assets/Icons";

const DesktopTableDataPopup = (props) => {

  if (!props.isUnapprovedLead) {

    return (
      <>
        {props?.onOpen && +props?.selectedRow === +props?.currentRow && (
          <Box sx={Styles.tablePopupWrapper}>
            <CloseIcon onClick={() => props?.onClose()} sx={Styles.tablePopupCloseIcon} />
            <ClickAwayListener onClickAway={() => props?.onClose()}>
              <UL sx={Styles.tablePopupList}>
                {props?.data &&
                  JSON.parse(props?.data)?.map((i, index) => {
                    if (i.id) {
                      return (
                        <LI
                          key={index}
                          sx={
                            JSON.parse(props?.data).length > 1 && +JSON.parse(props?.data).length - 1 !== +index
                              ? Styles.tablePopupListItem1
                              : Styles.tablePopupListItem2
                          }
                        >
                          {" "}
                          {i.name}
                        </LI>
                      );
                    }
                  })}
              </UL>
            </ClickAwayListener>
          </Box>
        )}
      </>
    );

  } else {
    return (
      <>
        {props?.onOpen && +props?.selectedRow === +props?.currentRow && (
          <Box sx={Styles.tablePopupWrapper}>
            <CloseIcon onClick={() => props?.onClose()} sx={Styles.tablePopupCloseIcon} />
            <ClickAwayListener onClickAway={() => props?.onClose()}>
              <UL sx={Styles.tablePopupList}>
                {props?.data &&
                  JSON.parse(props?.data)?.map((i, index) => {
                    if (i) {
                      return (
                        <LI
                          key={index}
                          sx={
                            JSON.parse(props?.data).length > 1 && +JSON.parse(props?.data).length - 1 !== +index
                              ? Styles.tablePopupListItem1
                              : Styles.tablePopupListItem2
                          }
                        >
                          {" "}
                          {i}
                        </LI>
                      );
                    }
                  })}
              </UL>
            </ClickAwayListener>
          </Box>
        )}
      </>
    );
  }
};

export default DesktopTableDataPopup;
