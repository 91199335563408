const ConsultantProfileHeaderStyles = {
  contentWrapper: {
    padding: "2rem",
    boxShadow: "2px 2px 4px #00000029",
  },
  profileImageWrapper: {
    width: "4rem",
    height: "4rem",
    margin: "0 auto",
    marginLeft: ["-16px", "2px", "", "32px", "32px"],
    position: "relative",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "block",
    marginLeft: [{ lg: "0" }],
  },
  profileImageEditIcon: {
    position: "absolute",
    bottom: "-0.2rem",
    right: "-0.2rem",
    border: "1px solid",
    borderRadius: "50px",
    padding: "2px",
    fontSize: "1.5rem",
    backgroundColor: "#fff",
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
  consultantName: {
    color: (theme) => theme.palette.text_heading.main,
    margin: "0",
    padding: "0",
    fontSize: "1.2rem",
    wordBreak: "brealAll",
  },
  backArrow: {
    paddingLeft: "1%",
    paddingBottom: "1%",
    marginLeft: ["-1rem", "none", "none", "none", "none"],
  },
  consultantNameEditInput: {
    width: "50%",
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #4444",
    fontSize: "1rem",
    boxShadow: "2px 2px 4px #00000029",
    "&:focus": {
      outline: "none",
    },
  },
  consultantNameSubmitIcon: {
    color: (theme) => theme.palette.primary.main,
    width: ["1.2rem", "1.5rem"],
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  consultantNameCancelIcon: {
    color: (theme) => theme.palette.primary.main,
    width: ["1.2rem", "1.5rem"],
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  editconsultantNameError: {
    fontSize: "x-small",
    color: "#ed143d",
    position: "relative",
    left: "1%",
    top: "0",
    bottom: "0",
  },
  consultantNameEditIcon: {
    color: (theme) => theme.palette.primary.main,
    width: ["1.2rem", "1.5rem"],
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
  },
  names: {
    width: ["100%", "37%", "37%", "25%", "25%"],
    marginTop: ["2%", "0", "0", "0", "0"],
    height: ["2rem", "0", "0", "0", "0"],
  },
  roles: {
    width: ["80%", "78%", "75%", "51.3%", "51%"],
    marginTop: ["0", "2%", "2%", "1%", "1%"],
  },
  iconPositionCheck: {
    position: "relative",
    float: "right",
  },
  iconPosition: {
    position: "relative",
    float: "right",
  },
  firstNameInput: {
    width: ["40%", "50%", "25%"],
    margin: "0",
  },
  lastNameInput: {
    width: ["40%", "50%", "25%"],
    margin: "0",
    marginLeft: "0.5rem",
  },
  updateLoader: {
    width: "15px !important",
    height: "15px !important",
    margin: "0 0 -0.5rem 1rem",
  },
  consultantNameCapital: {
    textTransform: "capitalize"
  },
  updateDate : {
    font: "normal normal normal 16px/22px Nunito Sans",
    letterSpacing: "0px",
    color: "#666666",
    opacity: "1",
    marginTop : "1rem"
  }
};

export default ConsultantProfileHeaderStyles;
