import React from "react";
import { status } from "Utils";
import { TextField, MenuItem } from "Assets/Material";

const StatusSelect = (props) => {

  return (
    <React.Fragment>
      <TextField
        select
        fullWidth
        id={props?.name}
        name={props?.name}
        label={props?.label}
        value={props?.value}
        size="small"
        margin="dense"
        autoComplete="off"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={props?.onChange}
        onBlur={props?.onBlur}
        error={props?.onError}
        helperText={props?.onError}
        sx={props?.sx}
      >
        {
          status.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))
        }
      </TextField>
    </React.Fragment>
  );
};

export default StatusSelect;