import React, { useState, useEffect } from "react";
import { getFunctionalLanguagesService } from "Services/CountAndDataService";
import { Typography, Box } from "Assets/Material";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
const Styles = {
  selectTitle: {
    top: "12px",
    left: "9px",
    fontSize: "12px",
    fontWeight: "300",
    padding: "0px 4px",
    position: "relative",
    background: "#fff",
    zIndex: 1,
  },
};

const UnapprovedLeadLanguageAutocomplete = (props) => {
  const [functionalLanguages, setFunctionalLanguages] = useState([]);
  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const [functionalLanguage, setFunctionalLanguage] = useState(props?.initialValues);

  const getFunctionalLanguages = async (searchParam) => {
    const response = await getFunctionalLanguagesService(searchParam);
    // console.log("LanguageAutocomplete::getFunctionalLanguages", response);
    if (response) {
      let skills = response.map((item) => ({ value: item.id, label: item.name }));
      setFunctionalLanguages(skills);
    }
  };

  const getDefaultLanguages = async (searchParam) => {
    const response = await getFunctionalLanguagesService(searchParam);
    // console.log("LanguageAutocomplete::getFunctionalLanguages", response);
    if (response) {
      let skills = response.map((item) => ({ value: item.id, label: item.name }));
      setDefaultLanguages(skills);
      setFunctionalLanguages(skills);
    }
  };

  const getSelectValue = (value) => {
    setFunctionalLanguage(value);
    let languageNameArray = value.map((item) => item.label);
    props?.getValue(languageNameArray);
  };

  const selectMessage = (text) => {
    return `Add "${text}"  Your newly added languages will be sent for admin approval`;
  };


  return (
    <>
      <Box>
        {props?.isLabel && (
          <Typography component="span" sx={{ ...Styles.selectTitle, background: props.bg ? "aliceblue" : "#fff" }}>
            {" "}
            Languages{" "}
          </Typography>
        )}
        
          <>
          <Select
              style={{ marginBottom: "0.5rem" }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isMulti
              isClearable={false}
              menuPosition={"fixed"}
              value={functionalLanguage}
              options={functionalLanguages}
              // placeholder=""
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getFunctionalLanguages(e);
                } else {
                  setFunctionalLanguages(defaultLanguages);
                }
              }}
            />
           
          </>
        
      </Box>
    </>
  );
};

export default UnapprovedLeadLanguageAutocomplete;
