const Styles = {
  unauthorisedWrappe: {
    height: '54vh',
    padding: ['10vh 2rem', '10vh 9rem', '10vh 16rem', '10vh 27rem', '10vh 27rem'],
    textAlign: "left"
  },
  unauthorisedText: {
    fontSize: "1rem"
  },
  unauthorisedActiveLink: {
    color: "#607ebb"
  },
  unauthorisedLink: {
    marginTop: "1rem",
    textDecoration: "none",
    color: (theme) => theme.palette.text_data.main,
  }
}

export default Styles;