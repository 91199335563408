const AdminProfileStyles = {
  contentContainer: {
    padding: ["0 0.5rem", "0 0.5rem", "0 2rem", "0 2rem", "0 2rem"],
  },
  contentWrapper: {
    border: "1px solid #4444",
    margin: "0.5rem",
    height: "12rem",
    boxShadow: "2px 2px 4px #00000029",
    borderRadius: "5px",
    padding: "5px",
    overflow: "auto",
  },
  contentHeader: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1.2rem"],
    margin: "0",

    padding: "0",
    color: (theme) => theme.palette.text_heading.main,
  },
  contentDataCompanyName: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1.2rem"],
  },
  contentHeaderIndustries: {
    marginBottom: "1rem",
  },
  contentDataIndustries: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1.2rem"],
    border: "1px solid #4444",
    borderRadius: "30px",
    padding: "5px",
    marginRight: "8px",
    marginBottom: "7px",
    display: "inline-block",
    wordBreak: "break-word",
    boxShadow: "2px 2px 4px #00000029",
  },
  overflowIndustries: {
    overflow: "auto",
  },
  contentDataContactDetailsWrapper: {
    wordBreak: "break-all",
    margin: ["2rem 0","1rem 0","1rem 0","1rem 0","1rem 0"],
  },
  contact_details:{
    display:['grid','block','block','block','block'],
    gridTemplateColumns : ['auto 1fr','none','none','none','none']
  },
  contentDataContactDetails: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1.2rem"],
    marginLeft: "1rem",
  },
  contentDataContactDetailsIcons: {
    float: "left",
    border: "1px solid",
    borderColor: (theme) => theme.palette.primary.main,
    borderRadius: "50px",
    padding: "3px",
    color: (theme) => theme.palette.primary.main,
    position: "relative",
    top: "4px",
  },
  contentDataLocation: {
    fontSize: ["0.8rem", "1rem", "1rem", "1rem", "1.2rem"],
  },
  profileImageEditIcon: {
    position: "absolute",
    bottom: "-0.2rem",
    right: "-0.2rem",
    border: "1px solid",
    borderRadius: "50px",
    padding: "2px",
    fontSize: "1.5rem",
    backgroundColor: "#fff",
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
  buttonWrapper: {
    float: "right",
  },
  adminEditIcon: {
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
  },
  disabled: {
    opacity: "0.3",
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    PointerEvent: "none",
  },
  adminSubmitIcon: {
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  adminCancelIcon: {
    color: (theme) => theme.palette.primary.main,
    width: "1.2rem",
    margin: "0 0 -0.5rem 1rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  adminUpdateLoader: {
    width: "15px !important",
    height: "15px !important",
    margin: "0 0 -0.5rem 1rem",
    float: "right",
  },
  adminEditInput: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    fontSize: "1rem",
    border: "1px solid #4444",
    boxShadow: "2px 2px 4px #00000029",
    "&:focus": {
      outline: "none",
    },
  },
  editAdminError: {
    margin: "0.5rem 0 0 0",
    fontSize: "0.8rem",
    color: (theme) => theme.palette.error.main,
  },
};

export default AdminProfileStyles;
