const ConsultantProfileExecutiveSummaryStyles = {
  profileAboutwrapper: {
    marginTop: "1rem",
    backgroundColor: "#90c3cf29",
    padding: ["1rem", "1rem", "2rem 2.5rem", "2rem 2.5rem", "2rem 2.5rem"],
  },
  consultantTitle: {
    fontWeight: "700",
    paddingLeft: "0.5rem",
    color: (theme) => theme.palette.text_heading.main,
    fontSize: ["1rem", "1rem", "1.2rem"],
    borderLeft: (theme) => [`4px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  executiveSummery: {
    overflow: "auto",
    margin: "0 0 1rem 0",
    wordBreak: "break-all",
    fontSize: ["0.8rem", "0.8rem", "1rem"],
    maxHeight: "10rem",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  edit: {
    float: "right",
    color: (theme) => theme.palette.primary.main,
  },
  content: {
    fontSize: ["10px", "16px", "16px", "16px", "16px"],
  },
  profile: {
    width: "35%",
  },
  shareWith: { width: "35%", paddingX: 0 },
  date: { width: "30%" },
  space: { width: "7%" },
  chipLabel: {
    "& .MuiChip-label": {
      fontSize: ["10px", "16px", "16px", "16px", "16px"],
    },
  },
  summaryEdit: {
    position: "relative",
    cursor: "pointer",
    right: 0,
    top: 0,
    float: "right",
    fontSize: ["1.2rem", "1.2rem", "1.5rem"],
    color: (theme) => theme.palette.primary.main,
  },
  close: {
    position: "relative",
    right: 0,
    top: 0,
    left: "1rem",
    float: "right",
    cursor: "pointer",
    fontSize: ["1.2rem", "1.2rem", "1.5rem"],
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  check: {
    position: "relative",
    right: ["2rem", "2rem", "3rem"],
    float: "right",
    cursor: "pointer",
    fontSize: ["1.2rem", "1.2rem", "1.5rem"],
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  editIconWrapper: {
    margin: "0.5rem",
  },
  executiveSummeryInputWrapper: {
    height: "auto",
    minHeight: "10rem",
    margin: " 0.5rem",
  },
  inputExecutiveSummery: {
    "& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input": {
      fontSize: ["0.8rem", "0.8rem", "1rem"],
    },
  },
  grid2: {
    display: "grid",
    padding: "5px",
    gridTemplateColumns: "1fr 86px",
  },
};

export default ConsultantProfileExecutiveSummaryStyles;
