import React from 'react';
import { H1, Box, CircularProgress } from 'Assets/Material';

const Styles = {
  loaderWrapper: {
    textAlign: "center",
    height: "100vh",
    background: "#fff",
  },
  loaderIcon: {
    marginTop: "5rem"
  },
  loaderText: {
    color: (theme) => theme.palette.text_heading.main
  }
}

const FallbackLoader = () => {
  return (
    <Box sx={Styles.loaderWrapper}>
      <CircularProgress sx={Styles.loaderIcon} />
      <H1 sx={Styles.loaderText}>Loading... </H1>
    </Box>
  )
}

export default FallbackLoader;