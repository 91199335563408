const BillingStyles = {
    primaryButton: {
        border: '1px solid #fff',
        backgroundColor: '#3B3A97',
        color: '#fff',
        transition: '0.2s',
        padding: '0.6rem 2rem !important',
        borderColor: '#3B3A97',
        width: '100%'
    },
    primaryButtonDisabled: {
        border: '1px solid #fff',
        backgroundColor: '#EEE',
        color: '#fff',
        padding: '0.6rem 2rem !important',
        borderColor: '#DDD',
        width: '100%'
    },
    linkButton:{
        color: '#3B3A97',
        fontWeight: 600
    },
    sectionHeading: {
        fontSize: '1.2rem',
        fontWeight: 700
    },
    uploadContainer: {
        paddingLeft: "0",
        paddingTop: "16px !important",
        margin: "8px 0",
    },
    uploadWrapper: {
        padding: "12px 5px",
        textAlign: "center",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        position: "relative",
        height: ["8rem", "8rem", "6rem", "6rem", "6rem"],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.87)",
        },
    },
};

export default BillingStyles;