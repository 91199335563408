import React from "react";
import Styles from "Common/Pagination/Styles";
import { List, ListItem, Box } from "Assets/Material";
import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from "Assets/Icons";

const Pagination = (props) => {

  const previous_disabled = props?.from <= props?.perPage ? true : false;
  const next_disabled = props?.totalDataItem <= props?.to ? true : false;

  return (
    <>
      {
        props?.totalDataItem > 0 &&
        <Box sx={Styles.paginationWrapper}>
          <List sx={Styles.paginationContentWrapper}>
            <ListItem sx={Styles.paginationContentItem}>{props?.from}</ListItem>
            <ListItem sx={Styles.paginationContentItem}> - </ListItem>
            <ListItem sx={Styles.paginationContentItem}> {props?.to < props?.totalDataItem ? props?.to : props?.totalDataItem} </ListItem>
            <ListItem sx={Styles.paginationContentItem}> of </ListItem>
            <ListItem sx={Styles.paginationContentItem}> {props?.totalDataItem} </ListItem>
            <ListItem sx={Styles.paginationContentItem}>
              <KeyboardArrowLeftIcon
                sx={previous_disabled ? Styles.buttonDisabled : Styles.buttonEnabled}
                onClick={!previous_disabled ? props?.previous : undefined}
              />
            </ListItem>
            <ListItem sx={Styles.paginationContentItem}>
              <KeyboardArrowRightIcon
                sx={next_disabled ? Styles.buttonDisabled : Styles.buttonEnabled}
                onClick={!next_disabled && props?.next}
              />
            </ListItem>
          </List>
        </Box>
      }
    </>
  );
}

export default Pagination;