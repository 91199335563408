import React, { useState, useEffect } from "react";
import { FileUploadService, imageUploadService } from "Services/FileUploadService";
import { Grid, Box, Typography, LinearProgress } from "Assets/Material";
import { UploadFileOutlinedIcon, DeleteIcon } from "Assets/Icons";
import Styles from "Common/FileUploader/Styles";
import { useRecoilState, toasterGlobal } from "Atom";
import { BillingStyles } from "Assets/Styles";

const ProofOfPaymentUploader = (props) => {
  const [progress, setProgress] = useState(0);
  const setToaster = useRecoilState(toasterGlobal)[1];
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(props?.initialValues.map((i) => i.asset_id));

  const onFileUpload = async (e) => {

    const ext = e.target.value.split(".").pop();
    if (["jpg", "png", "jpeg", "JPG", 'PNG', 'JPEG', 'pdf'].includes(ext)) {
      const tempFiles = [];
      let id = 0;
      for (const file of e.target.files) {
        file.id = id;
        tempFiles.push(file);
        id += 1;
      }
      setFiles(tempFiles);
      setProgress(20);
      const resp = await imageUploadService(tempFiles[0]);
      const data = resp.data;
      setProgress(100);
      setFileId([...fileId, ...data]);
      props?.getData(data[0].asset_id);
    } else {
      setToaster({ isOpen: true, status: "fail", message: "Supports : .pdf/ .jpg/ .png" });
    }
  };

  const deleteFile = (object) => {
    let newUploadList = [...files];
    let newUploadListId = [...fileId];
    let foundIndex = files.findIndex((x) => x.asset_id === object.asset_id);
    if (foundIndex > -1) {
      newUploadList.splice(foundIndex, 1);
      newUploadListId.splice(foundIndex, 1);
    }

    setFiles(newUploadList);
    setFileId(newUploadListId);
    props?.getData(0);
  };

  useEffect(() => {
    return () => {
      setFiles(null);
    };
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6} sx={BillingStyles.uploadContainer}>
        <Box sx={BillingStyles.uploadWrapper}>
          <Typography component="label" htmlFor="upload_document" sx={Styles.uploadTitle}>
            Upload Proof of payment
          </Typography>

          <Typography htmlFor="upload_document" component="label" sx={Styles.uploadFileIconWrapper}>
            <UploadFileOutlinedIcon sx={Styles.uploadFileIcon} />
          </Typography>

          <Typography htmlFor="upload_document" component="label">
            <Typography component="span" sx={Styles.supportText}>
              Supports : .jpg, .png, .pdf
            </Typography>
          </Typography>

          <input
            style={{ display: "none" }}
            
            id="upload_document"
            type="file"
            accept="*/*"
            onChange={onFileUpload}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} sx={Styles.filesContainer} mt={1}>
        {files && (
          <Box sx={files.length > 0 && Styles.filesWrapper1} style={{ height: "6rem" }}>
            {files &&
              files.map((file, index) => (
                <Box key={index}>
                  <Grid item xs={12}>
                    <Typography sx={Styles.fileName}> {file.name}</Typography>
                  </Grid>
                  <Grid container sx={Styles.progressWrapper}>
                    <Grid item xs={11}>
                      <LinearProgress variant="determinate" value={progress} sx={Styles.progressBar} />
                    </Grid>
                    <Grid item xs={1}>
                      <DeleteIcon sx={Styles.deleteIcon} onClick={() => deleteFile(file)} />
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default ProofOfPaymentUploader;
