const Styles = {
  loaderWrapper: {
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    zIndex: "9999",
    position: "fixed",
    background: "#47474773"
  },
  loader: {
    position: "fixed",
    width: "100px",
    top: "40%",
    left: ["35%", "43%", "45%", "47%", "47%"]
  }
}

export default Styles;