import React, { useState, useEffect } from "react";
import { Box, LABEL, SELECT, P, IMG, SPAN, OPTION } from "Assets/Material";
import CountryCode from "Utils/CountryCode";
import Styles from "Common/SelectCountryCode/Styles";

const SelectCountryCode = (props) => {
  const [flag, setFlag] = useState("");
  const [code, setCode] = useState("");

  const onSelectChange = (country_code) => {
    if (country_code) {
      CountryCode.map((country) => country.phone == country_code && getCountryDetails(country));
    } else {
      setFlag("");
      setCode("");
    }
  };

  const getCountryDetails = (country) => {
    props?.getValue(country);
    setCode(country.phone);
    setFlag(`https://flagpedia.net/data/flags/h80/${country.code.toLowerCase()}.webp`);
  };

  useEffect(() => {
    onSelectChange(props?.initialValue);
  }, [props]);

  return (
    <Box sx={!props?.isRequired ? Styles.countryCodeWrapper : Styles.countryCodeWrapper1}>
      <LABEL htmlFor="CountryCode" sx={!props?.isRequired ? Styles.countryCodeLabel : Styles.countryCodeLabelError}>
        {" "}
        Country Code {props.requiredForm ? "*" : ""}
      </LABEL>

      <P sx={Styles.countryDataWrapper}>
        {flag && <IMG src={flag} sx={Styles.countryFlag} alt="Flag" />}
        <SPAN sx={Styles.countryCode}> {code} </SPAN>
      </P>

      <SELECT
        onChange={(e) => {
          onSelectChange(e.target.value);
        }}
        sx={Styles.countrySelect}
        disabled={props?.disabled}
      >
        <OPTION value sx={Styles.countrySelectOption}>
          {" "}
          Country Code
        </OPTION>
        {CountryCode &&
          CountryCode.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)).map((country, index) => {
            return (
              <OPTION key={index} value={country.phone} sx={Styles.countrySelectOption}>
                {country.label + " (" + country.phone + ")"}
              </OPTION>
            );
          })}
      </SELECT>
      <P sx={Styles.errorCountryCode}>{props?.isRequired && "Country code is required"}</P>
    </Box>
  );
};

export default SelectCountryCode;
