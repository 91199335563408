import { fetchUnapprovedLeadById, useApproveMarvinLeadService, useEditUnapproveLeadService } from "Services/ProjectLeadsServices";
import { authUser, convertTZ, extractDate, extractTimeFromDateString, formatNumberWithCommas, getIdFromHash, getUnapprovedLeadStatusLead, getUserTimeZone } from "Utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classes from "../../ProjectLeads/component/ProjectLeadsDetails/Details.module.css";
import Styles from "../../../Assets/Styles/TalentManagement/TalentManagementStyles"
import { DeleteIcon, EditIcon } from "Assets/Icons";
import { Loader } from "Common";
import EditMarvinLeads from "./EditMarvinLeads";
import { Box, Button, CircularProgress, IconButton, Modal } from "@mui/material";
import { H3 } from "Assets/Material";
import { useRecoilState } from "recoil";
import { toasterGlobal } from "Atom";
import { date } from "yup";

const MarvinLeadsDetails = () => {
    const [dataProjectLeadDetails, setDataProjectLeadDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [isProjectLeadEdit, setIsProjectLeadEdit] = useState(false);
    const [isSwapper, setIsSwapper] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [action, setAction] = useState({
      type: "",
      message: "",
      buttonText: "",
      title: ""
    });
    const [loading, setLoading] = useState(false);
    const setToaster = useRecoilState(toasterGlobal)[1];
    const [meeting_start, setMeetingStart] = useState(null);
    const [meeting_end, setMeetingEnd] = useState(null);
    const [showDeleteRequirementPopup, setShowDeleteRequirementPopup] = useState(false);
    const [responseRequirementId, setResponseRequirementId] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const search = useLocation();
    const temp = search.pathname.split("/");
    const id = temp[temp.length - 1];

    const onSuccesss = (data) => {};

    const onError = (error) => {
      console.log({ error });
    };


  const {mutate: editUnapprovedLead} = useEditUnapproveLeadService(onSuccesss, onError)
  const {mutate: approveLead} = useApproveMarvinLeadService(onSuccesss, onError)

  const isClientLead = authUser().user_type_id !== 3;

  const editPopup = (activeStep) => {

    setActiveStep(activeStep);
    setIsProjectLeadEdit(true);
  };

  const closeProjectLead = () => {
    setActiveStep(0);
    setIsProjectLeadEdit(false);
    fetchLeadById(getIdFromHash(id));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchLeadById = async (data, openFormOnDataFetch) => {
    setIsLoading(true);
    if (typeof data === "undefined") {
      data = getIdFromHash(id);
    }
    const response = await fetchUnapprovedLeadById(data);
    if (response) {
      response.consultant_requirements = response.consultant_requirements ? JSON.parse(response.consultant_requirements) : []
      response.industries = response.industries ? JSON.parse(response.industries) : []
      response.capabilities = response.capabilities ? JSON.parse(response.capabilities) : []
      setDataProjectLeadDetails(response);
      setIsLoading(false);
    }
  };


  const add_edit_unapproved_leads_params = {
    onOpen: isProjectLeadEdit,
    onClose: () => closeProjectLead(),
    isEdit: isProjectLeadEdit,
    onSwapperClose: () => setIsSwapper(false),
    dataEdit: dataProjectLeadDetails,
    activeStep: activeStep,
    handleNext: () => handleNext(),
    handleBack: () => handleBack(),
    isClientLead: isClientLead,
  };

  useEffect(() => {
    document.title = "Project Lead Detail";
    fetchLeadById(getIdFromHash(id));
  }, []);


  const handleApproveLeadClick = () => {
    setShowConfirmModal(true)
    setAction({type: "approve", message: "Are you sure you want to approve this lead?", buttonText: "Approve", title: "Approve Lead"})
  }

  const handleRejectLeadClick = () => {
    setShowConfirmModal(true)
    setAction({type: "reject", message: "Are you sure you want to archive this lead?", buttonText: "Archive", title: "Archive Lead"})
  }

  const handleUndoRejectLeadClick = () => {
    setShowConfirmModal(true)
    setAction({type: "undo_reject", message: "Are you sure you want to undo archive this lead?", buttonText: "Undo archive", title: "Undo archive Lead"})
  }

  const handleApproveLead = async () => {
    setLoading(true)
    let data = {...dataProjectLeadDetails, modified_by: authUser().user_id, admin_name: authUser().name};
    
    approveLead(data, {
      onSuccess: (data) => {
        
        setLoading(false)
        setShowConfirmModal(false)
        setToaster({ isOpen: true, status: "success", message: "Lead approveed sucessfully" });
        
        let _data = {...dataProjectLeadDetails, status: 2};
        editUnapprovedLead(_data, {
          onSuccess: (data) => {
              fetchLeadById(getIdFromHash(id));
            },
            onError: () => {
            }
          })
      },
      onError: () => {
          setShowConfirmModal(false)
          setLoading(false)
          setToaster({ isOpen: true, status: "error", message: "Something went wrong" });
      }
    })

  }

  const handleUndoRejectLead = async () => {
    setLoading(true)
    let data = {...dataProjectLeadDetails, status: 1};

    editUnapprovedLead(data, {
      onSuccess: (data) => {
          fetchLeadById(getIdFromHash(id));
          setLoading(false)
          setShowConfirmModal(false)
          setToaster({ isOpen: true, status: "success", message: "Achived undone successfully" });
      },
      onError: () => {
          setShowConfirmModal(false)
          setLoading(false)
          setToaster({ isOpen: true, status: "error", message: "Something went wrong" });
      }
    })
  }

  const handleRejectLead = async () => {
    setLoading(true)

    let data = {...dataProjectLeadDetails, status: 3};

    editUnapprovedLead(data, {
      onSuccess: (data) => {
          fetchLeadById(getIdFromHash(id));
          setLoading(false)
          setShowConfirmModal(false)
          setToaster({ isOpen: true, status: "success", message: "Lead archived successfully" });
      },
      onError: () => {
          setShowConfirmModal(false)
          setLoading(false)
          setToaster({ isOpen: true, status: "error", message: "Something went wrong" });
      }
    })

  }

  const handleDeleteRequirement = async (index) => {
     //remove the requirement from the list using the index
       setDeleteLoading(true)
      let _data = {...dataProjectLeadDetails};
      let requirements = _data.consultant_requirements;
      requirements.splice(index, 1);
      _data.consultant_requirements = requirements;

       
      editUnapprovedLead(_data, {
        onSuccess: (data) => {
            setDeleteLoading(false)
            setShowDeleteRequirementPopup(false);
            setToaster({ isOpen: true, status: "success", message: "Requirement deleted successfully" });
        },
        onError: () => {
            setDeleteLoading(false)
        }
    })
  }

  useEffect(() => {
    /**
     * Convert the meeting start and end date time to each admin timezone user timezone
     */
    if (dataProjectLeadDetails && dataProjectLeadDetails?.meeting_start_datetime) {
      const date = new Date(dataProjectLeadDetails?.meeting_start_datetime);
      const {timeZone} = getUserTimeZone()
      let adjustedForTimeZone = convertTZ(date, timeZone);

      let time = extractTimeFromDateString(adjustedForTimeZone)
      let _date = extractDate(adjustedForTimeZone);

      setMeetingStart(`${_date} ${time}`)
    }

    if (dataProjectLeadDetails && dataProjectLeadDetails?.meeting_end_datetime) {
      const date = new Date(dataProjectLeadDetails?.meeting_end_datetime);
      const {timeZone} = getUserTimeZone()
      let adjustedForTimeZone = convertTZ(date, timeZone);

      let time = extractTimeFromDateString(adjustedForTimeZone)
      let _date = extractDate(adjustedForTimeZone);

      setMeetingEnd(`${_date} ${time}`)
    }
    
  }, [dataProjectLeadDetails])

  const ConfirmPopup = ({type}) => {

    return (
      <Modal
        open={true} 
        >
        <Box sx={Styles.formWrapper}>
          <div 
            style={{
              textAlign: 'center', 
              padding: '2rem', 
              display: 'flex', 
              flexDirection: 'column',
              gap: '1rem'
            }}>
            <H3 style={{fontSize: '1.8rem'}}>{action.title}</H3>
            <div>{action.message}</div>
            <div style={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
              {
                loading 
                ? <CircularProgress />
                : (
                  <>
                    <Button
                      type="button"
                      sx={{ padding: "6px 12px", minWidth: "auto" }}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        if (type === "approve") {
                          handleApproveLead()
                        }

                        if (type === "reject") {
                          handleRejectLead()
                        }

                        if (type === "undo_reject") {
                          handleUndoRejectLead()
                        }

                      }}
                      disabled={loading}
                    >
                      {action.buttonText}
                    </Button>
                    <Button
                      type="button"
                      sx={{ padding: "6px 12px", minWidth: "auto" }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setShowConfirmModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </>
                )
              }
              
            </div>
            
            
          </div>
          
        </Box>
        
      </Modal>
      
    )
  } 


    return (
        <>
        {isLoading ? (
        <Loader />
      ) : (
        <div>
          {showConfirmModal && <ConfirmPopup type={action.type} />}
         <div className={classes.basicDiv}>
            <div className={classes.headerDetails}>
              <h2>
                <span>Basic Details</span>
              </h2>
              <div className={classes.headerEnd}>
              <div className={classes.headerButtonDiv}>
                 <button  
                  style={{background: (dataProjectLeadDetails.status == 2 || dataProjectLeadDetails.status == 3) ? 'rgba(0, 0, 0, 0.3)' : ''}} 
                  disabled={dataProjectLeadDetails.status == 2 || dataProjectLeadDetails.status == 3} 
                  onClick={() => handleApproveLeadClick()} 
                  className={classes.headerButtonApprove}>Approve
                </button>

                 {/* {dataProjectLeadDetails?.status == 3 ? 
                    <button onClick={() => handleUndoRejectLeadClick()} className={classes.headerButtonReject}>Undo Archive</button> : 
                    <button 
                     style={{background: (dataProjectLeadDetails.status == 2) ? 'rgba(255, 0, 0, 0.3)' : ''}} 
                     onClick={() => handleRejectLeadClick()} 
                     disabled={dataProjectLeadDetails.status === 2} 
                     className={classes.headerButtonReject}>Archive
                    </button>
                  } */}
              </div>
                
                <span onClick={() => editPopup(0)}>
                  <EditIcon />
                </span> 
              </div>
            </div>
            <div className={classes.grid3}>
              <div>
                <p className={classes.bold}>Name</p>
                <p>{dataProjectLeadDetails.name}</p>
              </div>
              <div>
                <p className={classes.bold}>Email ID</p>
                <p>{dataProjectLeadDetails.email}</p>
              </div>
              <div>
                <p className={classes.bold}>Contact</p>
                <p>{`${dataProjectLeadDetails.country_code} ${dataProjectLeadDetails.mobile}`}</p>
              </div>
              <div>
                <p className={classes.bold}>Organization</p>
                <p>{dataProjectLeadDetails.organization}</p>
              </div>
              <div>
                <p className={classes.bold}>Industry Focus</p>
                {dataProjectLeadDetails.industries !== "" &&
                  dataProjectLeadDetails.industries.map(
                    (ind, kk) => {
                      if (ind !== null) {
                        return (
                          <span key={kk} className={classes.capabilities}>
                            {ind}
                          </span>
                        );
                      }
                    }
                  )}
                {/* <p>IT </p> */}
              </div>
              {/* <div>
                <p className={classes.bold}>Website</p>
                <p>{dataProjectLeadDetails.website}</p>
              </div> */}
              {/* <div>
                <p className={classes.bold}>Lead Status</p>
                <p>{getUnapprovedLeadStatusLead(dataProjectLeadDetails)}</p>
                 
              </div> */}

              <div>
                <p className={classes.bold}>Budget Min</p>
                <p>{dataProjectLeadDetails.budget_min && formatNumberWithCommas(dataProjectLeadDetails.budget_min)}</p>
                 
              </div>

              <div>
                <p className={classes.bold}>Budget Max</p>
                <p>{dataProjectLeadDetails.budget_max && formatNumberWithCommas(dataProjectLeadDetails.budget_max)}</p>
                 
              </div>

              <div>
                {dataProjectLeadDetails && dataProjectLeadDetails?.feedback ? 
                  <div>
                    <p className={classes.bold}>Client Feedback</p>
                    <p>{dataProjectLeadDetails.feedback}</p>
                  </div>  
                  :
                  ""
              }
              </div>

              <div>
                {dataProjectLeadDetails && dataProjectLeadDetails?.meeting_end_datetime && new Date(dataProjectLeadDetails?.meeting_end_datetime) > new Date() ? 
                  <>
                    <p className={classes.bold}>Meeting Details</p>
                    <p>start time: {meeting_start}</p>
                    <p>end time: {meeting_end}</p>
                    <p><a href={dataProjectLeadDetails.meeting_link}>join meeting</a></p>
                  </>
                :
                "" 
              }
              </div>

             

            </div>
          </div>

          {/* Project Details */}
          <div className={classes.prjectDiv}>
            <div className={classes.headerDetails}>
              <h2>
                <span>Project Details</span>
              </h2>
              <div>
                <span onClick={() => editPopup(1)}>
                  <EditIcon />
                </span>
              </div>
            </div>

            <div className={classes.grid}>
            <p className={classes.bold} style={{marginLeft: "15px"}}>Prompt</p>
              <p style={{marginLeft: "15px"}}>{dataProjectLeadDetails.prompts}</p>
              <br/>

              <p className={classes.bold} style={{marginLeft: "15px"}}>Project Brief</p>
              <br/>
              <div
               style={{marginLeft: "15px"}}
                dangerouslySetInnerHTML={{
                  __html: dataProjectLeadDetails?.problem_description,
                }}
              ></div>
            </div>
            <div className={classes.grid3}>
              <div>
                <p className={classes.bold}>Start Date</p>
                <p>
                  {/* {dateFormWithMonthName(
                    dataProjectLeadDetails.estimated_start_date
                  )} */}
                </p>
              </div>
              <div>
                <p className={classes.bold}>Duration</p>
                {dataProjectLeadDetails.duration !== null &&
                  dataProjectLeadDetails.duration_type !== null && (
                    <p>{`${dataProjectLeadDetails.duration} ${dataProjectLeadDetails.duration_type}`}</p>
                  )}
              </div>

              <div>
                <p className={classes.bold}>Location</p>
                <p>
                  {dataProjectLeadDetails.location}
                </p>
              </div>
            </div>
          </div>

          {/* Consultant Requirments */}
          <div className={classes.basicDiv}>
            <div className={classes.headerDetails}>
              <h2>
                <span>Consultant Requirements</span>
              </h2>
              <div>
                <span onClick={() => editPopup(2)}>
                  <EditIcon />
                </span>
              </div>
            </div>
            {dataProjectLeadDetails.consultant_requirements &&
              dataProjectLeadDetails.consultant_requirements.map((val, k) => {
                if (val.id !== null) {
                  let working_remotely = "";
                  if (val.remote_working === 1) {
                    working_remotely = "Remote Only";
                  } else if (val.remote_working === 0) {
                    working_remotely = "On Site Possible";
                  } else {
                    working_remotely = val.remote_working;
                  }

                  return (
                    <div key={k} className={classes.consultantR}>
                      <div className={classes.grid}>
                        <p className={classes.bold}>Title</p>
                        <p
                          style={{
                            cursor: "pointer",
                            color: "#607EBB",
                          }}
                        >
                          {val?.requirement_title}
                        </p>
                      </div>
                      <div className={classes.grid}>
                        <p className={classes.bold}>Capabilities</p>
                        <div>
                          {val.capabilities !== "" &&
                            val.capabilities.map((cap, kk) => {
                              if (cap !== null) {
                                return (
                                  <span
                                    key={kk}
                                    className={classes.capabilities}
                                  >
                                    {cap}
                                  </span>
                                );
                              }
                            })}
                          {/* <span>CSS</span> */}
                        </div>
                      </div>
                      <div className={classes.grid3}>
                        <div>
                          <p className={classes.bold}>Requirement Status</p>
                          <p>{val?.status == 1 ? "Open for Application" : "Closed for Application"}</p>
                        </div>
                        <div>
                          <p className={classes.bold}>No. of Consultants</p>
                          <p>{val.number_of_consultants}</p>
                        </div>
                        <div>
                          <p className={classes.bold}>Years of Experience</p>
                          <p>{val.years_of_experience}</p>
                        </div>
                        
                        <div>
                          <p className={classes.bold}>Engagement Preference</p>
                          <p>{val.engagement_preference}</p>
                        </div>
                        {/* <div>
                          <p className={classes.bold}>Compensation range</p>
                          {val.compensation_low !== null &&
                            val.compensation_high !== null && (
                              <p>{`$ ${val.compensation_low} to $ ${val.compensation_high}`}</p>
                            )}
                        </div> */}
                        

                        <div>
                        <p className={classes.bold}>Languages</p>
                        <div>
                          {val.languages &&
                            val.languages.map((lang, kk) => {
                              if (lang !== null) {
                                return (
                                  <span
                                    key={kk}
                                    className={classes.capabilities}
                                  >
                                    {lang}
                                  </span>
                                );
                              }
                            })}

                        <IconButton onClick={() => {
                          setShowDeleteRequirementPopup(true);
                          setResponseRequirementId(k);
                        }} style={{position: "absolute", right: 100}}>
                          <DeleteIcon />
                        </IconButton>
                          {/* <span>CSS</span> */}

                          {showDeleteRequirementPopup && (
                        <Modal
                         open={true} 
                        >
                        <Box sx={Styles.formWrapper}>
                          <div 
                            style={{
                              textAlign: 'center', 
                              padding: '2rem', 
                              display: 'flex', 
                              flexDirection: 'column',
                              gap: '1rem'
                            }}>
                            <H3 style={{fontSize: '1.8rem'}}>Delete Requirement</H3>
                            <div>Are you sure you want to delete this requirement?</div>
                            <div style={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
                              {
                                deleteLoading 
                                ? <CircularProgress />
                                : (
                                  <>
                                    <Button
                                      type="button"
                                      sx={{ padding: "6px 12px", minWidth: "auto" }}
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        handleDeleteRequirement(responseRequirementId)
                                      }}
                                      disabled={deleteLoading}
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      type="button"
                                      sx={{ padding: "6px 12px", minWidth: "auto" }}
                                      size="small"
                                      variant="outlined"
                                      onClick={() => {
                                        setShowDeleteRequirementPopup(false);
                                      }}
                                    >
                                      Close
                                    </Button>
                                  </>
                                )
                              } 
                              
                            </div>
                            
                            
                          </div>
                          
                        </Box>
                        
                      </Modal>
                      )}
                        </div>
                      </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className={classes.consultantR}>
                      No Consultant Requirements
                    </div>
                  );
                }
              })}
          </div>
        </div>
        )}

         <EditMarvinLeads {...add_edit_unapproved_leads_params} />
        </>

    )
};

export default MarvinLeadsDetails;