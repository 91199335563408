import React from 'react'
import Styles from "Common/ActionMenu/Style";
import { MoreVert } from "Assets/Icons";
import { IconButton, Menu, MenuItem } from "Assets/Material";

const ActionMenu = (props) => {
  return (
    <>
      <IconButton onClick={props?.onClickMoreVertIcon} >
        <MoreVert sx={Styles.actionButtonIcon} />
      </IconButton>

      <Menu
        keepMounted
        open={!!props?.onOpen}
        onClose={props?.onClose}
        anchorEl={props?.anchorEl}
        sx={Styles.actionMenuWrapper}
      >
        {props?.actionIcons.map((icon, index) => <MenuItem key={index}> {icon} </MenuItem>)}
      </Menu>
    </>
  )
}

export default ActionMenu;