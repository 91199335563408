import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { usertype, authUser } from "Utils";
import { useHistory } from "react-router-dom";
import { useRecoilState, isLoginGlobal } from "Atom";
import { loginUserService } from "Services/AuthService";
import { UserProfile, VisibilityIcon, PasswordIcon, VisibilityOffIcon, CloseIcon } from "Assets/Icons";
import { Modal, Box, Button, IMG, TextField, CircularProgress, P, InputAdornment, IconButton } from 'Assets/Material';
import Styles from "Common/Header/Styles";
import Cookies from 'js-cookie';

const PasswordPopup = (props) => {
  const history = useHistory();

  const [loginError, setLoginError] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const setIsLoginGlobalState = useRecoilState(isLoginGlobal)[1];

  const loginAuth = async (values) => {

    setIsFormSubmit(true);

    values.email = authUser()?.email;
    values.user_type_id = +props?.userTypeId;

    let response = await loginUserService(values);

    if (response?.data && response?.data?.data && response?.data?.data?.token) {
      Cookies.set("token", response?.data?.data?.token);
      history.push("/");
      setIsLoginGlobalState(true);
      setIsFormSubmit(false);
      props.onClose();
    } else {
      setLoginError(response?.data?.message);
      setIsFormSubmit(false);
    }
  }

  const validateForm = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Password is required.";
      setLoginError("");
    }
    return errors;
  }

  useEffect(() => {
    return () => {
      setLoginError("")
    }
  }, [])

  return (
    <>
      <Modal open={props?.isOpen} onClose={props?.onClose}>
        <Box sx={Styles.confirmWrapper}>
          <CloseIcon sx={Styles.closeIcon} onClick={props?.onClose} />
          <Box sx={Styles.loginProfileImageWrapper}>
            <IMG src={UserProfile} alt={UserProfile} sx={Styles.loginProfileImage} />
          </Box>
          <P sx={{ fontWeight: "700" }}> {authUser()?.email} </P>
          <P> Switch user type to {usertype(+props?.userTypeId)} </P>
          <Formik
            initialValues={{ password: "" }}
            validate={values => validateForm(values)}
            onSubmit={values => loginAuth(values)}
          >
            {
              ({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <TextField
                    fullWidth
                    name="password"
                    type={!isShowPassword ? "password" : "text"}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    placeholder="Password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={Styles.inputField}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"> <PasswordIcon /> </InputAdornment>,
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsShowPassword(prev => !prev)}
                          edge="end"
                        >
                          {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={errors.password && touched.password && errors.password}
                    helperText={errors.password && touched.password && errors.password}
                  />
                  <P sx={Styles.formError}> {loginError} </P>

                  <Button
                    type="submit"
                    disabled={isFormSubmit}
                    sx={Styles.authButton}
                  >
                    {isFormSubmit ? <CircularProgress sx={Styles.loader} /> : "Login"}
                  </Button>
                </form>
              )
            }
          </Formik>
        </Box>
      </Modal>
    </>
  )
}

export default PasswordPopup;