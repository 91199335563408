import React, { useState } from "react";
import Styles from "Common/Header/Styles";
import { isLogin } from "Utils";
import { AppBar, Toolbar, useMediaQuery, useTheme } from "Assets/Material";
import { useRecoilState, isLoginGlobal, toasterGlobal, confirmGlobal, approvalGlobal, isLoaderGlobal } from "Atom";
import { MobileMenu, DesktopMenu, Brand, Loader, Toaster, Confirm, ApprovalConfirm } from "Common";
import PasswordPopup from "Common/Header/PasswordPopup";
import RestPasswordPopup from "Common/Header/ResetPasswordPopup"
import ChatWindows from "Modules/Conversation/ChatWindows";

const Header = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const toasterGlobalState = useRecoilState(toasterGlobal)[0];
  const confirmGlobalState = useRecoilState(confirmGlobal)[0];
  const approvalGlobalState = useRecoilState(approvalGlobal)[0];
  const isLoginGlobalState = useRecoilState(isLoginGlobal)[0];
  const isLoaderGlobalState = useRecoilState(isLoaderGlobal)[0];
  const [isPasswordPopup, setIsPasswordPopup] = useState(false);
  const [isResetPasswordPopup, setIsResetPasswordPopup] = useState(false);
  const [userTypeId, setUserTypeId] = useState({});

  return (
    <>
      <AppBar position="fixed" sx={Styles.appbar}>
        <Toolbar sx={Styles.toolbar}>
          <Brand />
          {
            !md && (isLoginGlobalState || isLogin()) &&
            <MobileMenu
              getUserTypeId={(userTypeId) => setUserTypeId(userTypeId)}
              setIsPasswordPopup={() => setIsPasswordPopup(true)}
              setIsResetPasswordPopup={() => setIsResetPasswordPopup(true)}
            />
          }
          {
            md && (isLoginGlobalState || isLogin()) &&
            <DesktopMenu
              getUserTypeId={(userTypeId) => setUserTypeId(userTypeId)}
              setIsPasswordPopup={() => setIsPasswordPopup(true)}
              setIsResetPasswordPopup={() => setIsResetPasswordPopup(true)}
            />
          }
          {toasterGlobalState.isOpen && <Toaster />}
          {confirmGlobalState.isOpen && <Confirm />}
          {approvalGlobalState.isOpen && <ApprovalConfirm />}
          {isLoaderGlobalState && <Loader />}
          <PasswordPopup
            isOpen={isPasswordPopup}
            onClose={() => setIsPasswordPopup(false)}
            userTypeId={userTypeId}
          />
          <RestPasswordPopup
            isOpen={isResetPasswordPopup}
            onClose={() => setIsResetPasswordPopup(false)}
            userTypeId={userTypeId}
          />
        </Toolbar>
      </AppBar>
      <ChatWindows />
    </>
  );
};

export default Header;
