const PaginationStyles = {
  paginationWrapper: {
    backgroundColor: "#fff",
    float: "right",
    marginTop: "1rem",
    padding: "0",
  },
  paginationContentWrapper: {
    margin: "0",
    padding: "0",
    fontSize: ["0.9rem", "0.9rem", "1rem", "1rem", "1rem"],
    background: "#fff",
    display: "flex",
    alignItems: "center",
    fontWeight:"700"
  },
  paginationContentItem: {
    display: "inline",
    padding: "0 4px"
  },
  buttonEnabled: {
    color: (theme) => theme.palette.enable,
    fontSize: ["1.8rem", "1.8rem", "2rem", "2rem", "2rem"],
    marginTop: "3px",
    cursor: "pointer",
  },
  buttonDisabled: {
    color: (theme) => theme.palette.disable,
    fontSize: ["1.8rem", "1.8rem", "2rem", "2rem", "2rem"],
    marginTop: "3px",
    pointerEvents: "none",
  }
}

export default PaginationStyles;