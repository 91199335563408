import React, { useState, useEffect } from "react";
import { getFunctionalLanguagesService } from "Services/CountAndDataService";
import { Typography, Box } from "Assets/Material";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
const Styles = {
  selectTitle: {
    top: "12px",
    left: "9px",
    fontSize: "12px",
    fontWeight: "300",
    padding: "0px 4px",
    position: "relative",
    background: "#fff",
    zIndex: 1,
  },
};

const LanguageAutocomplete = (props) => {
  const [functionalLanguages, setFunctionalLanguages] = useState([]);
  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const [functionalLanguage, setFunctionalLanguage] = useState(props?.initialValues);

  const getFunctionalLanguages = async (searchParam) => {
    const response = await getFunctionalLanguagesService(searchParam);
    // console.log("LanguageAutocomplete::getFunctionalLanguages", response);
    if (response) {
      let skills = response.map((item) => {
          if (item.id !== null || item.id !== undefined || item.id !== "") {
            return { value: item.id, label: item.name }
          }
      });
      setFunctionalLanguages(skills);
    }
  };

  const getDefaultLanguages = async (searchParam) => {
    const response = await getFunctionalLanguagesService(searchParam);
    // console.log("LanguageAutocomplete::getFunctionalLanguages", response);
    if (response) {
      let skills = response.map((item) => ({ value: item.id, label: item.name }));
      setDefaultLanguages(skills);
      setFunctionalLanguages(skills);
    }
  };

  const getSelectValue = (value) => {
    const languages = [];
    const languagesAdd = [];
    const unapproved_skills = [];
    const unapproved_skillss = [];
    value.map((i) => (i.__isNew__ ? unapproved_skills.push(i.label) : languages.push(i.value)));
    value.map((i) => (i.__isNew__ ? unapproved_skillss.push(i.label) : languagesAdd.push(i)));

    setFunctionalLanguage(value);
    props?.getValue({ languages, unapproved_skills, languagesAdd });
  };

  const selectMessage = (text) => {
    return `Add "${text}"  Your newly added languages will be sent for admin approval`;
  };

  // useEffect(() => {
  //   getDefaultLanguages('');
  // }, [props]);

  return (
    <>
      <Box>
        {props?.isLabel && (
          <Typography component="span" sx={{ ...Styles.selectTitle, background: props.bg ? "aliceblue" : "#fff" }}>
            {" "}
            Languages{" "}
          </Typography>
        )}
        {props.isSelectOnly ? (
          <>
            <Select
              style={{ marginBottom: "0.5rem" }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isMulti
              isClearable={false}
              menuPosition={"fixed"}
              value={functionalLanguage}
              options={functionalLanguages}
              // placeholder=""
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getFunctionalLanguages(e);
                } else {
                  setFunctionalLanguages([]);
                }
              }}
            />
          </>
        ) : (
          <>
          <Select
              style={{ marginBottom: "0.5rem" }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isMulti
              isClearable={false}
              menuPosition={"fixed"}
              value={functionalLanguage}
              options={functionalLanguages}
              // placeholder=""
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getFunctionalLanguages(e);
                } else {
                  setFunctionalLanguages(defaultLanguages);
                }
              }}
            />
            {/* <CreatableSelect
              style={{ marginBottom: "0.5rem" }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isMulti
              isClearable={false}
              menuPosition={"fixed"}
              value={functionalLanguage}
              options={functionalLanguages}
              // placeholder=""
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getFunctionalLanguages(e);
                } else {
                  setFunctionalLanguages([]);
                }
              }}
            /> */}
          </>
        )}

        
      </Box>
    </>
  );
};

export default LanguageAutocomplete;
