import React, { useState, useEffect, useRef } from "react";
import { TextField, Autocomplete, LI, Typography, Box } from "Assets/Material";
import { getCityService } from "Services/AdminProfileServices";
import CountryCode from "Utils/CountryCode";
import AsyncSelect from "react-select";

const CityAutocomplete = (props) => {

  const [value, setValue] = useState(null);
  const [dataList, setDataList] = useState([]);



  const getCities = async (searchParam) => {
    const response = await getCityService(searchParam);
    if (response) {
      let cities = response.data.map((item) => ({value: `${item.country}, ${item.name}`, label: `${item.country}, ${item.name}`}))
      setDataList(cities)
    }
  }


  const Styles = {
    selectTitle: {
      top: "-4px",
      left: "9px",
      fontSize: "12px",
      fontWeight: "300",
      padding: "0px 4px",
      position: "relative",
      background: "#fff",
      zIndex: 1,
    },
  };
  

  return (
    <>
      <Typography component="span" sx={Styles.selectTitle}>
        {props.label}
      </Typography>
      <AsyncSelect
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), control: (baseStyles) => ({...baseStyles, marginTop: "-15px",...props.styles}) }}
        menuPosition="fixed"
        placeholder={props.placeholder}
        defaultValue={{label: props.initialValues, value: props.initialValues}}
        options={dataList}
        onInputChange={(e) => {
          if (e.length >= 1) {
            getCities(e)
          } else {
            setDataList([])
          }
        }}
        onChange={(value, _) => {
          setValue(value);
          props.getValue(value.value)
        }}
      />
    </>
  )

};

export default CityAutocomplete;