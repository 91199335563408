import React, { useState, useEffect } from "react";
import { Typography, Box } from "Assets/Material";
import { getIndustriesService } from "Services/CountAndDataService";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const Styles = {
  selectTitle: {
    top: "9px",
    left: "9px",
    fontSize: "12px",
    fontWeight: "300",
    padding: "0px 4px",
    position: "relative",
    background: "#fff",
    zIndex: 1,
  },
};

const IndustryAutocomplete = (props) => {
  const [industriesData, setIndustriesData] = useState([]);
  const [industriesValue, setIndustriesValue] = useState(props?.initialValues);
  const getIndustries = async (searchParam) => {
    const response = await getIndustriesService(searchParam);
    if (response) {
      let skills = response.map((item) => ({ value: item.id, label: item.name }));
      setIndustriesData(skills);
    }
  };

  const getSelectValue = (value) => {
    const industries = [];
    const unapproved_industries = [];
    value.map((i) => (i.__isNew__ ? unapproved_industries.push(i.label) : industries.push(i.value)));
    setIndustriesValue(value);
    props?.getValue({ industries, unapproved_industries });
  };

  const selectMessage = (text) => {
    return `Add "${text}"  Your newly added industries will be sent for admin approval`;
  };

  useEffect(() => {}, []);

  return (
    <>
      <Box>
        <Typography component="span" sx={Styles.selectTitle}>
          Industries
        </Typography>
        {props.isSelectOnly ? (
          <>
            <Select
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPosition={"fixed"}
              isClearable={false}
              isMulti
              value={props.create === "createProject" ? props?.initialValues : industriesValue}
              options={industriesData}
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getIndustries(e);
                } else {
                  setIndustriesData([]);
                }
              }}
            />
          </>
        ) : (
          <>
            <CreatableSelect
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPosition={"fixed"}
              isClearable={false}
              isMulti
              value={props.create === "createProject" ? props?.initialValues : industriesValue}
              options={industriesData}
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getIndustries(e);
                } else {
                  setIndustriesData([]);
                }
              }}
            />
          </>
        )}
        {/* <CreatableSelect
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPosition={"fixed"}
          isClearable={false}
          isMulti
          value={props.create === "createProject" ? props?.initialValues : industriesValue}
          options={industriesData}
          placeholder="Input few characters"
          formatCreateLabel={(userInput) => selectMessage(userInput)}
          onChange={(newValue, _) => getSelectValue(newValue)}
          onInputChange={(e) => {
            if (e.length >= 1) {
              getIndustries(e);
            } else {
              setIndustriesData([]);
            }
          }}
        /> */}
      </Box>
    </>
  );
};

export default IndustryAutocomplete;
