import { Avatar, Box, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CheckCircleOutlinedIcon } from 'Assets/Icons';
import { FetchSignURLService } from 'Services/FileDownloadService';
import formatDistance from 'date-fns/formatDistance';
import React from 'react';
const ChatMessage = (props) => {
    const {msg, lastSeenTime, is_sender } = props;
    const sender_role = props.msg.sender_user_type_id === 3 ? 'Admin' : 'Consultant';
    let messageTitle = '', message_text = msg.body, sender_name = props.sender_name;
    const isSeen = lastSeenTime !== null && (new Date(lastSeenTime) > new Date(msg.createdAt));
    if (msg.type === 'NORMAL' || !msg.type){

    }else if(msg.type === 'APPLIED'){
        messageTitle = 'Successfully Applied'
        message_text = 'at Strategy Connect';
        sender_name = 'Auto Response';
    } else if (msg.type === 'ACKNOWLEDGED'){
        messageTitle = 'Thanks for Applying';
        message_text = 'We will review your application & get back to you...';
        sender_name = 'Auto Response';
    } else {
        messageTitle = msg.type;
        sender_name = 'Auto Response';
    }
    return (
        <Box sx={{
            background: props.is_sender ? '#E9E9F7' : '#E5E6EB',
            borderRadius: 2,
            padding: 2,
            maxWidth: '80%',
            position: 'relative'
        }} >
            <Grid wrap="nowrap" container spacing={1} sx={{ alignItems: 'center' }}>
                <Grid item xs={"auto"}>
                    <Avatar sx={{ bgcolor: '#AAA' }}>SH</Avatar>
                </Grid>
                <Grid item xs>
                    <Box>
                        <Typography

                            sx={{
                                fontWeight: 'bold',
                                fontSize: '0.8rem',
                                lineHeight: 1.5
                            }}
                        >{sender_name}</Typography>
                        <Typography
                            sx={{
                                fontSize: '0.75rem',
                                color: '#4B5163',
                                lineHeight: 1
                            }}
                        >
                            {formatDistance(new Date(props.msg.createdAt), new Date())} ago
                        </Typography>
                    </Box>
                    <Box>


                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                <Box>
                    {messageTitle ? <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#1F2237' }}>{messageTitle}</Typography> : ''}
                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 400, color: '#6B7180' }}>{message_text}</Typography>
                    {/* <Box sx={{ borderTop: '1px solid #D1D3DB', paddingTop: '0.5rem', marginTop: '0.5rem', textAlign: 'center' }}>
                        <a style={{ fontSize: '0.7rem', fontWeight: 600, color: '#3B3A97' }}>View Application</a>
                    </Box> */}
                </Box>
                {/* {
                    (props.msg.type === 'NORMAL' || !props.msg.type)
                        ? (
                            <Box >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem',
                                        color: '#4B5163'
                                    }}
                                >{props.msg.body}</Typography>
                            </Box>
                        ) : ''
                }
                {
                    props.msg.type === 'APPLIED'
                        ? (
                            <Box>
                                <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#1F2237' }}>Successfully Applied</Typography>
                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 400, color: '#6B7180' }}>at Strategy Connect</Typography>
                                <Box sx={{ borderTop: '1px solid #D1D3DB', paddingTop: '0.5rem', marginTop: '0.5rem', textAlign: 'center' }}>
                                    <a style={{ fontSize: '0.7rem', fontWeight: 600, color: '#3B3A97' }}>View Application</a>
                                </Box>
                            </Box>
                        ) : ''
                }
                {
                    props.msg.type === 'ACKNOWLEDGED'
                        ? (
                            <Box>
                                <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#1F2237' }}>Thanks for Applying</Typography>
                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 400, color: '#6B7180' }}>We will review your application & get back to you...</Typography>

                            </Box>
                        ) : ''
                }
                {
                    props.msg.type === 'ACKNOWLEDGED'
                        ? (
                            <Box>
                                <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#1F2237' }}>Thanks for Applying</Typography>
                                <Typography sx={{ fontSize: '0.8rem', fontWeight: 400, color: '#6B7180' }}>We will review your application & get back to you...</Typography>

                            </Box>
                        ) : ''
                } */}
            </Box>
            <Box>{(props.msg.files || []).map((f, k) => {

                return (
                    <Box key={f.id}>
                        <a
                            style={{ fontSize: '0.675rem', lineHeight: 1.2, display: 'block' }}
                            onClick={async () => {
                                let signed_url_resp = await FetchSignURLService(f.id);
                                if (signed_url_resp.data.status === 200) {
                                    window.open(signed_url_resp.data.signed_url, "_blank");
                                } else {
                                    alert('Something went wrong while fetching secure resource url');
                                }
                            }}>{f.name}</a>
                    </Box>
                )
            })}</Box>
            {isSeen && is_sender ? <CheckCircleOutlinedIcon sx={{width: 12, position: 'absolute', bottom: 0, right: -15, color: '#666'}} /> : <></>}
        </Box>
    )
}

export default ChatMessage;