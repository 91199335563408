const ThemeStyles = {
  dialogWrapper: {
    padding: ["1rem", "1rem", "2rem", "2rem", "2rem"],
  },
  dialogHeading: {
    fontSize: '1.6rem',
    fontWeight: 'bold'
  },
  paddedBox: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
  },
  activeCheckbox: {
    color: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
};

export default ThemeStyles;
