const SwapperStyles = {
  contentTitle: {
    padding: "0",
    fontSize: "0.9rem",
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
    marginBottom: "1rem",
  },
  contentData: {
    padding: "0",
    fontSize: "0.9rem",
    color: "#444444",
    marginBottom: "1rem",
    wordBreak: "break-all",
  },
  swapperIcon: {
    color: (theme) => theme.palette.primary.main,
  },
  deleteIcon: {
    color: (theme) => theme.palette.error.main,
  },
  swapperIconWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  swapperActionMenuWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default SwapperStyles;
