import React, {useEffect, useState} from "react";
import Styles from "Common/Filters/Styles";
import { SearchIcon } from "Assets/Icons";
import { Box, Select, MenuItem, FormControl, OutlinedInput, InputAdornment, Autocomplete } from "Assets/Material";
import { searchTermValue, useRecoilState, searchByValue } from "Atom";
import { useHistory, useLocation } from "react-router-dom";
import CreatableAutocomplete from "Common/CreatableAutocomplete/CreatableAutocomplete";

let _TO_ = null;
const SearchFilter = (props) => {
  const [search_term, setSerch_term] = useRecoilState(searchTermValue)
  const [search_by, setSearch_by] = useRecoilState(searchByValue)
  const [isCapability, setIsCapability] = useState(false);
  const history = useHistory();

  
  const handleSearchTermChange = (term) => {
    setSerch_term(term);
    const LAG = 700;
    if(_TO_){
      clearTimeout(_TO_);
    }

    _TO_ = setTimeout(() => {
      props?.setSearchTerm(term);
    }, LAG);
  }

  const handleClick = (event) => {
     const value = event.target.innerText;
     value === "Capability" ? setIsCapability(true) : setIsCapability(false);
  }

  const handleCapability = (value) => {

    const term = value.capabilitiesAdd.map((item) => item.label)
    setSerch_term(term);
    const LAG = 700;
    if(_TO_){
      clearTimeout(_TO_);
    }
    _TO_ = setTimeout(() => {
      props?.setSearchTerm(term);
    }, LAG);
  }

  useEffect(() => {
      setSerch_term("")
  },[history?.location?.pathname])
  return (
    <Box sx={Styles.searchFilterContainer}>
      <FormControl>
        <Select
          displayEmpty
          input={<OutlinedInput />}
          sx={Styles.searchFilter1}
          value={props?.searchBy}
          onChange={(e) => {
            props?.setSearchBy(e.target.value);
            handleSearchTermChange("");
            setSearch_by(e.target.value)
          }}
        >
          {props?.searchFilterData.map((search, index) => {
            return (
              <MenuItem key={index} value={search.value} onClick={handleClick}>
                {search.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl>
        {
          isCapability ? 
          <div style={{marginTop: "-1.5px", }}>
            <CreatableAutocomplete 
              style={{border: "1px solid red"}}
              getValue={(value) => handleCapability(value)}
            />
          </div>
        : 
        <OutlinedInput
          sx={Styles.searchFilter2}
          value={search_term}
          onChange={(e) => {
            handleSearchTermChange(e.target.value);
          }}
          placeholder="Enter Keywords"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        }
        
      </FormControl>
    </Box>
  );
};

export default SearchFilter;
