const Styles = {
  swapper: {
    backgroundColor: "#fff",
    padding: "0 2rem",
    height: "auto",
  },
  swapperHook: {
    width: "10%",
    borderWidth: "0.2rem",
    borderColor:(theme) => theme.palette.primary.main,
    borderRadius: "50px",
    marginTop: "1.5rem",
    marginBottom: "1rem",
  },
  swapperTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: (theme) => theme.palette.text_heading.main,
    textAlign: "center",
    margin: "0",
  },
  swapperActionMenuIcon: {
    fontSize: "1.5rem",
    float: "right",
    color: (theme) => theme.palette.primary.main,
  },
  swapperDevider: {
    border: "1px solid gainsboro",
    margin: "1rem 0"
  }
};

export default Styles;