const ProjectDetailsHeaderStyles = {
  wrapper: {
    marginBottom: "2rem",
    background: "#F7FEFF 0% 0% no-repeat padding-box",
    padding: "12px",
    border: "1px solid #BCF3FF",
    borderRadius: "4px",
  },
  projectId: {
    color: (theme) => theme.palette.text_data.main,
    fontWeight: "700",
    textAlign: "right",
  },
  projectDescriptionWrapper: {
    paddingTop: "1rem",
  },
  projectDescription: {
    maxHeight: "10rem",
    overflow: "auto",
    width: "100%",
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: ["0.5rem", "0.5rem"],
  },
  justiFyCenter: {
    alignItems: "center",
  },
  dateInput: {
    // width: ["100%", "90%", "50%", "40%", "30%"],
    width: "60%",
    display: "inline-block",
    paddingLeft: "1rem",
  },
  durationWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: ["0.5rem", "0.5rem"],
  },
  durationInputWrapper: {
    // paddingLeft: "1rem",
    display: "flex",
    gap: "1rem",
    paddingLeft: "12px",
  },
  durationInput: {
    width: ["100%", "90%", "50%", "40%", "30%"],
  },
  durationTypeInput: {
    width: "10rem",
    marginLeft: "1rem",
  },
  createTeamButton: {
    position: "relative",
    border: "2px solid #607ebb",
    borderRadius: "50px",
    color: "#607ebb",
    cursor: "pointer",
    padding: "0 1rem",
    fontWeight: "700",
    fontSize: "1rem",
    marginTop: ["1.5rem", "2rem", "2rem", "2rem", "2rem"],
  },
  createTeamButtonLogo: {
    height: "20px !important",
    width: "20px !important",
    marginLeft: "1rem",
  },
};

export default ProjectDetailsHeaderStyles;
