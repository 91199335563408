const ClientProfileStyles = {
  clientProfileTablewrapper: {
    marginTop:"1rem",
    backgroundColor: "#90c3cf29",
    padding: ["1rem", "1rem", "2rem 2.5rem", "2rem 2.5rem", "2rem 2.5rem"]
  },
  clientProfileTableTitle: {
    margin: "0",
    marginBottom: "1rem",
    display: "flex",
    fontWeight: "700",
    paddingLeft: "0.5rem",
    color: (theme) => theme.palette.text_heading.main,
    fontSize: "1.2rem",
    borderLeft: (theme) => [`4px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  statusActive: {
    color: (theme) => theme.palette.success.main
  },
  deleteIcon: {
    color: (theme) => theme.palette.error.main,
  },
}

export default ClientProfileStyles;