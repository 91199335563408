import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, Typography } from "Assets/Material";
import { getProjectsService } from "Services/CountAndDataService";
import { getAllProjectData } from "Services/ProjectService";
import { authUser } from "Utils";
import Select from "react-select";

const Styles = {
  selectTitle: {
    top: "12px",
    left: "9px",
    fontSize: "12px",
    fontWeight: "300",
    padding: "0px 4px",
    position: "relative",
    background: "#fff",
    zIndex: 1,
  },
};

const ProjectAutocomplete = (props) => {
  const [value, setvalue] = useState(props?.initialValue);
  const [projects, setProjects] = useState([]);
  const [projectList, setProjectList] = useState([]);
  // const getFunctionalCapabilities = async (searchParam) => {
  //   const response = await getFunctionalCapabilitiesService(searchParam);
  //   if (response) {
  //     let skills = response.map((item) => ({ value: item.id, label: item.name }));
  //     setFunctionalCapabilities(skills);
  //   }
  // };

  const getDataList = async (searchParam) => {
    const params = {
      from: 0,
      offset: 30,
      org_id: authUser()?.org_id,
      search_by: "1",
      search_term: searchParam,
      sort_by: "id",
      sort_order: "DESC",
      user_id: authUser()?.user_id,
      user_type: authUser()?.user_type,
    };

    const projects_list = await getAllProjectData(params);
    if (projects_list) {
      setProjects(projects_list);
      let data = [];
      projects_list?.data.map((i) => {
        data.push({ label: i.title, value: i.project_id });
      });
      setProjectList(data);
    }
  };

  useEffect(() => {
    getDataList();
    return () => setProjects([]);
  }, []);

  return (
    <>
      {/* <Autocomplete
        id={props?.id}
        name={props?.name}
        value={value}
        disableClearable
        options={projects}
        getOptionLabel={(option) => option.title || ""}
        onChange={(_, newValue) => {
          setvalue(newValue);
          props?.getValue(newValue.id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            size="small"
            margin="dense"
            label="Projects"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      /> */}
      <Typography component="span" sx={Styles.selectTitle}>
        Projects
      </Typography>

      <Select
        defaultValue={value}
        name="colors"
        onChange={(newValue, _) => props?.getValue(newValue.value)}
        onInputChange={(e) => {
          if (e.length >= 1) {
            getDataList(e);
          } else {
            setProjectList([]);
          }
        }}
        // onChange={(newValue, _) => props?.getValue(newValue.value)}
        options={projectList}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </>
  );
};

export default ProjectAutocomplete;
