import React from "react";
import Styles from "Common/Confirm/Styles";
import { Modal, Box, Typography, Button } from '@mui/material';
import { useRecoilState, confirmGlobal } from "Atom";
import { Delete } from "@mui/icons-material";

const Confirm = ({text="delete", buttonText = "delete"}) => {

  const [confirm, setConfirm] = useRecoilState(confirmGlobal);
  const onClose = () => setConfirm({ isOpen: false, delete: null });

  return (
    <>
      <Modal open={confirm.isOpen} onClose={onClose}>
        <Box sx={Styles.confirmWrapper}>
          <Delete sx={Styles.confirmDeleteIcon} />
          {
            confirm.message 
            ? confirm.message 
              : <Typography sx={Styles.message}>Are you sure you want to {text}?</Typography> 
          }
          
          <Box sx={Styles.buttonWrapper}>
            <Button
              variant="radius"
              onClick={() => {
                confirm.delete();
              }}
              sx={Styles.deleteButton}
            > {buttonText}
            </Button>
            <Button
              variant="radius"
              onClick={onClose}
              sx={Styles.cancelButton}
            >Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Confirm;