const Styles = {
  toasterWrapper: {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "#fff",
    padding: "1rem",
    width: ["80%", "50%", "30%", "30%", "30%"],
  },
  closeIcon: {
    right: "1rem",
    cursor: "pointer",
    position: "absolute",
    "&:hover": { color: "red" },
  },
  message: {
    textAlign: "center !important",
    margin: "1rem 0 !important",

    fontSize: ["1rem","1.5rem","1.5rem","1.5rem","1.5rem"],
    "&:first-latter": {},
  },
  toasterSuccessIcon: {
    margin: "0 auto !important",
    display: "block !important",
    fontSize: "3rem !important",
    marginTop: "2rem !important",
    color: (theme) => theme.palette.success.main,
  },
  toasterFailIcon: {
    margin: "0 auto !important",
    display: "block !important",
    fontSize: "3rem !important",
    marginTop: "2rem !important",
    color: "#ff1744",
  },
};

export default Styles;
