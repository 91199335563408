const Styles = {
  tablePopupWrapper: {
    position: "absolute",
    maxHeight: "120px",
    width: "190px",
    overflowY: "auto",
    margin: "0",
    padding: "12px 6px",
    boxShadow: "0px 0px 5px 0px #0000004d",
    backgroundColor: "#fff",
    minHeight: "44px",
    marginTop: "-22px",
    borderRadius: "4px",
    zIndex: "9",
    whiteSpace: "pre-wrap",
  },
  tablePopupCloseIcon: {
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  tablePopupList: {
    margin: "0",
    listStyle: "none",
    padding: "0",
    background: "#ffffff",
    borderRadius: "5px",
    width: "auto !important",
    zIndex: "99",
  },
  tablePopupListItem1: {
    padding: "5px",
    borderBottom: "1px solid",
    borderColor: "#44444480",
    whiteSpace: "pre-line !important",
    overflow: "auto",
  },
  tablePopupListItem2: {
    padding: "5px",
    overflow: "auto",
    whiteSpace: "pre-line !important",
  },
};

export default Styles;
