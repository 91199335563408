import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { usertype, authUser } from "Utils";
import { useHistory } from "react-router-dom";
import { useRecoilState, isLoginGlobal, toasterGlobal } from "Atom";
import { ResetPasswordProfileService } from "Services/AuthService";
import { UserProfile, VisibilityIcon, PasswordIcon, VisibilityOffIcon, CloseIcon } from "Assets/Icons";
import { Modal, Box, Button, IMG, TextField, CircularProgress, P, InputAdornment, IconButton } from "Assets/Material";
import Styles from "Common/Header/Styles";
import Cookies from "js-cookie";

const PasswordPopup = (props) => {
  const history = useHistory();

  const setToaster = useRecoilState(toasterGlobal)[1];
  const [loginError, setLoginError] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfrmPassword, setIsShowConfrmPassword] = useState(false);

  const setIsLoginGlobalState = useRecoilState(isLoginGlobal)[1];

  const loginAuth = async (values) => {
    setIsFormSubmit(true);

    // values.user_id = authUser()?.user_id;
    values.user_email = authUser()?.email;
    let response = await ResetPasswordProfileService(values);

    if (response?.data && response?.data?.status && response?.data?.status == true) {
      // Cookies.set("token", response?.data?.data?.token);
      // history.push("/");
      setIsLoginGlobalState(true);
      setIsFormSubmit(false);
      // setToaster({ isOpen: true, status: "success", message: response?.data?.message });
      Cookies.remove("token");
      history.push("/login");
      props.onClose();
    } else {
      setLoginError(response?.data?.message);
      setIsFormSubmit(false);
      // setToaster({ isOpen: true, status: "fail", message: response?.data?.message });
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.old_password) {
      errors.old_password = "Old password is required";
      setLoginError("");
    }
    if (!values.password) {
      errors.password = "New password is required.";
      setLoginError("");
    }
    if (!values.password_again) {
      errors.password_again = "Please enter confirm password.";
      setLoginError("");
    }
    if (values.password !== values.password_again) {
      errors.password_again = "New password and Confirm password are not same";
      setLoginError("");
    }
    return errors;
  };

  useEffect(async () => {
    return () => {
      setLoginError("");
    };
  }, []);

  return (
    <>
      <Modal open={props?.isOpen} onClose={props?.onClose}>
        <Box sx={Styles.confirmWrapper} style={{ marginTop: "5%" }}>
          <CloseIcon sx={Styles.closeIcon} onClick={props?.onClose} />
          <Box sx={Styles.loginProfileImageWrapper}>
            <IMG src={UserProfile} alt={UserProfile} sx={Styles.loginProfileImage} />
          </Box>
          <P sx={{ fontWeight: "700" }}> {authUser()?.email} </P>
          <P> Reset your password </P>
          <Formik
            initialValues={{
              old_password: "",
              password: "",
              password_again: "",
            }}
            validate={(values) => validateForm(values)}
            onSubmit={(values) => loginAuth(values)}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <TextField
                  fullWidth
                  name="old_password"
                  type={!isShowPassword ? "password" : "text"}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  placeholder="Old Password"
                  value={values.old_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={Styles.inputField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <PasswordIcon />{" "}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsShowPassword((prev) => !prev)}
                          edge="end"
                        >
                          {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={errors.old_password && touched.old_password && errors.old_password}
                  helperText={errors.old_password && touched.old_password && errors.old_password}
                />
                <P sx={Styles.resetFormError}> {loginError} </P>
                <TextField
                  fullWidth
                  name="password"
                  type={!isShowNewPassword ? "password" : "text"}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  placeholder=" New password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={Styles.inputField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <PasswordIcon />{" "}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsShowNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {isShowNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={errors.password && touched.password && errors.password}
                  helperText={errors.password && touched.password && errors.password}
                />
                {/* <P sx={Styles.formError}> {loginError} </P> */}
                <TextField
                  fullWidth
                  name="password_again"
                  type={!isShowConfrmPassword ? "password" : "text"}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  placeholder="Confirm password"
                  value={values.password_again}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={Styles.inputField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <PasswordIcon />{" "}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsShowConfrmPassword((prev) => !prev)}
                          edge="end"
                        >
                          {isShowConfrmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={errors.password_again && touched.password_again && errors.password_again}
                  helperText={errors.password_again && touched.password_again && errors.password_again}
                />
                {/* <P sx={Styles.formError}> {loginError} </P> */}

                <Button
                  type="submit"
                  disabled={isFormSubmit}
                  sx={Styles.authButton}
                  // fullWidth
                >
                  {isFormSubmit ? <CircularProgress sx={Styles.loader} /> : "Reset Password"}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default PasswordPopup;
