import API from "Services";
import {
  SIGNUP,
  LOGIN,
  VERIFY,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  GET_USER_ACCESS,
  RESER_PASSWORD_PROFILE,
  RELAY_LOGIN,
  GET_SHARE_LINK,
} from "Utils/ApiConstants";

export const signupUserService = (data) => {
  return API.post(SIGNUP, data);
};

export const verificationUserService = (data) => {
  return API.post(VERIFY, data);
};

export const getUserAccessService = (data) => {
  return API.post(GET_USER_ACCESS, data);
};

export const loginUserService = (data) => {
  return API.post(LOGIN, data);
};

export const forgetPasswordService = (data) => {
  return API.post(FORGET_PASSWORD, data);
};

export const ResetPasswordService = (data) => {
  return API.post(RESET_PASSWORD, data);
};

export const ResetPasswordProfileService = (data) => {
  return API.post(RESER_PASSWORD_PROFILE, data);
};

export const generateRelayLogin = async (token) => {
  try {
    const response = await API.post(RELAY_LOGIN, { token });
    if (response && response.data && response.data) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const getShareLink = async (user) => {
  let response = await API.get(GET_SHARE_LINK, {
    params: {
      user_id: user.user_id,
      user_type_id: user.user_type_id,
      job_id: user.job_id
    },
  });

  if (response && response.data) {
    return response.data;
  }
};
