import React, { useState, memo, useEffect } from "react";
import { useHistory } from "react-router";
import { authUser, getHashFromId } from "Utils";
import { useRecoilState, toasterGlobal, confirmGlobal } from "Atom";
import { useDeleteConsultantService } from "Services/TalentManagementService";
import { TableStyles, TalentManagementStyles as Styles } from "Assets/Styles";
import { Email, Loader, NoData, Pagination, ActionMenu, ContactAction } from "Common";
import { VisibilityIcon } from "Assets/Icons";
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  useMediaQuery,
  useTheme,
  TableContent,
  IMG,
  IconButton
} from "Assets/Material";
import { MoreVert } from "Assets/Icons";
import TalentManagementSwapper from "Modules/TalentManagement/Component/TalentManagementSwapper";
import { useTour } from "@reactour/tour";
import { getAllQuochmark } from "Services/QuochMark";
import ConsultantInvoices from "./ConsultantInvoices";

const DataList = (props) => {
  const history = useHistory();
  const { setIsOpen } = useTour();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  const setConfirm = useRecoilState(confirmGlobal)[1];
  const setToaster = useRecoilState(toasterGlobal)[1];

  const [talentActionAnchor, setTalentActionAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEmail, setIsEmail] = useState(false);
  const [isSuggest, setIsSuggest] = useState(false);
  const [selectedConsultant, selectConsultant] = useState(null);

  

  const onEmailToggle = (bool, data) => {
    if (bool) {
      setIsEmail(true);
      setSelectedRow(data);
    } else {
      setIsEmail(false);
      setSelectedRow(null);
      props?.onSwapperClose();
    }
  };

  const onSwapperToggle = (bool, data) => {
    if (bool) {
      props?.onSwapperOpen();
      setSelectedRow(data);
    } else {
      props?.onSwapperClose();
      setSelectedRow(null);
    }
  };

  const onSuccess = () => {};

  const onError = () => {};

  const getSearchTalentConsultants = async () => {
    const getQuoch = await getAllQuochmark(authUser().user_id);
    if (getQuoch?.data.length > 0) {
      if (getQuoch?.data[0]?.talents === 1) {
        setIsOpen(true);
      }
    }
  };
  useEffect(() => {
    getSearchTalentConsultants();
  }, []);

  return (
    <>
      {
        selectedConsultant !== null 
        ? <ConsultantInvoices 
            close={() => selectConsultant(null)} 
            user_id={selectedConsultant.user_id} 
            consultant={selectedConsultant}
            onEmailToggle={() => onEmailToggle(true, selectedConsultant)} />
        : ''
      }
      <Box sx={TableStyles.tableWrapper}>
        <TableContainer component={Paper} sx={TableStyles.tableContainer}>
          <Table sx={TableStyles.table}>
            <TableHead>
              <TableRow>
                <TableCell sx={TableStyles.tableCell}>Name</TableCell>
                
                
                {!xs && !sm && <TableCell sx={{ ...TableStyles.tableCell, ...{ width: "10%" } }}>Charges/Hr</TableCell>}
                
                <TableCell sx={TableStyles.tableCell}>Last Invoice Date</TableCell>
                <TableCell sx={TableStyles.tableCell}>Open Invoices</TableCell>
                <TableCell sx={TableStyles.tableCell}>Total Billed Amount</TableCell>
                <TableCell sx={TableStyles.tableCell}>Total Unbilled Amount</TableCell>
                <TableCell sx={TableStyles.tableCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            {props?.data?.data?.length > 0 ? (
              <TableBody>
                {props?.data?.data?.map((list, key) => {
                  return (
                    <TableRow key={key} sx={TableStyles.tableRow}>
                      <TableCell
                        sx={
                          xs || sm
                            ? { ...TableStyles.tabledata, ...TableStyles.mobilelink }
                            : { ...TableStyles.tabledata, ...TableStyles.link }
                        }
                        onClick={() => {
                          setSelectedRow(list);
                          xs || sm
                            ? onSwapperToggle(true, list)
                            : history.push(`/consultant-details/${getHashFromId(list?.user_id)}`);
                        }}
                      >
                        <TableContent>
                          {list.first_name} {list.last_name}
                        </TableContent>
                      </TableCell>

                      

                      

                      

                      {!xs && !sm && (
                        <TableCell sx={TableStyles.tabledata}>
                          <TableContent>${list.remote_per_day_rate}</TableContent>
                        </TableCell>
                      )}

                      

                      <TableCell sx={TableStyles.tabledata}>
                        <TableContent style={{ padding: "0px" }}>
                          {list.last_invoice_date}
                        </TableContent>
                      </TableCell>
                      <TableCell sx={TableStyles.tabledata}>
                        <TableContent style={{ padding: "0px" }}>
                          {list.open_invoice_count}
                        </TableContent>
                      </TableCell>
                      <TableCell sx={TableStyles.tabledata}>
                        <TableContent style={{ padding: "0px" }}>
                          {list.total_billed_amount}
                        </TableContent>
                      </TableCell>
                      <TableCell sx={TableStyles.tabledata}>
                        <TableContent style={{ padding: "0px" }}>
                          {list.total_unbilled_amount}
                        </TableContent>
                      </TableCell>

                      {authUser().user_type === "Admin" && (
                        <>
                          {!xs && !sm && (
                            <TableCell sx={TableStyles.tabledata}>
                              <TableContent>
                                <IconButton >
                                  <VisibilityIcon 
                                    // sx={TableStyles.buttonIcon} 
                                    onClick={() => selectConsultant(list)} />
                                </IconButton>
                              </TableContent>
                            </TableCell>
                          )}
                        </>
                      )}
                      
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={"8"}>
                    <NoData />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>

          <Pagination
            perPage={10}
            from={props?.pagination.from}
            to={props?.pagination.to}
            totalDataItem={props?.pagination.totalDataItem}
            previous={props?.pagination.previous}
            next={props?.pagination.next}
          />
        </TableContainer>

        <Email
          data={selectedRow}
          onOpen={isEmail}
          onClose={() => onEmailToggle(false, null)}
          requestFrom="TalentManagement"
          title={selectedRow && `${selectedRow.first_name} ${selectedRow.last_name}`}
          id={selectedRow?.consultant_id}
        />

        

        {/* {isSuggest && <SuggestClient consultant={selectedRow} onOpen={isSuggest} onClose={() => setIsSuggest(false)} />} */}
      </Box>
    </>
  );
};

export default memo(DataList);
