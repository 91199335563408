import React, { useState, useEffect } from "react";
import { usertype, authUser } from "Utils";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { PageHeader, Filters } from "Common";
import { SearchFilter, SortFilter } from "Utils/FiltersConstants";
import { useAllProjectLeadService, fetchProjectLeadExport, fetchLeadByIdService, useAllMarvinLeadsService } from "Services/ProjectLeadsServices";

import queryString from "query-string";
import DataList from "Modules/ProjectLeads/component/DataList";
import AddEditProjectLeads from "Modules/ProjectLeads/component/AddEditProjectLeads";
import { useTheme, useMediaQuery, Grid, Box, Button } from "Assets/Material";
import EditProjectLeads from "./component/EditProjectLeads";
import { useRecoilState, toasterGlobal, searchTermValue, searchByValue } from "Atom";
import MarvinDataList from "./component/MarvinDataList";


const MarvinLeads = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const path = window.location.pathname;
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const initialQueryString = queryString.parse(location.search);
  const initialPageNumber = Number(initialQueryString.page) || 1;

  const setToaster = useRecoilState(toasterGlobal)[1];
  const serchTermValue = useRecoilState(searchTermValue)
  const searchBy_Value = useRecoilState(searchByValue)

  const [startFrom, setStartFrom] = useState((initialPageNumber - 1) * 10);
  const [endTo, setEndTo] = useState(10);
  const [searchBy, setSearchBy] = useState(searchBy_Value[0]);
  const [searchTerm, setSearchTerm] = useState(serchTermValue[0]);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortOrderList, setSortOrderList] = useState([{ id: 1, name: "Default", value: "DESC" }]);

  const [onAddProjectLeads, setOnAddProjectLeads] = useState(false);
  const [isProjectLeadEdit, setIsProjectLeadEdit] = useState(false);
  const [dataProjectLeadEdit, setDataProjectLeadEdit] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [isSwapper, setIsSwapper] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const data = {
    from: startFrom,
    offset: 10,
    org_id: authUser()?.org_id,
    search_by: searchBy,
    search_term: searchTerm,
    sort_by: sortBy,
    sort_order: sortOrder,
    user_id: authUser()?.user_id,
    user_type: usertype(authUser()?.user_type_id),
  };
  // Use react Query
  const onSuccesss = (data) => {
    const initialPageNumber = Number(startFrom / 10 + 1) || 1;
    history.push(`${path}?page=${initialPageNumber}`);
  };

  const onError = (error) => {
    // console.log({ error });
  };

  const {
    isLoading,
    data: projectLead,
    isError,
    error,
    refetch,
    isFetching,
  } = useAllMarvinLeadsService(onSuccesss, onError, data);

  const previous = () => {
    setEndTo(endTo - 10);
    setStartFrom(startFrom - 10);
    sessionStorage.setItem("from", startFrom - 10);
    sessionStorage.setItem("end", endTo - 10);
  };


  
  useEffect(() => {
    let _from = sessionStorage.getItem("from");
    let _end = sessionStorage.getItem("end");
    if (_from) {
      setStartFrom(Number(_from));
      setEndTo(Number(_end));
    }

    sessionStorage.clear();
  }, [])

  const next = () => {
    setEndTo(endTo + 10);
    setStartFrom(startFrom + 10);
    sessionStorage.setItem("from", startFrom + 10);
    sessionStorage.setItem("end", endTo + 10);
  };

  const closeProjectLead = () => {
    setOnAddProjectLeads(false);
    setIsProjectLeadEdit(false);
    setDataProjectLeadEdit(null);
    setActiveStep(0);
  };

  const resetFilters = () => {
    setSearchBy("1");
    setSearchTerm("");
    setSortBy("id");
    setSortOrder("DESC");
    setEndTo(10);
    setSortOrderList([{ id: 1, name: "Default", value: "DESC" }]);
  };

  // const downloadExport = async () => {
  //   const dataE = {
  //     from: startFrom,
  //     // offset: talentManagement?.data?.[0]?.count,
  //     offset: projectLead?.[0]?.count,

  //     org_id: authUser()?.org_id,
  //     search_by: searchBy,
  //     search_term: searchTerm,
  //     sort_by: sortBy,
  //     sort_order: sortOrder,
  //     export: true,
  //     user_id: authUser()?.user_id,
  //     user_type: usertype(authUser()?.user_type_id),
  //   };
  //   const daaa = await fetchProjectLeadExport(dataE);
  //   if (daaa.message) {
  //     let envFile = process.env.REACT_APP_API_URL.toString();
  //     let envFileSplit = envFile.split("api/v1.0/");
  //     var a = document.createElement("a");
  //     a.href = `${envFileSplit[0]}${daaa.message}`;
  //     a.setAttribute("download", "leads_list");
  //     a.click();
  //   }
  // };

  const zeroOne = () => {
    setStartFrom((1 - 1) * 10);
    setEndTo(10);
  };

  const fetchLeadById = async (data) => {
    const response = await fetchLeadByIdService(data);
    if (response) {
      setDataProjectLeadEdit(response);
      setIsProjectLeadEdit(true);
    }
  };

  useEffect(() => {
    document.title = "Project Leads";
  }, []);

  const page_header_params = {
    pageTitle: "Marvin Leads",
    // buttonLabel: "Add",
  };

  const filters_params = {
    isSearch: true,
    isSort: true,
    isAddButton: false,
    searchFilterData: SearchFilter.unapprovedLeads,
    sortFilterData: SortFilter.unapprovedLeads,
    searchBy: searchBy,
    searchTerm: searchTerm,
    sortBy: sortBy,
    sortOrder: sortOrder,
    sortOrderList: sortOrderList,
    setSearchBy: (value) => {
      setSearchBy(value);
      zeroOne();
    },
    setSearchTerm: (value) => {
      setSearchTerm(value);
      zeroOne();
    },
    setSortBy: (value) => {
      setSortBy(value);
      zeroOne();
    },
    setSortOrder: (value) => {
      setSortOrder(value);
      zeroOne();
    },
    setSortOrderList: (value) => setSortOrderList(value),
    resetFilters: () => {
      resetFilters();
      zeroOne();
    },
    // isExport:authUser()?.user_type === "Admin" ? true : false,
    isExport: false,

    isExportButton: () => {
      // downloadExport();
    },
    
    
    
    addItem: () => setOnAddProjectLeads(true),
  };

  const data_list_params = {
    data: projectLead,
    isLoading: isLoading,
    isSwapper: isSwapper,
    onSwapperOpen: () => setIsSwapper(true),
    onSwapperClose: () => setIsSwapper(false),
    isProjectLeadEdit: () => setIsProjectLeadEdit(true),
    getDataEdit: (data) => {
      fetchLeadById(data);
    },
    pagination: {
      perPage: 10,
      from: startFrom + 1,
      to: endTo,
      totalDataItem: projectLead?.[0]?.count,
      previous: previous,
      next: next,
    },
  };
  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const add_edit_project_leads_params = {
    onOpen: onAddProjectLeads || isProjectLeadEdit,
    onClose: () => closeProjectLead(),
    isEdit: isProjectLeadEdit,
    onSwapperClose: () => setIsSwapper(false),
    dataEdit: dataProjectLeadEdit,
    activeStep: activeStep,
    handleNext: () => handleNext(),
    handleBack: () => handleBack(),
    // refresh: getProjectLeads,
  };
  const edit_project_leads_params = {
    onOpen: isProjectLeadEdit,
    onClose: () => closeProjectLead(),
    isEdit: isProjectLeadEdit,
    onSwapperClose: () => setIsSwapper(false),
    dataEdit: dataProjectLeadEdit,
    // refresh: getProjectLeads,
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <PageHeader {...page_header_params} />
        </Grid>
        {xs && authUser().user_type === "Admin" && (
          <Grid item xs={4}>
            <Box className="header_comp">
              <Button variant="radius" onClick={() => setOnAddProjectLeads(true)}>
                {" "}
                Add{" "}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <Filters {...filters_params} />
      <MarvinDataList {...data_list_params} />
      <AddEditProjectLeads {...add_edit_project_leads_params} />
    </>
  );
};

export default MarvinLeads;
