const Styles = {
  linkedIn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  formWrapper: {
    p: 4,
    overflowY: "auto",
    position: "absolute",
    bgcolor: "background.paper",
    top: ["0", "0", "40%", "40%", "40%"],
    left: ["0", "0", "50%", "50%", "50%"],
    width: ["100%", "100%", " 60%", " 50%", " 50%"],
    maxHeight: ["100vh", "100vh", "80vh", "80vh", "80vh"],
    height: ["100vh", "100vh", "auto", "auto", "auto"],
    transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"],
  },
  resume: {
    p: 4,
    overflowY: "auto",
    position: "absolute",
    bgcolor: "background.paper",
    top: ["0", "0", "40%", "40%", "40%"],
    left: ["0", "0", "50%", "50%", "50%"],
    width: ["100%", "100%", " 60%", " 50%", " 50%"],
    // maxHeight: ["10vh", "100vh", "80vh", "80vh", "80vh"],
    minHeight: ["100vh", "100vh", "auto", "auto", "auto"],
    transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"],
  },
  submitButton: {
    padidingTop: "0px",
  },
  remoteWprking: {
    marginTop: "8px",
  },
  engagementPreferenceWrapper: {
    marginTop: "1.3rem",
  },
  engagementPreferenceFormLabel: {
    fontSize: "0.8rem",
    color: "#666666",
    position: "absolute",
    top: "-1.4rem",
    left: "0.8rem",
    backgroundColor: "#fff",
    padding: "0 0.2rem",
  },
  radioButtonWrapper: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    marginTop: "-13px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1rem",
  },
  radioButtonLabel: {
    fontSize: "0.8rem",
    marginRight: "0",
    "& .MuiTypography-body1": {
      fontSize: "0.8rem",
    },
  },
  skills: {
    paddingTop: "0 !important",
  },
};

export default Styles;
