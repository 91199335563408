const ClientsStyles = {
  actionMenuHeader: {
    width: "7%"
  },
  disabledIcon: {
    pointerEvent: "none",
    cursor: "auto",
    color: "#adacb0"
  },
  editIcon: {
    color: (theme) => theme.palette.primary.main,
    marginRight: "2rem",
  },
  deleteIcon: {
    color: "#adacb0"
  }
};

export default ClientsStyles;