import API from "Services";
import Cookies from "js-cookie";

import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  GET_ALL_TALENT_MANAGEMENT,
  GET_ALL_INDEXED_TALENT_MANAGEMENT,
  DELETE_CONSULTANT,
  SUBMIT_LINKEDIN_URL,
  ADD_CONSULTANT,
  GET_CONSULTANT_DETAILS,
  SUGGEST_CLIENTS_TO_CONSULTANTS,
  FETCH_SHARED_CONSULTANT_PROFILE,
  GET_CONSULTANT_PROJECTS,
  DELETE_SHARED_PROFILE,
  FILE_UPLOAD,
  UPDATE_CONSULTANT_PROFILE,
  ONE_PAGER_UPLOAD,
  FETCH_CAPABILITY_SUGGESTION,
  DISMISS_CAPABILITY_SUGGESTIONS,
  FETCH_LANGUAGE_SUGGESTION,
  DISMISS_LANGUAGE_SUGGESTIONS,
  GET_CONSULTANT_RESUME_PARSER,
  GET_CONSULTANT_RESUME_SUMMARY

} from "Utils/ApiConstants";

export const getTalentManagement = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}&pdf_text=${data.pdf_text}`;
    const response = await API.get(GET_ALL_TALENT_MANAGEMENT + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const getIndexedTalentManagement = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}&pdf_text=${data.pdf_text}`;
    const response = await API.get(GET_ALL_INDEXED_TALENT_MANAGEMENT + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getConsultantResumeParser = async (consultant_id, asset_id) => {
  let params =  `?consultant_id=${consultant_id}&asset_id=${asset_id}`;
  try {
    const response = await API.get(GET_CONSULTANT_RESUME_PARSER + params);
    if (response && response.data) return response.data;
  } catch (error) {
     if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}

export const getConsultantResumeSummary = async (consultant_id, asset_id) => {
  let params =  `?consultant_id=${consultant_id}&asset_id=${asset_id}`;
  try {
    const response = await API.get(GET_CONSULTANT_RESUME_SUMMARY + params);
    if (response && response.data) return response.data;
  } catch (error) {
     if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
}

export const getTalentManagementExport = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}&export=true`;
    const response = await API.get(GET_ALL_TALENT_MANAGEMENT + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
export const getIndexedTalentManagementExport = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&search_by=${data.search_by}&search_term=${data.search_term}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_type=${data.user_type}&user_id=${data.user_id}&export=true`;
    const response = await API.get(GET_ALL_INDEXED_TALENT_MANAGEMENT + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useGetTalentManagementService = (onSuccess, onError, data) => {
  return useQuery(["all-talent-managment", data], () => getTalentManagement(data), {
    onSuccess,
    onError,
  });
};
export const useGetIndexedTalentManagementService = (onSuccess, onError, data) => {
  return useQuery(["all-indexed-talent-managment", data], () => getIndexedTalentManagement(data), {
    onSuccess,
    onError,
  });
};

export const useDeleteConsultantService = (onSuccesss, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteConsultant, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-talent-managment");
      return onSuccesss();
    },
  });
};

export const deleteConsultant = async (data) => {
  try {
    const response = await API.post(DELETE_CONSULTANT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const saveLinkedinUrlService = async (data) => {
  try {
    const response = await API.post(SUBMIT_LINKEDIN_URL, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

const addConsultant = async (data) => {
  try {
    const response = await API.post(ADD_CONSULTANT, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const useAddConsultantService = (onSuccesss, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addConsultant, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-talent-managment");
      return onSuccesss();
    },
  });
};

export const getConsultantDetailsService = async (id) => {
  try {
    const response = await API.get(GET_CONSULTANT_DETAILS + id);
    if (response) return response?.data?.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

// export const useConsultantDetailsService =  (id) => {
//   try {
//     const response = await API.get(GET_CONSULTANT_DETAILS + id);
//     if (response) return response;
//   } catch (error) {
//     return error;
//   }
// };

export const useConsultantDetailsService = (onSuccess, onError, data) => {
  return useQuery(["all-talent-managment", data], () => getConsultantDetailsService(data), {
    onSuccess,
    onError,
  });
};

export const suggestClientsToConsultantsServices = async (data) => {
  try {
    const response = await API.post(SUGGEST_CLIENTS_TO_CONSULTANTS, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getSharedConsultantService = async (data) => {
  try {
    const response = await API.post(FETCH_SHARED_CONSULTANT_PROFILE, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getConsultantProjectService = async (data) => {
  try {
    const response = await API.post(GET_CONSULTANT_PROJECTS, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const getFetchSharedConsultantProfileService = async (id) => {
  try {
    const response = await API.get(FETCH_SHARED_CONSULTANT_PROFILE + id);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const deleteSharedProfileService = async (data) => {
  try {
    const response = await API.post(DELETE_SHARED_PROFILE, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const uploadProfilePhotoService = async (data) => {
  try {
    const response = await API.post(FILE_UPLOAD, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const updateConsultantProfileService = async (data) => {
  try {
    const response = await API.post(UPDATE_CONSULTANT_PROFILE, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const addOnePagerService = async (data) => {
  try {
    const response = await API.post(ONE_PAGER_UPLOAD, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const fetchCapabilitySuggestionsService = async (consultant_id) => {
  try {
    const response = await API.get(FETCH_CAPABILITY_SUGGESTION + '?consultant_id=' + consultant_id);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const dismissCapabilitySuggestionsService = async (id) => {
  try {
    const response = await API.post(DISMISS_CAPABILITY_SUGGESTIONS, {id});
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};


export const fetchLanguageSuggestionsService = async (consultant_id) => {
  try {
    const response = await API.get(FETCH_LANGUAGE_SUGGESTION + '?consultant_id=' + consultant_id);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const dismissLanguageSuggestionsService = async (id) => {
  try {
    const response = await API.post(DISMISS_LANGUAGE_SUGGESTIONS, {id});
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};