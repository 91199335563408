import React, { useState, useEffect } from "react";
import { useRecoilState, toasterGlobal } from "Atom";
import { FileUploadService } from "Services/FileUploadService";
import { UploadFileOutlinedIcon, DeleteIcon } from "Assets/Icons";
import { Grid, Box, Typography, LinearProgress } from "Assets/Material";
import Styles from "Common/FileUploader/Styles";

const FileUploader = (props) => {

  const setToaster = useRecoilState(toasterGlobal)[1];
  const [progress, setProgress] = useState();
  const [files, setFiles] = useState(props?.initialValues);

  const onFileUpload = async (e) => {

    const file = e.target.files[0];
    let ext = file.name.split(".").pop();
    if (["pdf", "PDF"].includes(ext)) {
      setFiles(e.target.files[0]);
      const option = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      };
      const data = await FileUploadService(e.target.files, option);
      props?.getData(data[0]);
    } else {
      setToaster({
        isOpen: true,
        status: "fail",
        message: "Only pdf file is allowed",
      });
    }
  };

  const deleteFile = () => {
    setProgress(0);
    setFiles(null);
    props?.getData("");
  }

  useEffect(() => {
    return () => {
      setFiles(null);
      setProgress(0);
    };
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} sx={Styles.uploadContainer}>
          <Box sx={Styles.uploadWrapper}>
            <Typography
              component="label"
              htmlFor="upload_document"
              sx={Styles.uploadTitle}
            > Upload Document </Typography>

            <Typography
              htmlFor="upload_document"
              component="label" sx={Styles.uploadFileIconWrapper}
            >
              <UploadFileOutlinedIcon sx={Styles.uploadFileIcon} />
            </Typography>

            <Typography htmlFor="upload_document" component="label">
              <Typography component="span" sx={Styles.supportText}>
                Supports : .pdf
              </Typography>
            </Typography>

            <input
              style={{ display: "none" }}
              id="upload_document"
              type="file"
              accept="file/pdf, file/doc, file/docx, file/xls, file/xlsx, file/ppt, file/pptx"
              onChange={onFileUpload}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={Styles.uploadContainer}>
          {
            files && <Box sx={Styles.uploadWrapper}>
              <Grid item xs={12}>
                <Typography sx={Styles.fileName}> {files.name}</Typography>
              </Grid>
              <Grid container sx={Styles.progressWrapper}>
                <Grid item xs={11}>
                  <LinearProgress
                    variant="determinate"
                    value={props?.initialValues ? 100 : progress}
                    sx={Styles.progressBar}
                  />
                </Grid>
                <Grid item xs={1}>
                  <DeleteIcon
                    sx={Styles.deleteIcon}
                    onClick={deleteFile}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default FileUploader; 