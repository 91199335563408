import React, { useState, useEffect } from "react";
import { getFunctionalCapabilitiesService } from "Services/CountAndDataService";
import { Typography, Box } from "Assets/Material";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
const Styles = {
  selectTitle: {
    top: "12px",
    left: "9px",
    fontSize: "12px",
    fontWeight: "300",
    padding: "0px 4px",
    position: "relative",
    background: "#fff",
    zIndex: 1,
  },
};

const UnapprovedLeadCapabilityAutoComplete = (props) => {
  const [functionalCapabilities, setFunctionalCapabilities] = useState([]);
  const [functionalCapability, setFunctionalCapability] = useState(props?.initialValues);

  const getFunctionalCapabilities = async (searchParam) => {
    const response = await getFunctionalCapabilitiesService(searchParam);
    if (response) {
      let skills = response.map((item) => ({ value: item.id, label: item.name }));
      setFunctionalCapabilities(skills);
    }
  };

  const getSelectValue = (value) => {
    setFunctionalCapability(value);
    let capabilitiesNameArray = value.map((item) => item.label);
    props?.getValue(capabilitiesNameArray);
  };

  const selectMessage = (text) => {
    return `Add "${text}"  Your newly added capabilities will be sent for admin approval`;
  };

  useEffect(() => {}, [props]);

  return (
    <>
      <Box>
        {props?.isLabel && (
          <Typography component="span" sx={{ ...Styles.selectTitle, background: props.bg ? "aliceblue" : "#fff" }}>
            {" "}
            Capabilities{" * "}
          </Typography>
        )}
          
            <Select
              style={{ marginBottom: "0.5rem" }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isMulti
              isClearable={false}
              menuPosition={"fixed"}
              value={functionalCapability}
              options={functionalCapabilities}
              // placeholder=""
              placeholder="Input few characters"
              formatCreateLabel={(userInput) => selectMessage(userInput)}
              onChange={(newValue, _) => getSelectValue(newValue)}
              onInputChange={(e) => {
                if (e.length >= 1) {
                  getFunctionalCapabilities(e);
                } else {
                  setFunctionalCapabilities([]);
                }
              }}
            />
      </Box>
    </>
  );
};

export default UnapprovedLeadCapabilityAutoComplete;
