import { styled, Box } from "@mui/material";

export const IMG = styled("img")(({ theme }) => ({}));

export const H1 = styled("h1")(({ theme }) => ({}));

export const H3 = styled("h3")(({ theme }) => ({}));

export const SPAN = styled("span")(({ theme }) => ({}));

export const P = styled("p")(({ theme }) => ({}));

export const HR = styled("hr")(({ theme }) => ({}));

export const UL = styled("ul")(({ theme }) => ({}));

export const LI = styled("li")(({ theme }) => ({}));

export const LABEL = styled("label")(({ theme }) => ({}));

export const SELECT = styled("select")(({ theme }) => ({}));

export const OPTION = styled("option")(({ theme }) => ({}));

export const INPUT = styled("input")(({ theme }) => ({}));

export const A = styled("a")(({ theme }) => ({}));

export const BUTTON = styled("button")(({ theme }) => ({}));

export const TableContent = styled(Box)((theme) => ({
  width: "100%",
  paddingRight: ["0", "0", "1rem", "1rem", "1rem"],
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "capitalize",
  fontSize: "14px",
}));
