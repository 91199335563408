const ProjectDetailsDeliverablesStyles = {
  deliverables: {
    margin: ["1rem 0 1rem 0", "1rem 0 1rem 0", "1rem 0 2rem 0"],
  },
  deliverablesContainer: {
    height: "auto",
    // marginBottom: "1rem",
    // background: "rgba(186, 228, 238, 0.16)",
  },
  deliverablesWrapper: {
    // borderRadius: "5px",
    overflow: "auto",
    height: ["20rem", "20rem", "20rem", "20rem", "25rem"],
    marginRight: ["0", "0", "0.5rem"],
    marginBottom: "1rem",
    // padding: "0.5rem",
    background: "#F7FEFF 0% 0% no-repeat padding-box",
    padding: "12px",
    border: "2px solid #BCF3FF",
    borderRadius: "4px",
    overflowX: "hidden",
  },
  borderDefault: {
    border: (theme) => `2px solid ${theme.palette.disable}`,
  },
  borderRed: {
    border: (theme) => `2px solid ${theme.palette.error.main}`,
  },
  borderBlue: {
    border: () => `2px solid  #607ebb`,
  },
  borderGreen: {
    border: (theme) => `1px solid ${theme.palette.success.main}`,
  },
  outcomesWrapperMobile: {
    border: (theme) => `1px solid ${theme.palette.disable}`,
    borderRadius: "5px",
    padding: ["0.5rem", "1rem"],
    overflow: "auto",
    height: "auto",
    maxHeight: ["20rem", "20rem", "20rem", "20rem", "25rem"],
    marginLeft: ["0", "0", "0.5rem"],
    marginBottom: "1rem",
  },
  outcomesWrapperDesktop: {
    padding: ["0.5rem", "1rem"],
    overflow: "auto",
    height: ["20rem", "20rem", "20rem", "20rem", "25rem"],
    marginLeft: ["0", "0", "0.5rem"],
    marginBottom: "1rem",
    background: " #F7FEFF 0% 0% no-repeat padding-box",
    border: "2px solid #BCF3FF",
    borderRadius: "4px",
  },
  addDeliverableButton: {
    float: "right",
  },
  outcomeHeading: {
    margin: "0",
    fontSize: "1.2rem",
    marginBottom: "1rem",
    width: "100%",
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
  },
  addOutcomesIcon: {
    float: "right",
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
  },
  outcomeDataWrapper: {
    marginBottom: "1rem",
    borderBottom: (theme) => `2px dashed ${theme.palette.primary.main}`,
  },
  outcomeDataTitle: {
    marginBottom: "1rem",
    fontSize: ["0.8rem", "0.8rem", "0.9rem", "0.9rem", "0.9rem"],
    position: "relative",
  },
  dataTitle: {
    xs: {
      display: "block",
    },
    color: (theme) => theme.palette.text_heading.main,
    fontWeight: "700",
  },
  outcomesBorder: {
    border: (theme) => `1px solid ${theme.palette.disable}`,
    // borderRadius: "5px",
    padding: "3px 5px",
  },
  outcomeHeadingIconWrapper: {
    display: "flex",
    float: "right",
  },
  addOutcomesIconClose: {
    marginLeft: "0.5rem",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  addOutcomesIconCheck: {
    "&:hover": {
      color: (theme) => theme.palette.success.main,
    },
  },
  deleteOutcomesIcon: {
    position: "absolute",
    bottom: "0",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  submitOutcomeButton: {
    margin: "0",
    padding: "0",
    background: "transparent",
    border: "none",
    outline: "none",
    float: "right",
  },
  loader: {
    width: "19px !important",
    height: "19px !important",
    margin: "5px",
  },
  outcomeTitle: {
    position: "relative",
  },
  outcomeEditIcon: {
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
  },
  editIconWrapper: {
    float: "right",
    display: "flex",
    flexDirection: "row",
  },
  OutcomeDescription: {
    width: ["90%", "95%", "90%", "95%", "95%"],
    paddingRight: "0.5rem",
    overflow: "auto",
    // maxHeight: "4rem",
    float: "left",
    margin: "0",
  },
  taskInput: {
    width: ["90%", "95%", "92%", "95%", "95%"],
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      borderRadius: ["0", "0", "4px"],
    },
  },
  dateInput: {
    width: "95%",
    "& .MuiOutlinedInput-input": {
      fontSize: ["0.7rem", "0.9rem", "1rem", "1rem", "1rem"],
      padding: "8.5px 14px",
    },
  },
  durationInput: {
    width: "95%",
    marginRight: "0.5rem",
    "& .MuiOutlinedInput-input": {
      fontSize: ["0.7rem", "0.9rem", "1rem", "1rem", "1rem"],
      padding: "8.5px 14px",
    },
    "&  MuiFormHelperText-root": {
      position: "absolute",

      top: " 40px",
    },
  },
  durationTypeInput: {
    width: "90%",
    "& . MuiSelect-select": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: ["0.7rem", "0.9rem", "1rem", "1rem", "1rem"],
    },
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
      textOverflow: "none",
    },
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        padding: "8.5px 14px",
      },
    "& .MuiSvgIcon-root": {
      right: "0",
    },
  },
  statusInput: {
    width: ["100%", "100%", "90%", "100%", "100%"],
    "& . MuiSelect-select": {
      padding: "0",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: ["0.7rem", "0.9rem", "1rem", "1rem", "1rem"],
    },
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
      textOverflow: "none",
    },
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        padding: "8.5px 14px",
      },
    "& .MuiSvgIcon-root": {
      right: "0",
    },
  },
  deliverabelTitle: {
    width: "90%",
    textAlign: "justify",
    color: (theme) => theme.palette.text_heading.main,

    fontWeight: "700",
    float: "left",
    margin: "0",
  },
  deliverabelDescription: {
    width: ["90%", "95%"],
    textAlign: "justify",
    // maxHeight: "4rem",
    overflow: "auto",
    paddingRight: "0.5rem",
    wordBreak: "break-all",
    fontSize: ["0.8rem", "0.8rem", "0.9rem", "0.9rem", "0.9rem"],
    float: "left",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  blockTitle: {
    display: ["block", "block", "block", "inline", "inline"],
  },
  deleteDeliverableIcon: {
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  capabiltiesButtonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    position: "relative",
    top: "-10px",
  },
  deliverableEditInputWrapper: {
    marginTop: "1rem",
  },
  deliverableDeleteicon: {
    marginTop: "0.5rem",
  },
  deliverableDataContainer: {
    position: "relative",
  },
  deliverableDataWrapper: {
    marginTop: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  capabilitiesButton: {
    marginLeft: "5px",
  },
  chipsWrapper: {
    maxHeight: "10rem",
    overflow: "auto",
  },
  chips: {
    margin: "0.2rem",
    fontSize: ["0.7rem", "0.7rem", "0.9rem", "0.9rem", "0.9rem"],
  },
  addConsultantButtonWrapper: {
    float: "left",
  },
  addConsultantButton: {
    // float: "right",
    color: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  paginationWrapper: {
    width: "80%",
    display: "block",
    margin: "0 auto",
    border: (theme) => `1px solid ${theme.palette.disable}`,
    borderRadius: "50px",
    padding: "0.5rem",
    textAlign: "center",
    marginBottom: "1rem",
    fontSize: "0.8rem",
    boxShadow: "0px 1px 4px #00000029",
  },
  paginationArrowIcon: {
    display: "block",
    margin: "auto",
    color: (theme) => theme.palette.primary.main,
  },
  paginationArrowIconDisable: {
    display: "block",
    margin: "auto",
    color: (theme) => theme.palette.disable,
  },
  paginationCount: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paginationLeftArrow: {
    borderRight: (theme) => `1px solid ${theme.palette.enable}`,
  },
  paginationRightArrow: {
    borderLeft: (theme) => `1px solid ${theme.palette.enable}`,
  },
  consultantIsEven: {
    padding: "0 0 0 0.5rem",
  },
  consultantIsOdd: {
    padding: "0 0.5rem 0 0",
  },
  capabilityConsultantContainer: {
    width: ["100%", "100%", "90%", "90%", "90%"],
    marginBottom: "0.5rem",
  },
  capabilityConsultantWrapper: {
    paddingBottom: "10px",
    background: "#90c3cf29",
    borderRadius: "5px",
    border: (theme) => `1px solid ${theme.palette.disable}`,
  },
  capabilityConsultantImageWrapper: {
    textAlign: "center",
    fontSize: "0.9rem",
    fontWeight: "700",
    // paddingRight: "1rem",

    color: (theme) => theme.palette.text_heading.main,
  },
  consultantImage: {
    margin: "0.5rem auto",
  },
  consultantBudget: {
    margin: "0",
    fontWeight: "700",
    color: (theme) => theme.palette.text_heading.main,
    fontSize: "0.8rem",
  },
  removeConsultantIcon: {
    float: "right",
    cursor: "pointer",
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
  },
  disbledInput: {
    pointerEvents: "none",
  },
  approve: {
    marginBottom: "-0.5rem",
    marginLeft: "1rem",
    color: (theme) => theme.palette.success.main,
  },
  reject: {
    marginBottom: "-0.5rem",
    marginLeft: "1rem",
    color: (theme) => theme.palette.error.main,
  },
  buttonDisabled: {
    cursor: "default",
    pointerEvents: "none",
    color: (theme) => theme.palette.disable,
  },
};

export default ProjectDetailsDeliverablesStyles;
