import React from "react";
import { Switch, Route } from "react-router-dom";
import routes from "Routes";
import AuthenticatedRoute from "Routes/AuthenticateRoutes";
import UnauthenticatedRoute from "Routes/UnauthenticateRoutes";

const Router = () => {
  return (
    <Switch>
      {routes.map((route, index) => {
        if (route.type === "authorised") {
          return (
            <AuthenticatedRoute
              exact
              key={index}
              path={route.path}
              component={route.component}
              access_level={route.access_level}
            />
          );
        } else if (route.type === "unauthorised") {
          return <UnauthenticatedRoute exact key={index} path={route.path} component={route.component} />;
        } else {
          return <Route exact key={index} path={route.path} component={route.component} />;
        }
      })}
    </Switch>
  );
};

export default Router;
