import { Title } from '@mui/icons-material';
import { Box } from '@mui/material';
import { P } from 'Assets/Material';
import { ThemeStyles } from 'Assets/Styles';
import React from 'react';

const RequirementOverview = (props) => {

    const dataProjectLeadDetails = props.leadData;
    const requirement_id = props.requirement_id;
    const consultant_requirement = props.consultantRequirement;
    
    let details_arr = [];
    if (dataProjectLeadDetails.location) {
        details_arr.push({
            label: 'Location',
            value: dataProjectLeadDetails.location
        });
    }
    // if (dataProjectLeadDetails.industries){
    //     details_arr.push({
    //         label: 'Industry Sector',
    //         value: JSON.parse(dataProjectLeadDetails.industries).map(i => i.name).join(', ')
    //     })
    // }
    if (dataProjectLeadDetails.duration && dataProjectLeadDetails.duration_type) {
        details_arr.push({
            label: 'Duration',
            value: `${dataProjectLeadDetails.duration} ${dataProjectLeadDetails.duration_type}`
        });
    }

    let { experience, remote_working, engagement_preference, capabilities } = consultant_requirement;

    if (remote_working) {
        details_arr.push({
            label: 'Role Type',
            value: `${remote_working === 0 ? 'On-Site' : 'Remote'}`
        });
    }
    if (engagement_preference) {
        details_arr.push({
            label: 'Job Type',
            value: engagement_preference === 'Both' ? 'Full-Time/Part-Time' : engagement_preference
        });
    }
    if (dataProjectLeadDetails.start_date) {
        details_arr.push({
            label: 'Start Date',
            value: `${dataProjectLeadDetails.start_date}`
        });
    }
    if (experience) {
        details_arr.push({
            label: 'Experience',
            value: `${experience}+ years`
        });
    }

    return (
        <Box>
            
            <Box sx={ThemeStyles.paddedBox}>
                {details_arr.map(d => <Box><strong>{d.label}:</strong> {d.value}</Box>)}
            </Box>
            

            {capabilities
                ? (
                    <Box sx={ThemeStyles.paddedBox}>
                        <strong>Capabilities</strong>
                        <Box>{JSON.parse(capabilities).map(c => <span>{c.name}</span>)}</Box> 
                    </Box>
                )
                : ''
            }
            {dataProjectLeadDetails.industries
                ? (
                    <Box sx={ThemeStyles.paddedBox}>
                        <strong>Industry Sector:</strong>  
                        {JSON.parse(dataProjectLeadDetails.industries).map(i => <span>{i.name}</span>)}
                    </Box>
                )
                : ''
            }

            {
                dataProjectLeadDetails.problem_description 
                ?  (
                    <Box sx={ThemeStyles.paddedBox}>
                            <strong>Project Brief:</strong>
                            
                            <P sx={{whiteSpace: 'pre-wrap'}}>{dataProjectLeadDetails.problem_description}</P> 
                            
                    </Box>
                ) 
                : ''
            }

        </Box>
    )
}

export default RequirementOverview;