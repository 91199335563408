import API from "Services";
import { authUser } from "Utils";
import { FILE_UPLOAD } from "Utils/ApiConstants";

export const FileUploadService = async (data, progress) => {
  const formData = new FormData();
  for (const file of data) {
    let ext = file.name.split(".").pop();
    if (["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(ext)) {
      formData.append("file", file);
    }
  }
  formData.append("user_platform_id", 1);
  formData.append("createdby_userid", authUser()?.user_id);
  const response = await API.post(FILE_UPLOAD, formData, progress);
  if (response) {
    return response.data.map((i) => i.asset_id);
  }
};

export const imageUploadService = async (data, options) => {
  const formData = new FormData();
  formData.append("file", data);
  const response = await API.post(FILE_UPLOAD, formData, options);
  if (response) return response;
};
