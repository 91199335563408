const SuggestedConsultantsStyles = {
  profileHeaderWrapper: {
    margin: "2rem 0",
    padding: "2rem 0",
    boxShadow: "2px 2px 4px #00000029",
  },
  profileImageWrapper: {
    width: "4rem",
    height: "4rem",
    margin: "0 auto",
    position: "relative",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "block",
    marginLeft: [{ lg: "0" }],
  },
  profileName: {
    color: (theme) => theme.palette.text_heading.main,
    margin: "0",
    padding: "0",
    fontSize: "1.2rem",
    textTransform: "capitalize",
  },
  experience: {
    width: "10%",
  },
  status: {
    width: "10%",
    textAlign: "center !important",
  },
  actionWidth: {
    width: "7%",
  },
  statusData: {
    textAlign: "left",
    padding: "0",
  },
  statusActive: {
    color: (theme) => theme.palette.success.main,
  },
  statusInactive: {
    color: (theme) => theme.palette.error.main,
  },
  retainsIcons: {
    margin: "0 auto",
  },
  dataListRetainsIcon: {
    width: "1.2rem",
  },
  statusWrappar: {
    display: "flex",
    alignItem: "center",
  },
  statusIcons: {
    marginLeft: "1rem",
  },
  contentContainer: {
    padding: ["0 2rem", "0 4rem"],
  },
  contentWrapper: {
    marginBottom: "1rem",
    textAlign: ["left", "left", "center"],
  },
  contentHead: {
    display: "block",
    fontWeight: "700",
    marginBottom: "5px",
    color: (theme) => theme.palette.text_heading.main,
  },
  leftHead: {
    textAlign: "left",
  },
  downloadIcon: {
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    border: "2px solid",
    borderRadius: "50px",
    padding: "1px",
    width: "1.4rem",
    height: "1.4rem",
    transition: "0.2s",
    cursor: "pointer",
  },
  downloadIconDisable: {
    color: (theme) => theme.palette.disable,
    borderColor: (theme) => theme.palette.disable,
    border: "2px solid",
    borderRadius: "50px",
    padding: "1px",
    width: "1.4rem",
    height: "1.4rem",
    transition: "0.2s",
    cursor: "default",
  },
  cursor: {
    cursor: "pointer",
  },
  stausButtonDisable: {
    pointerEvent: "none",
    cursor: "default",
    color: (theme) => theme.palette.disable,
  },
  executiveSummery: {
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
};

export default SuggestedConsultantsStyles;
