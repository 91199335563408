import API from "Services";
import {
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATION_DATA,
  FETCH_CONVERSATION_MESSAGES,
  CONVERSATION_ARCHIVE,
  CONVERSATION_MARK_SEEN
} from "Utils/ApiConstants";

export const fetchConversations = async ({entity_id, entity_type, user_id, user_type_id}) => {
  try {
    let filters = `?entity_id=${entity_id}&entity_type=${entity_type}&user_id=${user_id}&user_type_id=${user_type_id}`;
    const response = await API.get(FETCH_CONVERSATIONS + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    return error;
  }
}

export const fetchConversationData = async (conversation_id) => {
  try {
    const response = await API.get(FETCH_CONVERSATION_DATA + `/${conversation_id}`);
    if (response && response.data) return response.data;
  } catch (error) {
    return error;
  }
}

export const fetchConversationMessages = async (conversation_id) => {
  try {
    const response = await API.get(FETCH_CONVERSATION_MESSAGES + `/${conversation_id}`);
    if (response && response.data) return response.data;
  } catch (error) {
    return error;
  }
}

export const archiveConversation = async (conversation_id) => {
  try {
    const response = await API.post(CONVERSATION_ARCHIVE, { conversation_id });
    if (response && response.data && response.data) {
      return response.data
    };
  } catch (error) {
    return error;
  }
};

export const markConversationSeen = async (conversation_id, user_id, user_type_id) => {
  try {
    const response = await API.post(CONVERSATION_MARK_SEEN + '/' + conversation_id, { conversation_id, user_id, user_type_id });
    if (response && response.data && response.data) {
      return response.data
    };
  } catch (error) {
    return error;
  }
};