import React from "react";
import Styles from "Common/Filters/Styles";
import { Box, MenuItem, FormControl, Select, OutlinedInput } from "Assets/Material";

const SortFilter = (props) => {
  return (
    <Box sx={Styles.searchFilterContainer}>
      <FormControl>
        <Select
          displayEmpty
          value={props?.sortBy}
          sx={Styles.sortFilter1}
          input={<OutlinedInput />}
          onChange={(e) => {
            props?.setSortBy(e.target.value);
            props?.sortFilterData.map((i) => {
              if (i.value === e.target.value) {
                props?.setSortOrderList(i.options);
              }
              return null;
            });
          }}
        >
          {props?.sortFilterData.map((search, index) => {
            return (
              <MenuItem key={index} value={search.value}>
                {search.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl>
        <Select
          displayEmpty
          sx={Styles.sortFilter2}
          value={props?.sortOrder}
          input={<OutlinedInput />}
          onChange={(e) => props?.setSortOrder(e.target.value)}
        >
          {props?.sortOrderList.map((sortorderlist, index) => {
            return (
              <MenuItem key={index} value={sortorderlist.value}>
                {sortorderlist.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortFilter;
