import React from 'react'
import NoDataImage from "Assets/Images/NoData.svg"
import Styles from "Common/NoData/Styles";
import { IMG, Box, Typography } from 'Assets/Material';
import { Height } from '@mui/icons-material';

const NoData = (props) => {
  return (
    <>
      <Box sx={{...Styles.noDataWrapper, ...Styles.noData}}>
        <div style={{width: "100%"}}>
          <IMG src={NoDataImage} alt={NoDataImage} sx={props?.imageStyle ? {...Styles.noDataImage, ...props?.imageStyle} : Styles.noDataImage} />
          <Typography component="h1" sx={Styles.noDataText}>{props.message || 'No Data Available'}</Typography>
        </div>
      </Box>
    </>
  )
}

export default NoData;