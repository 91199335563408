const CaseStudySwapperStyles = {
  launchIcon: {
    color: (theme) => theme.palette.primary.main,
    marginRight: "0",
    position: "absolute",
    top: "0",
    right: "0",
  },
  swapperTextOverflow: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  fileDownloadIcon: {
    color: (theme) => theme.palette.primary.main
  }
}

export default CaseStudySwapperStyles;
