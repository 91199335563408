const Styles = {
  formWrapper: {
    // p: 4,
    // overflowY: "auto",
    // borderRadius: "4px",
    // position: "absolute",
    // bgcolor: "background.paper",
    // top: ["0", "0", "40%", "40%", "40%"],
    // left: ["0", "0", "50%", "50%", "50%"],
    // width: ["100%", "100%", " 50%", " 50%", " 50%"],
    // maxHeight: ["100vh", "100vh", "80vh", "80vh", "80vh"],
    // height: ["100vh", "100vh", "auto", "auto", "auto"],
    // transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"],
    margin: "auto",
    padding: ["1rem","2rem","2rem","2rem","2rem"],
    display: "block",
    overflowY: "auto",
    borderRadius: "4px",
    position: "relative",
    bgcolor: "background.paper",
    top: ["0", "0", "10%", "10%", "10%"],
    width: ["100%", "100%", " 50%", " 50%", " 50%"],
    height: ["100vh", "100vh", "auto", "auto", "auto"],
    maxHeight: ["100vh", "100vh", "80vh", "80vh", "80vh"]
  },
  formHeading: {
    fontSize: "1.2rem",
    fontWeight: "700",
    marginBottom: "1.5rem",
    paddingLeft: "0.5rem",

    borderLeft: "5px solid #607ebb",
    color: (theme) => theme.palette.text_heading.main,
  },
  formCloseIcon: {
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
};

export default Styles;
