const AdminProfilePhotoViewerStyles =  {
  photoViewerWrapper: {
    padding:"1rem",
    overflowY: "auto",
    position: "absolute",
    bgcolor: "background.paper",
    top: ["10vh", "10vh", "40%", "40%", "40%"],
    left: ["10%", "25%", "50%", "50%", "50%"],
    width: ["80%", "50%", " 30%", " 30%", " 30%"],
    height: ["auto", "auto", "auto", "auto", "auto"],
    transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"],
  },
  closeIcon: {
    float: "right",
    cursor: "pointer",
    "&:hover": { color: "red" }
  },
  photo: {
    width: "100%",
    marginTop:"0.5rem",
    borderRadius:"5px"
  }
}

export default AdminProfilePhotoViewerStyles;