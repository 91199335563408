import API from "Services";
import { 
  FETCH_INVOICE_LIST, SUBMIT_INVOICE, 
  FETCH_UNBILLED_COSTING_REQUESTS,
  FETCH_UNBILLED_COSTING_SUMMARY,
  UPDATE_CONSULTANT_BILLING_ADDRESS, UPDATE_CONSULTANT_BANK_DETAILS, MARK_INVOICE_PAID 
} from "Utils/ApiConstants";

export const submitInvoiceService = async (data) => {
    try {
        const response = await API.post(SUBMIT_INVOICE, {data});
        return response.data;
      } catch (error) {
        return error.message
      }
}

export const markInvoicePaidService = async (data) => {
  try {
      const response = await API.post(MARK_INVOICE_PAID, {data});
      return response.data;
    } catch (error) {
      return error.message
    }
}

export const getConsultantInvoiceListService = async (user_id, from, limit = 5) => {
    try {
        const response = await API.get(FETCH_INVOICE_LIST+'?user_id='+user_id+'&start='+from+'&limit='+limit);
        return response.data;
      } catch (error) {
        return error.message
      }
}

export const fetchUnbilledCostingRequestService = async (data) => {
  try {
    let filters = `?from=${data.from}&offset=${data.offset}&sort_by=${data.sort_by}&sort_order=${data.sort_order}&user_id=${data.user_id}`;
    const response = await API.get(FETCH_UNBILLED_COSTING_REQUESTS + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchUnbilledCostingSummaryService = async (consultant_user_id) => {
  try {
    let filters = `?user_id=${consultant_user_id}`;
    const response = await API.get(FETCH_UNBILLED_COSTING_SUMMARY + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    return error;
  }
}

export const updateConsultantBillingAddress = async (billing_address, consultant_id) => {
  try {
    const response = await API.post(UPDATE_CONSULTANT_BILLING_ADDRESS, {billing_address, consultant_id});
    return response.data;
  } catch (error) {
    return error.message
  }
}

export const updateConsultantBankDetails = async (bank_details, consultant_id) => {
  try {
    const response = await API.post(UPDATE_CONSULTANT_BANK_DETAILS, {bank_details, consultant_id});
    return response.data;
  } catch (error) {
    return error.message
  }
} 