const Styles = {
  pageHeaderWrapper: {
    alignItems: "center",
    padding: ["1rem 1rem", "2rem 1.5rem 1.5rem", "1.5rem 2rem", "1.5rem 2rem", "1.5rem 2rem"],
  },
  pageHeaderTitle: {
    margin: "0px",
    display: "flex",
    fontWeight: "700",
    paddingLeft: "0.5rem",
    color: (theme) => theme.palette.text_heading.main,
    fontSize: ["1.2rem", "1.5rem", "1.5rem", "1.5rem", "1.5rem"],
    borderLeft: (theme) => [`4px solid ${theme.palette.primary.main}`, `5px solid ${theme.palette.primary.main}`],
  },
  pageHeaderButtonWrapper: {
    textAlign: "right",
  },
  pageHeaderButtonWrapper1: {
    textAlign: "left",
    marginTop: "1rem",
  },
  project_leads :{ textAlign: 'right', marginRight: '14%', marginTop: '15%' }
};

export default Styles;
