import React, { useEffect } from "react";
import { Reset } from "Assets/Icons";
import { SortFilter, SearchFilter } from "Common";
import { IMG, IconButton, Box, Typography, Button, useMediaQuery, useTheme } from "Assets/Material";
import Style from "Common/Filters/Styles";
import { authUser } from "Utils";
import IosShareIcon from "@mui/icons-material/IosShare";

const Filters = (props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
      props?.resetFilters()
  }, []);
  
  return (
    <Box sx={props?.isSearch && props?.isSort ? Style.filterContainer : Style.filterContainer1}>
      <Box sx={props?.isSearch && props?.isSort ? Style.filterWrapper : Style.filterWrapper1}>
        {props?.isSearch && <SearchFilter {...props} />}
        {props?.isSort && <SortFilter {...props} />}
        {/* {props?.isSortNone && <div>&nbsp;</div>} */}
        {/* <p>hello</p> */}
      </Box>
       

      <Box
        sx={Style.resetContainer}
        style={sm ? (props.isExport ? { gridTemplateColumns: "1fr auto 1fr" } : {}) : { gridTemplateColumns: "1fr" }}
      >
        <IconButton
          onClick={() => {
            props?.resetFilters();
          }}
          disableRipple
          sx={Style.resetWrapper}
        >
          <IMG src={Reset} alt={Reset} sx={Style.resetIcon} />
          <Typography sx={Style.resetText}> Reset </Typography>
        </IconButton>
        
        {sm && props.isAddButton && authUser()?.user_type == "Admin" && (
          <Box
            sx={Style.pageHeaderButtonWrapper}
            style={props?.isSortNone ? { position: "absolute", right: "34px" } : {}}
          >
            <Button variant="radius" onClick={() => props.addItem()}>
              {props.buttonLabel ? props.buttonLabel : "Add"}
            </Button>
          </Box>
        )}{props.isExport && (
          <IconButton onClick={() => props.isExportButton()} disableRipple sx={Style.resetWrapper}>
            <IosShareIcon sx={{ margin: "auto", color: "#607ebb" }} />
            <Typography sx={Style.resetText}> Export </Typography>
          </IconButton>

          // <IconButton onClick={() => props.isExportButton()} disableRipple>
          //   {/* <IMG src={Reset} alt={Reset} sx={Style.resetIcon} /> */}
          //   {/* <Typography sx={Style.resetText}> Reset </Typography> */}
          // </IconButton>
          // <Button variant="radius" onClick={() => props.isExportButton()}>
          //   Export
          // </Button>
        )}
      </Box>
    </Box>
  );
};

export default Filters;
