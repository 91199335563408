import React from 'react';
import Link from '@mui/material/Link';
import Styles from "Common/Unauthorised/Styles";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, Button, H1, P } from "Assets/Material";
import Cookies from 'js-cookie';

const Unauthorised = (props) => {

  return (
    <Box sx={Styles.unauthorisedWrappe}>
      <H1>Unauthorised</H1>
      <P sx={Styles.unauthorisedText}>
        You do not have sufficient permission to access this page.
        You may need to login as an appropriate user to access this page. &nbsp;
        {
          props.isHome
            ? <Button variant='text' onClick={() => {
              Cookies.remove("token");
              window.location.reload();
            }}>Login</Button>
            : <Link
              to="/"
              activeStyle={Styles.unauthorisedActiveLink}
              component={RouterLink}
              sx={Styles.unauthorisedLink}
            >
              Back To Home
            </Link>
        }
      </P>
      
      
    </Box>
  )
}

export default Unauthorised;