const ProjectDetailsRevenueAndCostStyles = {
  dataWrapper: {
    marginTop: "1rem",
  },
  dataItemWrapper: {
    marginBottom: "1rem",
  },
  textInput: {
    width: "80%",
  },
  justiFyCenter: {
    alignItems: "center",
  },
};

export default ProjectDetailsRevenueAndCostStyles;
