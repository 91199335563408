import { Box, Grid, Stack } from '@mui/material';
import { DownChevron } from 'Assets/Icons';
import { CloseIcon } from 'Assets/Icons';
import { IMG } from 'Assets/Material';
import { ChatStyles } from 'Assets/Styles';
import React from 'react';

const AdminChatHeader = (props) => {
    const consultant = props.data.members.find(m => m.user_type_id === 2) || {};
    return (
        <Box sx={ChatStyles.chatHeader}>
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                <Box item onClick={props.toggle} sx={{grow: 1}}>{consultant.name}</Box>
                <Box style={{lineHeight: 1, display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                    <IMG src={DownChevron} onClick={props.toggle} sx={props.isMinimized ? { transform: "rotateZ(180deg)", cursor: 'pointer' } : { cursor: 'pointer' }} />
                    <CloseIcon sx={{ color: '#FFF', cursor: 'pointer' }} onClick={props.close} />
                </Box>
                
            </Stack>
        </Box>
    )
}

export default AdminChatHeader;