import API from "Services";
import {
  GET_COUNT,
  GET_ALL_CLIENTS,
  GET_ALL_INDUSTRIES,
  GET_ALL_PROJECTS,
  GET_ALL_FUNCTIONAL_CAPABILITIES,
  GET_ALL_FUNCTIONAL_LANGUAGES,
  GET_ALL_AUTOCOMPLETE_CLIENTS,
  GET_ALL_RESPONSE_STATUS_REASONS,
  DELETE_RESPONSE_STATUS_REASONS,
} from "Utils/ApiConstants";

export const getCountService = async (data) => {
  const response = await API.get(GET_COUNT + data);
  if (response && response.data) {
    return response.data[0].count;
  }
};

export const getIndustriesService = async (searchParam) => {
  // list/fetchAll-industries?search_term=d&limit=50
  // return false;
  const response = await API.get(`${GET_ALL_INDUSTRIES}?search_term=${searchParam}&limit=50`);
  if (response.status === 200) return response.data.data || [];
};

export const getProjectsService = async (count_key) => {
  const limit = await getCountService(count_key);
  if (limit) {
    const response = await API.get(`${GET_ALL_PROJECTS}sort=id&sort_order=DESC&limit=${limit}&start=0`);
    if (response && response.data) return response.data?.data?.list || [];
  }
};

export const getFunctionalCapabilitiesService = async (searchParam) => {
  const response = await API.get(`${GET_ALL_FUNCTIONAL_CAPABILITIES}?search_term=${searchParam}&limit=50`);
  if (response && response.data) return response.data.data || [];
};

export const getFunctionalLanguagesService = async (searchParam) => {
  const response = await API.get(`${GET_ALL_FUNCTIONAL_LANGUAGES}?search_term=${searchParam}&limit=50`);
  if (response && response.data) return response.data.data || [];
};

export const getClientsService = async () => {
  const response = await API.get(
    `${GET_ALL_CLIENTS}?from=${0}&offset=${1000}&sort_order=DESC&user_type=Admin&user_id=${1}`
  );
  if (response && response.data) return response.data.data || [];
};

export const getResponseStatusReasons = async () => {
  // list/fetchAll-industries?search_term=d&limit=50
  // return false;
  const response = await API.get(`${GET_ALL_RESPONSE_STATUS_REASONS}`);
  if (response.status === 200) return response.data.data || [];
};

export const deleteResponseStatusReasons = async (name) => {
  const response = await API.post(DELETE_RESPONSE_STATUS_REASONS, { name });
  if (response && response.data) return response.data;
};