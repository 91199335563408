const ProjectDetailsContractStyles = {
  projectDetailsContractWrapper: {
    paddingBottom: "1rem",
    background: "rgba(186, 228, 238, 0.16)",
    padding: "12px",
  },
  addConsultantContractButton: {
    marginTop: "1rem",
  },
  tablePopupWrapper: {
    position: "absolute",
    maxHeight: "120px",
    width: "190px",
    overflowY: "auto",
    margin: "0",
    padding: "12px 6px",
    boxShadow: "0px 0px 5px 0px #0000004d",
    backgroundColor: "#fff",
    minHeight: "44px",
    marginTop: "-22px",
    borderRadius: "4px",
    zIndex: "9",
  },
  tablePopupCloseIcon: {
    position: "absolute",
    right: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      color: (theme) => theme.palette.error.main,
    },
    fontSize: "16px",
  },
  tablePopupList: {
    margin: "0",
    marginTop: "1rem",
    listStyle: "none",
    padding: "0",
    background: "#ffffff",
    borderRadius: "5px",
    width: "200px !important",
    zIndex: "99!important",
  },
  tablePopupListItem1: {
    padding: "5px",
    borderBottom: "1px solid",
    borderColor: "#44444480",
    whiteSpace: "pre-line !important",
    overflow: "auto",
  },
  tablePopupListItem2: {
    padding: "5px",
    overflow: "auto",
  },
  count: {
    fontWeight: "700",
    position: "absolute",
    right: "1rem",
    color: (theme) => theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "1rem",
  },
  downloadIcon: {
    float: "right",
    borderRadius: "50px",
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    color: (theme) => theme.palette.primary.main,
    width: "1.4rem",
    height: "1.4rem",
    cursor: "pointer",
  },
  contractTableWrapper: {
    marginTop: "1rem",
  },
  uploadIconWrapper: {
    textAlign: "left",
    padding: "0",
  },
  uploadIcon: {
    fontSize: "2rem",
    color: (theme) => theme.palette.primary.main,
    cursor: "pointer",
  },
  addContractButton: {
    float: "right",
    marginBottom: "1rem",
    cursor: "pointer",
    marginTop: "0 0 1rem 1rem 1rem ",
    color: (theme) => theme.palette.primary.main,
  },
  addContractButtonDisable: {
    cursor: "default",
    float: "right",
    marginBottom: "1rem",
    marginTop: "1rem",
    color: (theme) => theme.palette.disable,
  },
};

export default ProjectDetailsContractStyles;
