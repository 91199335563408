import React, { useState } from "react";
import { Formik } from "formik";
import { authUser } from "Utils";
import { Grid } from "Assets/Material";
import { useRecoilState, toasterGlobal } from "Atom";
import { sendEmailService } from "Services/SendEmailServices";
import { Form, TextInput, FileUploader, FormButton, TextareaInput } from "Common";

const Email = (props) => {

  const setToaster = useRecoilState(toasterGlobal)[1];
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const sendEmail = async (values) => {

    values.user_platform_id = 1;
    values.createdby_userid = authUser()?.user_id;

    setIsFormSubmit(true);
    const response = await sendEmailService(values);
    if (response && +response.status === 200) {
      onCloseEmailPopup(true, response.message)
    } else {
      onCloseEmailPopup(false, response.message)
    }
  };

  const onCloseEmailPopup = (isSuccess, message) => {
    setToaster({
      isOpen: true,
      status: isSuccess ? "success" : "fail",
      message: message,
    });
    props?.onClose();
    setIsFormSubmit(false);
  }

  const validateForm = (values) => {
    const errors = {};
    if (!values.subject) {
      errors.subject = "Subject is required";
    }
    if (!values.message) {
      errors.message = "Message is required";
    }
    return errors;
  }

  return (
    <div>
      <Form
        onOpen={props?.onOpen}
        onClose={props?.onClose}
        title={props?.title}
      >
        <Formik
          initialValues={{
            subject: "",
            message: "",
            pdf_asset_id: "",
            id: props?.id,
            requestFrom: props?.requestFrom,
            user_id: authUser()?.user_id
          }}
          validate={values => validateForm(values)}
          onSubmit={values => sendEmail(values)}
        >
          {
            ({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput
                      label="Subject"
                      name="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      onError={errors.subject && touched.subject && errors.subject}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextareaInput
                      rows={4}
                      label="Message"
                      name="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      onError={errors.message && touched.message && errors.message}
                    />
                  </Grid>

                  <FileUploader getData={value => values.pdf_asset_id = value} />

                  <Grid item xs={12} sm={12} md={6}>
                    <FormButton
                      disabled={isFormSubmit}
                      label="Send"
                    />
                  </Grid>

                </Grid>
              </form>
            )
          }
        </Formik>
      </Form>
    </div>
  );
};

export default Email;
