import API from "Services";
import Cookies from "js-cookie";
import { GET_QUOCH_MARK, FINISH_QUOCH_MARK } from "Utils/ApiConstants";
// import  from

export const getAllQuochmark = async (data) => {
  try {
    let filters = `?user_id=${data}`;
    const response = await API.get(GET_QUOCH_MARK + filters);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};

export const finishQuochmark = async (data) => {
  try {
    const response = await API.post(FINISH_QUOCH_MARK, data);
    if (response && response.data) return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.location.href = "/";
    }
    return error;
  }
};
