// Auth //
export const DEFAULT_AUTH_LANDING = "/projects";

export const SIGNUP = "users/register-admin";
export const VERIFY = "users/validate-admin";
export const GET_USER_ACCESS = "users/getUserAccess";
export const LOGIN = "users/login";
export const FORGET_PASSWORD = "users/forgot-password";
export const RESET_PASSWORD = "users/reset-password";
export const RESER_PASSWORD_PROFILE = "users/reset-password-on-profile";
export const RELAY_LOGIN = "users/relay-login";

// Projects //
export const GET_ALL_PROJECT_DATA = "project/fetchAll-projects";
export const ADD_EDIT_PROJECT = "project/save-project";
export const CREATE_CLIENT = "data/generic/save/client/";
export const ADD_UNIQUE_IDENTIFIER = "project/add-unique-identifier";
export const GET_PROJECT_MILESTONE_DELIRABLE = "project/fetchAll-milestones-deliverables";
export const GET_PROJECT_DETAILS = "project/get-project-details";
export const CREATE_MS_TEAM = "v1/teams/createTeam";
export const ADD_COMMIUNICATION_CHANNEL = "project/save-communication-channel";
export const GET_ALL_COMMUNICATION_CHANNEL = "project/fetch-channels";
export const DELETE_COMMUNICATION_CHANNEL = "project/delete-channel";
export const GET_PROJECT_CONTRACTS = "data/generic/project_contract/list?filters=";
export const ADD_EDIT_CONTRACT = "project/project-contract";
export const DELETE_PROJECT_CONTRACT = "project/remove-contract";
export const GET_CONSULTANTS = "v1/caseStudy/fetchCounsultants";
export const GET_OUTCOMES = "data/generic/next_action/list?filters=";
export const ADD_EDIT_DELIVERABLES = "project/save-milestone";
export const PROJECT_COST = "costing/project-cost";

export const COSTING_SAVE_DELIVERABLES = "costing/save-costing";
export const GET_COSTING = "costing/get-consultant-costing";

export const ADD_EDIT_OUTCOME = "project/save-deliverable";
export const DELETE_OUTCOME = "project/delete-deliverable";
export const DELETE_MILESTONE = "project/delete-milestone";
export const SEARCH_TALENT = "talent-management/searchTalent";

export const CURRENT_JOB_TITLE = "talent-management/fetch-current-job-title";
export const CURRENT_EMPLOYER = "talent-management/fetch-current-employer";
export const DELETE_COSTING = "costing/delete/costing";
export const UPDATE_CONSULTANT_STATUS = "data/generic/update-consultant-status/deliverable/";
export const SAVE_COSTING = "v1/costModule/save-costing";

// Talent Management //
export const GET_ALL_TALENT_MANAGEMENT = "talent-management/fetchAll-consultants";
export const GET_ALL_INDEXED_TALENT_MANAGEMENT = "talent-management/fetchAll-consultants-indexed";
export const DELETE_CONSULTANT = "talent-management/delete-consultant";
export const SUBMIT_LINKEDIN_URL = "data/generic/submit-linkedin-urls/consultant";
export const ADD_CONSULTANT = "talent-management/save-consultant-details-v2";
export const UPDATE_CONSULTANT_BILLING_ADDRESS = "talent-management/update-billing-address";
export const UPDATE_CONSULTANT_BANK_DETAILS = "talent-management/update-bank-details";
export const GET_CONSULTANT_RESUME_PARSER = "talent-management/get-consultant-resume-parser";
export const GET_CONSULTANT_RESUME_SUMMARY = "talent-management/get-consultant-summary";

export const GET_CONSULTANT_DETAILS = "users/get-consultant-data?user_id=";
export const SUGGEST_CLIENTS_TO_CONSULTANTS = "talent-management/save-suggested-consultants";
export const FETCH_SHARED_CONSULTANT_PROFILE = "talent-management/fetch-shared-consultant-profile?consultant_id=";
export const GET_CONSULTANT_PROJECTS = "v1/list/getConsultantProjects";
export const DELETE_SHARED_PROFILE = "talent-management/delete-shared-profile";
export const UPLOAD_CONSULTANT_PHOTO = "v1/list/upload1";
export const UPDATE_CONSULTANT_PROFILE = "talent-management/update-consultant-details";
export const ONE_PAGER_UPLOAD = "talent-management/create-one-pager";

// Clients //
export const ADD_EDIT_CLIENT = "client/save-client";
export const GET_CLIENT_DETAILS = "list/fetchAll-client-details";
export const EDIT_CLIENT = "client/edit-client";
export const GET_ALL_CLIENTS = "client/fetchAll-clients";
export const GET_CLIENT_PROFILE_DETAILS = "users/get-client-data";
export const UPDATE_CLIENT_PROFILE = "v1/client/update-client-profile";
export const GET_CLIENTS_PROJECTS = "v1/list/getClientProjects";
export const GET_CLIENTS_SUGGESTED_CONSULTANT = "talent-management/fetch-suggested-consultant-with-client";

// Client User Profile //
export const GET_CLIENT_USER_PROFILE = "users/get-client-data?user_id=";
export const UPDATE_CLIENT_USER_PROFILE = "client/edit-client";

// Consultant //
export const GET_CONSULTANT_PROFILE = "/v1/consultant/profile";
export const UPLOAD_CONSULTANT_RESUME = "v1/consultant/upload-documents-resume?consultant_id=";
export const UPLOAD_CONSULTANT_ONE_PAGE = "/v1/consultant/upload-documents-one-pager?consultant_id=";

// Consultant User Profile
export const GET_CONSULTANT_USER_PROFILE = "users/get-consultant-data?user_id=";
export const GET_CONSULTANT_USER_PREVIOUS_PROJECT = "talent-management/fetch-consultants-details?consultant_id=";
export const UPDATE_CONSULTANT_USER_PROFILE = "talent-management/update-consultant-details";
export const ANALYSE_PDF = "talent-management/analyse-pdf";
export const CONSULTANT_UPDATE_PDF_TEXT = "talent-management/update-pdf-text";
export const FETCH_CAPABILITY_SUGGESTION = "talent-management/capability-suggestions";
export const DISMISS_CAPABILITY_SUGGESTIONS = "talent-management/dismiss-capability-suggestions";

export const FETCH_LANGUAGE_SUGGESTION = "talent-management/language-suggestions";
export const DISMISS_LANGUAGE_SUGGESTIONS = "talent-management/dismiss-language-suggestions";

// Case Studies //
export const GET_ALL_CASE_STUDIES = "knowledge-management/fetchAll-case-studies";

export const ADD_CASE_STUDY = "knowledge-management/save-case-study";

export const DELETE_CASE_STUDY = "knowledge-management/delete-case-study";
export const FETCH_SAVED_CASE_STUDY = "knowledge-management/fetch-saved-case-study";
export const PUBLISH_CASE_STUDY = "knowledge-management/publish-case-study";
// talent-management/fetchAll-consultants?from=0&offset=10&sort_order=DESC&sort_by=id&search_term=&search_by=1&user_type=Admin&user_id=1

export const GET_PUBLISHED_CASED_STUDIES = "knowledge-management/fetchAll-published-case-studies";
export const DELETE_PUBLISHED_CASE_STUDY = "knowledge-management/delete-published-case-study";

//relay
export const GET_RELAY_TOKEN = "leads/get-relay-token";

//Project Leads //
export const GET_ALL_PROJECT_LEADS = "leads/get-leads";
export const GET_ALL_MARVIN_LEADS = "leads/get-marvin-leads";
export const GET_MARGVIN_LEAD_BY_ID = "leads/get-marvin-lead-by-id";
export const EDIT_MARVIN_LEAD = "leads/edit-marvin-lead";
export const APPROVE_MARVIN_LEAD = "leads/approve-marvin-lead";
export const GET_SINGLE_PROJECT_LEADS = "leads/get-lead-by-id";
export const ADD_PROJECT_LEADS = "leads/save-lead";
export const EDIT_PROJECT_LEADS = "leads/edit-lead";
export const FETCH_CONSULTANTS_FOR_PROJECT_LEAD = "leads/get-consultant-suggestions";
export const FETCH_CONSULTANTS_FOR_PROJECT_LEAD_INDEXED = "leads/get-indexed-consultant-suggestions";
export const FETCH_CONSULTANTS_FOR_PROJECT_LEAD_TYPESENSE = "leads/get-typesense-consultant-recommendation";
export const FETCH_CONSULTANTS_FOR_PROJECT_LEAD_SOVREN = "leads/get-sovren-consultant-suggestions";
export const BROADCAST_REQUIREMENTS_FOR_PROJECT_LEAD = "leads/broadcast-requirements";
export const ADD_CONSULTANT_SUGGESTION_FOR_PROJECT_LEAD = "leads/add-consultant-suggestion";
export const REMOVE_CONSULTANT_SUGGESTION_FOR_PROJECT_LEAD = "leads/remove-consultant-suggestion";
export const SEND_LEAD_VALIDATION_EMAIL = "leads/send-validation-email";
export const GENERATE_LEAD_LOGIN = "leads/generate-lead-login";
export const SAVE_CONSULTANT_RESPONSE = "leads/save-consultant-response";
export const GET_ALL_CONSULTANT_RESPONSE = "leads/get-consultants-responses"
export const UPDATE_CONSULTANT_RESPONSE = "leads/update-consultant-response";
export const GET_SHORTLISTED_CONSULTANTS = "leads/get-shortlisted-consultants";
export const SEND_SHORTLISTED_CONSULTANTS_EMAIL = "leads/send-shortlisted-consultants-email";
export const UPDATE_CONSULTANT_RESPONSE_WITH_TOKEN = "leads/update-consultant-response-with-token";
export const GET_SHARE_LINK = "leads/get-share-link"
export const SOFT_DELETE_TEMP_LEADS = "leads/soft-delete-temp-lead";
export const SOFT_DELETE_CONSULTANT_REQUIREMENTS = "leads/remove-requirement";

export const LEAD_CLIENT = "data/generic/save/lead_client/";
export const DELETE_PROJECT_LEADS = "leads/remove-lead";

// Approval Panel //
export const Add_NEW_FUNCTIONAL_CAPABILITY = "talent-management/save-unapproved-skills";
export const Add_NEW_FUNCTIONAL_LANGUAGE = "talent-management/save-unapproved-languages";
export const Add_NEW_UNAPPROVED_INDUSTRIES = "knowledge-management/save-unapproved-industries";
export const GET_ALL_UNAPPROVED_INDUSTRIES = "knowledge-management/fetch-unapproved-industries";
export const GET_CITY = "list/fetch-locations";
export const FETCH_COSTING_REQUESTS = "costing/fetch-costing-requests";
export const FETCH_UNBILLED_COSTING_REQUESTS = "costing/get-consultant-unbilled-costing";
export const FETCH_UNBILLED_COSTING_SUMMARY = "costing/get-consultant-unbilled-costing-summary";
export const FETCH_ADDED_SKILLS = "talent-management/fetch-unapproved-skills";
export const UPDATE_SKILLS_STATUS_REMOVE = "talent-management/update-skills-status-remove";
export const UPDATE_SKILLS_STATUS_ADD = "talent-management/update-skills-status-add";
export const APPROVE_REJECT_REQUEST = "costing/approve-reject-requests";
export const UPDATE_INDUSTRIES_STATUS_REMOVE = "knowledge-management/update-industries-status-remove";
export const UPDATE_INDUSTRIES_STATUS_ADD = "knowledge-management/update-industries-status-add";
// Admin Profile  //
export const GET_ADMIN_PROFILE_DETAILS = "users/get-admin-data?user_id=";
export const UPDATE_ADMIN_PROFILE = "users/edit-admin-profile";
export const SEND_PASSWORD_EMAIL_RESET = "users/send-password-reset-link"

// Suggested Consultant //
export const GET_ALL_SUGGESTED_CONSULTANTS = "talent-management/fetchAll-shared-consultants-with-clients";

// v1/consultant/shared-consultants";
export const CHANGED_STATUS_SHARED_CONSULTANT = "talent-management/change-status-shared-consultants";
export const SHARED_CONSULTANT_TO_CLIENTS = "talent-management/fetch-shared-profile-to-client";

// Other//
export const SIGNED_URL = "list/fetch-signed-url/";
export const DOWNLOAD_FILE = "list/download-file/";
export const GET_COUNT = "v1/list/getCount/";
export const GET_ALL_INDUSTRIES = "list/fetchAll-industries";
export const GET_ALL_RESPONSE_STATUS_REASONS = "list/fetch-response-status-reasons";
export const DELETE_RESPONSE_STATUS_REASONS = "list/delete-response-status-reasons";

export const GET_ALL_FUNCTIONAL_CAPABILITIES = "list/fetchAll-capabilities";
export const GET_ALL_FUNCTIONAL_LANGUAGES = "list/fetchAll-languages";
export const GET_ALL_PROJECTS = "data/generic/project/list?";
export const GET_ALL_AUTOCOMPLETE_CLIENTS = "/data/generic/client/list?";
export const FILE_UPLOAD = "/list/upload1";
export const SEND_EMAIL = "/list/sendEmail";

// Quoch Mark
export const GET_QUOCH_MARK = "users/coach-marks";
export const FINISH_QUOCH_MARK = "users/finished-journey";

// Invoices
export const SUBMIT_INVOICE = "invoices/submit";
export const MARK_INVOICE_PAID = "invoices/mark-paid";
export const FETCH_INVOICE_LIST = "invoices/list";

// Conversation
export const FETCH_CONVERSATIONS = "conversation/list";
export const FETCH_CONVERSATION_DATA = "conversation/data";
export const FETCH_CONVERSATION_MESSAGES = "conversation/messages";
export const CONVERSATION_MARK_SEEN = "conversation/mark-seen";
export const CONVERSATION_ARCHIVE = "conversation/archive";
export const SEND_APPLICATION_UPDATE_EMAIL = "conversation/send-application-update-email"
export const GET_ADMIN_SHORTLIST_URL = "leads/get-admin-shortlist-url";

// Sovren Indexing
export const INDEX_ENTITY_IN_SOVREN = "leads/index-in-sovren";

//for hiding dev changes that are not ready for prod
export const SHOW_DEV_CHANGES = true;

