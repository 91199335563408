const AuthStyles = {
  authContainer: {
    height: "90vh",
    display: "flex",
  },
  authWrapper: {
    padding: "2rem",
    height: "auto",
    width: ["100%", "80%", "50%", "30%", "30%"],
    margin: "auto",
    // marginTop: "5%",
    background: "#fff",
    borderRadius: "5px",
    textAlign: "center",
    position: "relative",
    top: "-64px",
    boxShadow: (theme) => ["0", "0", `0px 2px 4px 0px ${theme.palette.disable}`],
    border: (theme) => ["0", "0", `1px solid ${theme.palette.disable}`],
  },
  authTitle: {
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: "700",
    fontFamily: "Nunito Sans ,sans-serif",
    color: (theme) => theme.palette.text_heading.main,
  },
  authSubTitle: {
    textAlign: "justify",
    marginBottom: "1rem !important",
  },
  authButton: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "5px",
    margin: "1rem 0",
    color: "#fff",
    fontWeight: "700",
    backgroundColor: (theme) => theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#7b94c6",
    },
  },
  inputField: {
    margin: "1rem 0",
    "&:focus": {
      background: "transferent",
    },
  },
  inputPasswordError: {
    marginBottom: "2rem !important",
  },
  otherLinks: {
    color: "#747e7d",
    marginBottom: "0",
    fontSize: "1rem",
  },
  link: {
    fontWeight: "700",
    float: "right",
    textDecoration: "none",
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkCreateAccount: {
    fontWeight: "700",
    textDecoration: "none",
    color: (theme) => theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  formError: {
    color: (theme) => theme.palette.error.main,
    marginBottom: "0",
    fontSize: "1rem",
  },
  forgetPasswordError: {
    marginBottom: "1rem",
  },
  loader: {
    width: "16px !important",
    height: "16px !important",
    margin: "5px",
    color: "#fff",
  },
  scLogo: {
    display: "block",
    margin: "auto",
    width: "3rem",
  },
  loginProfileImageWrapper: {
    width: "5rem",
    height: "5rem",
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50px",
    overflow: "hidden",
    margin: "auto",
  },
  loginProfileImage: {
    width: "100%",
  },
  checkboxDefaultUser: {
    float: "left",
    marginLeft: "0",
    marginTop: "0.5rem",
    "& .css-1xnhj9c-MuiTypography-root": {
      marginLeft: "0.5rem",
    },
    "& .css-77t0ky-MuiButtonBase-root-MuiCheckbox-root": {
      padding: "0",
    },
  },
  backArrow: {
    left: "2rem",
    cursor: "pointer",
    position: "absolute",
    color: (theme) => theme.palette.primary.main,
  },
};

export default AuthStyles;
